import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  UpdateEventRequest,
  UpdateEventRequestPayload,
} from './UpdateEventRequest';

export class UpdateEventNetworkObject extends WebSocketNetworkObject<
  UpdateEventRequest,
  UpdateEventRequestPayload
> {
  constructor(params: UpdateEventRequest) {
    super(params, WSMessageEvent.UpdateEvent);
  }

  override getParams(): UpdateEventRequestPayload {
    const {
      id,
      removeModifiers,
      event: { label, eventType, accommodationId },
      eventDetails: { repetitionType, impacts },
    } = this.params;

    return {
      id,
      removeModifiers,
      event: {
        label,
        eventType,
        accommodationId,
        eventDetails: {
          repetitionType,
          impacts: impacts.map((impact) => ({
            date: getISODate(impact.date),
            impact: impact.impact,
            isTail: impact.isTail,
          })),
        },
      },
    };
  }
}
