class UtilString {
    reverse(val: string) {
        return val.split('').reverse().join('')
    }

    replaceAll(val: string, target: string, replacement: string) {
        return val.split(target).join(replacement)
    }

    capitalizeFirst(val: string) {
        return val.charAt(0).toUpperCase() + val.slice(1)
    }

    toInteger(val: string, isFloat: boolean = false) {
        const functionToUse = isFloat ? parseFloat : parseInt
        try {
            return functionToUse(val)
        } catch {
            return 0
        }
    }
}

export const utilString = new UtilString()
