import revive_payload_client_WEFTSytvje from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BqMNJrPZ7E from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_C0mlZN7COG from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_45gt3xCM9U from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eLYLbcpakw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yZP0H21Y8c from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_7Axg12WnSk from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ZVpcc2TzG2 from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_BBokKjTD6D from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_EbELCf3CCh from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0GX2wNXaPc from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import errorHandling_EicKpRvXfW from "/opt/build/repo/src/plugins/errorHandling.ts";
import googleTagManager_LIIzgQRozu from "/opt/build/repo/src/plugins/googleTagManager.ts";
import pinia_cfuRVwzvtd from "/opt/build/repo/src/plugins/pinia.ts";
import sentry_h0fMjCG9AB from "/opt/build/repo/src/plugins/sentry.ts";
import tracking_tMIteM76jV from "/opt/build/repo/src/plugins/tracking.ts";
import v_calendar_iMjdyQNhyd from "/opt/build/repo/src/plugins/v-calendar.ts";
export default [
  revive_payload_client_WEFTSytvje,
  unhead_BqMNJrPZ7E,
  router_C0mlZN7COG,
  payload_client_45gt3xCM9U,
  navigation_repaint_client_eLYLbcpakw,
  check_outdated_build_client_yZP0H21Y8c,
  chunk_reload_client_7Axg12WnSk,
  plugin_vue3_ZVpcc2TzG2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BBokKjTD6D,
  switch_locale_path_ssr_EbELCf3CCh,
  i18n_0GX2wNXaPc,
  errorHandling_EicKpRvXfW,
  googleTagManager_LIIzgQRozu,
  pinia_cfuRVwzvtd,
  sentry_h0fMjCG9AB,
  tracking_tMIteM76jV,
  v_calendar_iMjdyQNhyd
]