import { StorageKeys } from '../constants/storageKeys'
import { useUserStore } from '../store/user'
import { SalesModes } from './../constants/salesMode'

class utilSales {
	isSalesMode: Ref<boolean> = ref(false)

	setSalesMode(mode: SalesModes | undefined) {
		localStorage.setItem(StorageKeys.SalesMode, mode === undefined ? 'disabled' : mode)

		this.computeSalesMode()
	}

	canUseSalesMode(): boolean {
		const userStore = useUserStore()
		const user = userStore.userData

		const hasValidMail = user.email != undefined && user.email.endsWith('@smartpricing.it')

		return user != undefined && (user.isAccountingSession || hasValidMail)
	}

	private hasValidSalesMode(): boolean {
		return (Object.values(SalesModes) as String[]).includes(localStorage.getItem(StorageKeys.SalesMode) || '')
	}

	computeSalesMode() {
		const { isDemoUser } = useUserStore()
		this.isSalesMode.value = isDemoUser || (this.canUseSalesMode() && this.hasValidSalesMode())
	}
}

export const UtilSales = new utilSales()
