export default {
	// primary colour
	'main-blue': {
		50: '#F1F5FE',
		100: '#E2EAFD',
		200: '#CBD8FB',
		300: '#ACBFF6',
		400: '#8A9BF0',
		500: '#6D7AE8',
		600: '#5154DB',
		700: '#3D3DBD',
		800: '#36389B',
		900: '#32357B',
	},

	// secondary colour
	'dark-blue': {
		50: '#FAFAFA',
		100: '#EAEAEC',
		200: '#D3D5D9',
		300: '#AEB4BC',
		400: '#818D9C',
		500: '#606C7B',
		600: '#455163',
		700: '#384252',
		800: '#272E3A',
		900: '#1F242D',
	},

	// secondary gray
	'secondary-gray': {
		50: '',
		100: '#F1F0EF',
		200: '#E5E4E3',
		300: '#D3D1CE',
		400: '#C2C0BC',
		500: '#9E9B95',
		600: '#86837C',
		700: '#6F6C66',
		800: '#555450',
		900: '#292826',
		'050': '#F8F8F8',
	},

	// ternary colour
	'light-blue': {
		50: '#EEFDFD',
		100: '#D4F8F9',
		200: '#AEF0F3',
		300: '#76E3EA',
		400: '#3BCED9',
		500: '#1CB1BE',
		600: '#1A8EA0',
		700: '#1C7282',
		800: '#1F5E6B',
		900: '#1E4E5B',
	},

	// success color
	green: {
		50: '#F0FEF4',
		100: '#DCFEE4',
		200: '#B6FCC9',
		300: '#7DF7A0',
		400: '#3DEB6E',
		500: '#14D249',
		600: '#0BAD39',
		700: '#0C882F',
		800: '#0D6D29',
		900: '#0D5925',
	},

	// warning color
	yellow: {
		50: '#FFFAEB',
		100: '#FEF0C7',
		200: '#FDE08A',
		300: '#FCD268',
		400: '#FAB425',
		500: '#F4930C',
		600: '#D86D07',
		700: '#B34B0A',
		800: '#92390E',
		900: '#78300F',
	},

	// error color
	red: {
		50: '#FEF2F2',
		100: '#FFE1E1',
		200: '#FFC8C8',
		300: '#FF8C8C',
		400: '#FD6C6C',
		500: '#F53E3E',
		600: '#E22020',
		700: '#BE1717',
		800: '#9D1717',
		900: '#821A1A',
	},

	// support colors
	'dark-red': {
		50: '#FBF2F5',
		100: '#EBC2D1',
		200: '#D991AD',
		300: '#C76189',
		400: '#B33166',
		500: '#9E0142',
		600: '#8D0039',
		700: '#7B0031',
		800: '#680028',
		900: '#550020',
	},
	'light-orange': {
		50: '#FFF7E3',
		100: '#FFF0CE',
		200: '#FFE9BA',
		300: '#FFE1A6',
		400: '#FFDA93',
		500: '#FED281',
		600: '#DFB86F',
		700: '#BF9F5E',
		800: '#9F854D',
		900: '#806A3C',
	},
	'light-yellow': {
		50: '#FFFFED',
		100: '#FFFFE0',
		200: '#FFFED3',
		300: '#FEFCC7',
		400: '#FDFABB',
		500: '#FBF8B0',
		600: '#DDDA99',
		700: '#BEBC82',
		800: '#9F9D6C',
		900: '#807E55',
	},
	'light-green': {
		50: '#F8FCF0',
		100: '#F3F9E4',
		200: '#EDF6D9',
		300: '#E8F3CF',
		400: '#E2EFC4',
		500: '#DCEBBA',
		600: '#C1CEA2',
		700: '#A6B28A',
		800: '#8A9573',
		900: '#6F775B',
	},
	'gem-green': {
		50: '#EEF9F2',
		100: '#D8F2E3',
		200: '#C4EAD3',
		300: '#B0E1C4',
		400: '#9CD8B4',
		500: '#89CFA5',
		600: '#77B690',
		700: '#659D7B',
		800: '#538467',
		900: '#426A52',
	},
	'water-blue': {
		50: '#EDF6F9',
		100: '#CAE4ED',
		200: '#A8D1E0',
		300: '#87BED1',
		400: '#66AAC3',
		500: '#4696B3',
		600: '#3C849E',
		700: '#327289',
		800: '#285F74',
		900: '#1F4D5D',
	},
	lavander: {
		50: '#F2EEF7',
		100: '#D9CDE8',
		200: '#C0ADD7',
		300: '#A68DC6',
		400: '#8D6EB4',
		500: '#744FA2',
		600: '#65448F',
		700: '#57397C',
		800: '#482F68',
		900: '#3A2554',
	},
	'secondary-gray': {
		50: '#F8F8F8',
		100: '#F1F0EF',
		200: '#E5E4E3',	
		300: '#D3D1CE',
		400: '#C2C0BC',
		500: '#9E9B95',
		600: '#86837C',
		700: '#6F6C66',
		800: '#555450',
		900: '#292826',
	}
}
