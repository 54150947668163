import { utilTracking } from '~/utils/utilTracking'
import { TrackingMessages } from '../constants/trackingMessages'
import { Event } from '../submodules/sharedTypes/common/Event'
import { EventDetails } from './../submodules/sharedTypes/common/Event'

export const enum EventStep {
    DETAIL_VIEW = 1,
    SETTINGS_VIEW = 2,
    IMPACT_VIEW = 3,
}

export const eventsOpenTracking = (event: Event) =>
    utilTracking.track(TrackingMessages.EVENT_OPEN, {
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
    })

export const eventsCloseTracking = (event: Event, step_num: EventStep) =>
    utilTracking.track(TrackingMessages.EVENT_CLOSE, {
        step_num,
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
    })

export const eventsGoToSettingsTracking = (event: Event) =>
    utilTracking.track(TrackingMessages.EVENT_SETTINGS_VIEW, {
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
    })

export const eventsGoToImpactsViewTracking = (
    event: Event,
    selectedAccommodationsAmount: number
) =>
    utilTracking.track(TrackingMessages.EVENT_IMPACT_VIEW, {
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
        selected_accommodations_num: selectedAccommodationsAmount,
    })

export const eventsImpactsSave = (
    event: Event,
    newEventDetails: EventDetails,
    oldEventDetails?: EventDetails
) => {
    let min_impact, max_impact, avg_impact, impacted_dates_num
    const impacts = (newEventDetails.impacts || []).map((el) => el.impact)

    if (impacts.length == 0) {
        min_impact = 0
        max_impact = 0
        avg_impact = 0
    } else {
        min_impact =
            impacts.reduce(
                (prev, next) => (prev < next ? prev : next),
                Number.MAX_VALUE
            ) * 100
        max_impact =
            impacts.reduce((prev, next) => (prev > next ? prev : next), 0) * 100
        avg_impact =
            (impacts.reduce((prev, next) => prev + next) * 100) / impacts.length
    }

    if (
        oldEventDetails == undefined ||
        (oldEventDetails.impacts.length == 0 &&
            newEventDetails.impacts.length != 0)
    ) {
        impacted_dates_num = newEventDetails.impacts.length
    } else if (
        newEventDetails.impacts.length == 0 &&
        oldEventDetails.impacts.length != 0
    ) {
        impacted_dates_num = oldEventDetails.impacts.length
    } else {
        const oldDatesMap = new Map()
        const newDatesMap = new Map()

        oldEventDetails.impacts.forEach((el) =>
            oldDatesMap.set(el.date, el.impact)
        )
        newEventDetails.impacts.forEach((el) =>
            newDatesMap.set(el.date, el.impact)
        )

        Array.from(newDatesMap.keys()).forEach((date) => {
            if (oldDatesMap.has(date)) {
                if (oldDatesMap.get(date) == newDatesMap.get(date)) {
                    oldDatesMap.delete(date)
                    newDatesMap.delete(date)
                } else {
                    oldDatesMap.delete(date)
                }
            }
        })

        impacted_dates_num = oldDatesMap.size + newDatesMap.size
    }

    utilTracking.track(TrackingMessages.EVENT_IMPACT_CONFIRM, {
        impacted_dates_num,
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
        avg_impact,
        min_impact,
        max_impact,
    })
}

export const eventsImpactFieldChange = (
    event: Event,
    value_originator: String
) =>
    utilTracking.track(TrackingMessages.EVENT_IMPACT_FIELD_CHANGE, {
        event_originator: event.isUserGenerated ? 'User' : 'System',
        event_type: event.eventType,
        value_originator,
    })
