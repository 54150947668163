import { ILanguageSpecificUrls, languageLimitedPages } from '~/config/languageLimitedPages'
import { getLanguageNotInUrl, getRouteLocale } from '~~/src/utils/route'

/**
 * checks if we have language specific routes & redirects to the specific language
 */
export default defineNuxtRouteMiddleware((from: any, to: any) => {
	// Every time the route changes (fired on initialization too)
	const language = getRouteLocale(to.path, 'en')
	const languageNotInUrl = getLanguageNotInUrl('en')
	const languageNormalizers = normalizersToUse(to, language, languageNotInUrl)

	if (languageNormalizers.length > 0 && !to.path.includes('smartpaying')) {
		return navigateTo(normalizeLanguage(languageNormalizers[0], to.fullPath, language, languageNotInUrl))
	}
})

const normalizersToUse = (to: any, language: string, languageNotInUrl: string) => {
	let url: string = to.path

	if (language != languageNotInUrl) {
		url = url.replace(`/${language}`, '')
	}

	return languageLimitedPages.filter((element) =>
		element.language != language && element.extendsToChilds ? url.startsWith(element.url) : url === element.url
	)
}

const normalizeLanguage = (
	normalizer: ILanguageSpecificUrls,
	url: string,
	language: string,
	languageNotInUrl: string
): string => {
	let newUrl = url
	newUrl = language != languageNotInUrl ? newUrl.replace(`/${language}`, '') : newUrl

	let newLanguage = normalizer.language
	newLanguage = newLanguage != languageNotInUrl ? `/${newLanguage}` : ''

	return newLanguage + newUrl
}
