import { lightFormat } from 'date-fns';
import { Range } from '../common/Range';

export const convertDateRangeToISODateRange = (
  date: Range<Date>
): Range<string> => {
  return {
    from: getISODate(date.from),
    to: getISODate(date.to),
  };
};

export const convertStringRangeToDateRange = (
  stringRange: Range<string>
): Range<Date> => {
  return {
    from: new Date(stringRange.from),
    to: new Date(stringRange.to),
  };
};

export const getISODate = (date: Date | string): string => {
  return lightFormat(new Date(date), 'yyyy-MM-dd');
};
