import { uniqueId } from 'lodash'
import { defineStore } from 'pinia'
import { NotificationDuration } from '../constants/notificationDuration'
import { IMessageCardData } from '../types/IMessageCardData'

interface INotificationMessageCardData extends IMessageCardData {
	creationHash: string
}

type NotificationsState = {
	notifications: INotificationMessageCardData[]
}

export const useNotificationsStore = defineStore('🚀 notifications store', {
	state: (): NotificationsState => ({
		notifications: [],
	}),
	actions: {
		addNotification(modal: IMessageCardData, timeout: NotificationDuration = NotificationDuration.MEDIUM): void {
			const creationHash = uniqueId('notification_')
			this.notifications.unshift({ ...modal, creationHash })
			setTimeout(() => {
				this.notifications = this.notifications.filter((el) => el.creationHash !== creationHash)
			}, timeout)
		},
		closeNotificationAtIndex(index: number) {
			this.notifications.splice(index, 1)
		},
	},
})
