import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationsPricesRequest,
  GetAccommodationsPricesRequestPayload,
} from './GetAccommodationsPricesRequest';

export class GetAccommodationsPricesNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsPricesRequest,
  GetAccommodationsPricesRequestPayload
> {
  constructor(params: GetAccommodationsPricesRequest) {
    super(params, WSMessageEvent.GetAccommodationsPrices);
  }

  override getParams(): GetAccommodationsPricesRequestPayload {
    const { accommodationIds, dateRange } = this.params;
    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
