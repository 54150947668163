import { useUserStore } from '../store/user'
import { Core } from '../config/Core'

export default defineNuxtRouteMiddleware(async (to) => {
	const { subscriptions } = useUserStore()

	if (to.fullPath.includes('auth')) {
		return
	}

	if (subscriptions) {
		// If the user is trying to visit a smartpricing-only route and
		// has a bad payment status for bundle `Smartpricing`, redirect to billing.
		if (
			Core.smartpricingOnlyRoutes.includes(to.fullPath) &&
			utilBadPayment.isBadSmartpricingPayment(subscriptions.paymentStatus)
		) {
			return to.fullPath.includes('billing') ? undefined : await navigateTo('/settings/billing')
		}

		// If the user is trying to visit a smartpaying-only route and
		// has a bad payment status for bundle `Smartpaying`, redirect to billing.
		if (
			Core.smartpayingOnlyRoutes.some((spRoute) => to.fullPath.startsWith(spRoute)) &&
			utilBadPayment.isBadSmartpayingPayment(subscriptions.paymentStatus)
		) {
			return to.fullPath.includes('billing') ? undefined : await navigateTo('/settings/billing')
		}
	}

	return
})
