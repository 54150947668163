import { Pinia, Store, getActivePinia } from 'pinia'
import { StorageKeys } from '../constants/storageKeys'
import { defaultLogoutRedirect } from './../config/LogoutConfig'

interface ExtendedPinia extends Pinia {
	_s: Map<string, Store>
}
const $ResetPinia = (): Record<string | 'all', () => void> => {
	const pinia = getActivePinia() as ExtendedPinia

	if (!pinia) {
		throw new Error('There is no stores')
	}

	const resetStores: Record<string, () => void> = {}

	pinia._s.forEach((store, name) => {
		resetStores[name] = () => store.$reset()
	})

	resetStores.all = () => pinia._s.forEach((store) => store.$reset())
	return resetStores
}

class UtilLogout {
	async logout(path?: string) {
		const { origin } = decodeJwt(localStorage.getItem(StorageKeys.SessionToken)!)

		utilWebSocket.killConnection()

		localStorage.removeItem(StorageKeys.SessionToken)
		localStorage.removeItem(StorageKeys.RefreshToken)
		sessionStorage.removeItem(StorageKeys.JustLogged)

		if (origin === 'ght') {
			await navigateTo(useRuntimeConfig().public.GHT_URL, { external: true })
			localStorage.removeItem(StorageKeys.SalesMode)
		} else {
			await navigateTo(path || defaultLogoutRedirect)
		}

		$ResetPinia().all()
	}
}

export const utilLogout = new UtilLogout()
