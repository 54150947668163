import {
	addDays,
	differenceInCalendarDays,
	differenceInDays,
	isAfter,
	isBefore,
	isPast,
	isSameDay,
	isToday,
} from 'date-fns'
import { basePricesConfiguration } from '../config/BasePrices'
import { BasePrice } from '../submodules/sharedTypes/common/BasePrice'
import { defaultEditingBasePrice, useBasePricesStore } from '../store/basePricesNew'
import { cloneDeep, isEqual } from 'lodash'
import { BasePriceEdited } from '../types/StartingPrices'

type NewBasePricePosition = {
	fromIndex: number
	toIndex: number
}

export const handleNewBasePriceInsertion = (newBasePrice: BasePriceEdited, roomTypeId?: number): BasePriceEdited[] => {
	const settingsStore = useSettingsStore()
	const basePricesStore = useBasePricesStore()

	let newBasePriceToInsert = cloneDeep(newBasePrice)
	let newBasePriceStartDateShifted = false

	const currentBasePrices = roomTypeId
		? cloneDeep(basePricesStore.basePricesEdited.get(settingsStore.selectedAccommodation!.id)!.get(roomTypeId)!)
		: cloneDeep(basePricesStore.getSelectedRoomTypeEditedBasePrices!)

	if (
		isEqual(newBasePriceToInsert, defaultEditingBasePrice.basePrice) ||
		(isPast(newBasePriceToInsert.dateRange.to) && !isToday(newBasePriceToInsert.dateRange.to))
	) {
		return currentBasePrices
	}

	// If there currently aren't any base prices, we simply return an array
	// containing the new base price that we want to add.
	if (currentBasePrices.length === 0) {
		return [newBasePriceToInsert]
	}

	// Compute where in the base prices list the new base price should be inserted at.
	const newBasePricePosition = computeNewBasePricePosition(currentBasePrices, newBasePriceToInsert)

	// If the new base price's date range is exactly overlapping (not contained within)
	// an existing base price's date range, we simply update the existing
	// base price's price to the new base price's price and we return the new list.
	if (
		newBasePricePosition.fromIndex === newBasePricePosition.toIndex &&
		isSameDay(newBasePriceToInsert.dateRange.from, currentBasePrices[newBasePricePosition.fromIndex].dateRange.from) &&
		isSameDay(newBasePriceToInsert.dateRange.to, currentBasePrices[newBasePricePosition.fromIndex].dateRange.to)
	) {
		currentBasePrices[newBasePricePosition.fromIndex].price = newBasePriceToInsert.price
		currentBasePrices[newBasePricePosition.fromIndex].isGap = newBasePriceToInsert.isGap

		return currentBasePrices
	}

	const currentBasePricesBeforeNewBasePrice = currentBasePrices.slice(0, newBasePricePosition.fromIndex)
	const currentBasePricesAfterNewBasePrice = currentBasePrices.slice(newBasePricePosition.toIndex + 1)

	// Compute all the base prices chunks derived from the new base price
	// overlapping with already existing base prices. This means, for example,
	// breaking up into smaller date ranges the periods that overlap with the new base price.
	const basePriceChunksAfterInsertion = computeNewBasePriceChunks(
		currentBasePrices,
		newBasePriceToInsert,
		newBasePricePosition,
		newBasePriceStartDateShifted
	)

	return [
		...currentBasePricesBeforeNewBasePrice,
		...basePriceChunksAfterInsertion,
		...currentBasePricesAfterNewBasePrice,
	]
}

const computeNewBasePricePosition = (
	currentBasePrices: BasePriceEdited[],
	newBasePrice: BasePriceEdited
): NewBasePricePosition => {
	let fromIndex = -1
	let toIndex = -1

	// Cycle through the current base prices list to find the correct position for the new base price.
	// We do this because the new base price might be overlapping with one or more
	// base prices already in the list.
	for (const [index, basePrice] of currentBasePrices.entries()) {
		// If the new base price's date range is within the range of an existing base price
		// or overlaps with it completely, we set both `fromIndex` and `toIndex`
		// to the index of that base price.
		if (
			newBasePrice.dateRange.from >= basePrice.dateRange.from &&
			newBasePrice.dateRange.to <= basePrice.dateRange.to
		) {
			return {
				fromIndex: index,
				toIndex: index,
			}
		}

		// If the new base price's `from` date is after the `from` date of an
		// existing base price, we se `fromIndex` to the index of that base price.
		if (newBasePrice.dateRange.from >= basePrice.dateRange.from) {
			fromIndex = index
		}
		// If the new base price's `to` date is after the `from` date of an
		// existing base price, we set `toIndex` to the index of that base price.
		if (newBasePrice.dateRange.to >= basePrice.dateRange.from) {
			toIndex = index
		}
	}

	// NOTE: If the new base price's date range does not overlap with a
	// base price completely (i.e. it is fully contained within the existing
	// base price or it overlaps with multiple already existing base prices),
	// we will compute the remaining chunks that will go next to the new base price
	// in the `computeNewBasePriceChunks` function.
	return {
		fromIndex: fromIndex,
		toIndex: toIndex,
	}
}

const computeNewBasePriceChunks = (
	currentBasePrices: BasePriceEdited[],
	newBasePrice: BasePriceEdited,
	newBasePricePosition: NewBasePricePosition,
	newBasePriceStartDateShifted: boolean
): BasePriceEdited[] => {
	const chunks: BasePriceEdited[] = []
	const { currentlyEditingBasePrice } = useBasePricesStore()

	const firstOverlappingPeriod = currentBasePrices[newBasePricePosition.fromIndex]
	const lastOverlappingPeriod = currentBasePrices[newBasePricePosition.toIndex]

	// If the new base price has no overlapping with the currently editing base price,
	// we should create a gap where the currently editing base price is.
	let hasCurrentlyEditingBasePriceChangedCompletely = false
	if (
		newBasePrice.dateRange.from > currentlyEditingBasePrice.basePrice.dateRange?.to ||
		newBasePrice.dateRange.to < currentlyEditingBasePrice.basePrice.dateRange?.from
	) {
		hasCurrentlyEditingBasePriceChangedCompletely = true
		const currentlyEditingBasePriceIndex = currentBasePrices.findIndex(
			(basePrice) =>
				isSameDay(basePrice.dateRange.from, currentlyEditingBasePrice.basePrice.dateRange?.from) &&
				isSameDay(basePrice.dateRange.to, currentlyEditingBasePrice.basePrice.dateRange?.to)
		)
		if (currentlyEditingBasePriceIndex > -1) {
			currentBasePrices[currentlyEditingBasePriceIndex].isGap = true
			currentBasePrices[currentlyEditingBasePriceIndex].price = undefined
		}
	}

	// I don't even know how to explain why I'm doing this but it works.
	// ... Just kidding, I do know:
	// If we're extending the selected base price period either backwards or forwards,
	// we shouldn't create a gap since we can just shrink the periods next to it.
	// Instead, if we're shrinking the selected base price period, we should create a gap
	// for those periods which were previously inside the selected base price period.
	// NOTE: we only create gaps if the editing base price has not changed completely,
	// meaning it's start date is after the new base price's end date or
	// it's end date is before the new base price's start date.
	// NOTE: we only create gaps for periods/portions of periods that go from the current date
	// onwards, since we don't want to create gaps or set to undefined prices for periods
	// that are in the past.
	const shouldCreateGapBeforeNewBasePrice =
		!isPast(addDays(newBasePrice.dateRange.from, -1)) &&
		!newBasePriceStartDateShifted &&
		!hasCurrentlyEditingBasePriceChangedCompletely &&
		(newBasePricePosition.fromIndex === newBasePricePosition.toIndex
			? newBasePrice.dateRange.from > firstOverlappingPeriod.dateRange.from
			: newBasePrice.dateRange.from > firstOverlappingPeriod.dateRange.to ||
				(newBasePrice.dateRange.from <= firstOverlappingPeriod.dateRange.to &&
					newBasePrice.dateRange.from > currentlyEditingBasePrice.basePrice.dateRange?.from))
	const periodPriceBeforeNewBasePrice =
		shouldCreateGapBeforeNewBasePrice && !hasCurrentlyEditingBasePriceChangedCompletely
			? undefined
			: firstOverlappingPeriod.price

	const shouldCreateGapAfterNewBasePrice =
		!hasCurrentlyEditingBasePriceChangedCompletely &&
		(newBasePricePosition.fromIndex === newBasePricePosition.toIndex
			? newBasePrice.dateRange.to < lastOverlappingPeriod.dateRange.to
			: newBasePrice.dateRange.to < lastOverlappingPeriod.dateRange.from ||
				(newBasePrice.dateRange.to >= lastOverlappingPeriod.dateRange.from &&
					newBasePrice.dateRange.to < currentlyEditingBasePrice.basePrice.dateRange?.to))
	const periodPriceAfterNewBasePrice =
		shouldCreateGapAfterNewBasePrice && !hasCurrentlyEditingBasePriceChangedCompletely
			? undefined
			: lastOverlappingPeriod.price

	// If the new base price's date range starts after the first overlapping
	// period's date range, we add a new chunk that goes from the first overlapping
	// period's `from` date to the day before the new base price's `from` date.
	if (newBasePrice.dateRange.from > firstOverlappingPeriod.dateRange.from) {
		chunks.push({
			isGap: shouldCreateGapBeforeNewBasePrice,
			price: periodPriceBeforeNewBasePrice,
			dateRange: {
				from: firstOverlappingPeriod.dateRange.from,
				to: addDays(newBasePrice.dateRange.from, -1),
			},
		})
	}

	// In any case, we add the new base price as a chunk. This would be:
	// - the first chunk if the new base price's date range starts before
	//   the first overlapping period's date range.
	// - the last chunk if the new base price's date range ends after
	//   the last overlapping period's date range.
	// - the middle chunk if both of the above conditions are false.
	chunks.push(newBasePrice)

	// If the new base price's date range ends before the last overlapping
	// period's date range, we add a new chunk that goes from the day after
	// the new base price's `to` date to the last overlapping period's `to` date.
	if (newBasePrice.dateRange.to < lastOverlappingPeriod.dateRange.to) {
		chunks.push({
			isGap: shouldCreateGapBeforeNewBasePrice,
			price: periodPriceBeforeNewBasePrice,
			dateRange: {
				from: addDays(newBasePrice.dateRange.to, 1),
				to: lastOverlappingPeriod.dateRange.to,
			},
		})
	}

	return chunks
}

export const computeValidBasePricesFromEdited = (editedBasePrices: BasePriceEdited[]): BasePrice[] => {
	return editedBasePrices
		.filter((basePrice) => basePrice.price !== undefined)
		.map((basePrice) => ({
			dateRange: basePrice.dateRange,
			price: basePrice.price!,
		}))
}

export const isBasePricesRangeValid = (basePrices: BasePriceEdited[]): boolean => {
	return (
		basePrices.length > 0 &&
		satisfiesMinimumDaysToFillWithPricesRequirement(basePrices) &&
		satisfiesMinimumDaysInRangeRequirement(basePrices) &&
		hasBasePriceForToday(basePrices) &&
		!hasBasePriceGaps(basePrices)
	)
}

export const satisfiesMinimumDaysToFillWithPricesRequirement = (basePrices: BasePriceEdited[]): boolean => {
	const currentDate = new Date()
	const basePricesLastDate = basePrices[basePrices.length - 1].dateRange.to

	return (
		differenceInCalendarDays(basePricesLastDate, currentDate) + 1 >= basePricesConfiguration.minimumDaysToFillWithPrices
	)
}

export const satisfiesMinimumDaysInRangeRequirement = (basePrices: BasePriceEdited[]): boolean => {
	const basePricesStore = useBasePricesStore()

	const basePricesToConsider = basePrices.filter(
		(basePrice) => !isPast(basePrice.dateRange.to) || isToday(basePrice.dateRange.to)
	)
	const basePricesMinimumDaysInRange = basePricesStore.minimumDaysInRange

	// If the first period we're checking is actually the first ever generated period,
	// we don't need to check if it satisfies the minimum days in range requirement.
	if (
		basePricesToConsider[0].dateRange.from === basePrices[0].dateRange.from &&
		basePricesToConsider[0].dateRange.to === basePrices[0].dateRange.to
	) {
		// We remove the first base price range since it does not need to satisfy the minimum days in range requirement.
		return basePricesToConsider
			.slice(1)
			.every(
				(basePrice) =>
					differenceInCalendarDays(basePrice.dateRange.to, basePrice.dateRange.from) + 1 >= basePricesMinimumDaysInRange
			)
	}

	// If the first period we're checking is not the first ever generated period,
	// we need to check if it satisfies the minimum days in range requirement.
	return basePricesToConsider.every(
		(basePrice) =>
			differenceInCalendarDays(basePrice.dateRange.to, basePrice.dateRange.from) + 1 >= basePricesMinimumDaysInRange
	)
}

export const hasBasePriceForToday = (basePrices: BasePriceEdited[]): boolean => {
	const currentDate = new Date()

	return basePrices.some((basePrice) => {
		const periodStartsBeforeToday = isBefore(basePrice.dateRange.from, currentDate)
		const periodEndsAfterToday = isAfter(basePrice.dateRange.to, currentDate)

		return (
			(periodStartsBeforeToday && periodEndsAfterToday) ||
			isToday(basePrice.dateRange.from) ||
			isToday(basePrice.dateRange.to)
		)
	})
}

export const hasBasePriceGaps = (basePrices: BasePriceEdited[]): boolean => {
	const currentDate = new Date()
	const currentBasePricePeriodIndex = basePrices.findIndex(
		(basePrice) =>
			isSameDay(currentDate, basePrice.dateRange.from) ||
			isSameDay(currentDate, basePrice.dateRange.to) ||
			(isBefore(currentDate, basePrice.dateRange.to) && isAfter(currentDate, basePrice.dateRange.from))
	)

	const basePricesFromToday =
		currentBasePricePeriodIndex > 0 ? basePrices.slice(currentBasePricePeriodIndex - 1) : basePrices

	return basePricesFromToday.some((basePrice, basePriceIndex) => {
		if (basePriceIndex > 0) {
			return basePrice.isGap === true
		}
	})
}
