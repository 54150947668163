export enum PaymentStatus {
  Regular = 'regular',
  Warning = 'warning',
  Severe = 'severe',
  Ban = 'ban',
}

export enum SubscriptionBundle {
  Smartpricing = 'smartpricing',
  Smartpaying = 'smartpaying',
}

export type PaymentStatusPerBundle = {
  [key in SubscriptionBundle]: PaymentStatus;
};

export type SubscriptionsData = {
  paymentStatus: PaymentStatusPerBundle;
  accountLockDate?: Date;
  trialPeriodExpirationDate?: Date;
};
