import { addDays } from 'date-fns'
import { defineStore } from 'pinia'
import { calendarConfig } from '../config/CalendarConfig'
import { LoadingIds } from '../constants/loadingIds'
import { GetAccommodationsOccupanciesResponse } from '../submodules/sharedTypes/communication/accommodations/occupancies/GetAccommodationsOccupanciesResponse'
import { utilNetwork } from '../utils/UtilNetwork'
import { Occupancy } from './../submodules/sharedTypes/common/Occupancy'
import { GetAccommodationsOccupanciesNetworkObject } from './../submodules/sharedTypes/communication/accommodations/occupancies/GetAccommodationsOccupanciesNetworkObject'
import { useAccommodationsStore } from './accommodations'
import { useLoadingStore } from './loading'

type OccupancyState = {
	startDate: Date
	roomTypeOccupancy: Map<number | string, Occupancy[]>
	accommodationOccupancy: Map<number | string, Occupancy[]>
}

export const useOccupancyStore = defineStore('🏨 occupancy', {
	state: (): OccupancyState => ({
		startDate: new Date(),
		roomTypeOccupancy: new Map<number | string, Occupancy[]>(),
		accommodationOccupancy: new Map<number | string, Occupancy[]>(),
	}),
	actions: {
		setStartDate(date: Date): void {
			this.startDate = date
		},
		addOccupancies(res: GetAccommodationsOccupanciesResponse) {
			const localOccupancies: {
				accommodations: Record<number | string, Occupancy[]>
				roomTypes: Record<number | string, Occupancy[]>
			} = {
				accommodations: {},
				roomTypes: {},
			}

			res.accommodations.forEach((accommodationPrice) => {
				accommodationPrice.occupancies.forEach((occupancy) => {
					const destination = occupancy.roomTypeId == undefined ? 'accommodations' : 'roomTypes'
					const indexToUse = occupancy.roomTypeId == undefined ? occupancy.accommodationId : occupancy.roomTypeId

					if (!localOccupancies[destination][indexToUse]) localOccupancies[destination][indexToUse] = []
					localOccupancies[destination][indexToUse].push(occupancy)
				})
			})

			Object.keys(localOccupancies.accommodations).forEach((accommodationId) => {
				this.accommodationOccupancy.set(accommodationId, localOccupancies.accommodations[accommodationId])
			})
			Object.keys(localOccupancies.roomTypes).forEach((roomTypeId) => {
				this.roomTypeOccupancy.set(Number(roomTypeId), localOccupancies.roomTypes[roomTypeId])
			})
		},

		clearOccupancy() {
			this.roomTypeOccupancy.clear()
			this.accommodationOccupancy.clear()
		},
		async requestOccupancy() {
			this.clearOccupancy()
			const loadingStore = useLoadingStore()
			await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

			const accommodations = useAccommodationsStore().accommodationsToShow.length
				? useAccommodationsStore().accommodationsToShow
				: useAccommodationsStore().accommodations.map((element) => element.id)

			const request = new GetAccommodationsOccupanciesNetworkObject({
				accommodationIds: accommodations,
				dateRange: {
					from: this.startDate,
					to: addDays(this.startDate, calendarConfig.maxDays - 1),
				},
			})

			utilNetwork.simpleRequest(request, undefined, LoadingIds.OCCUPANCY)
		},
	},
	getters: {
		getOccupancyByRoomTypeId() {
			return (id: number) => this.roomTypeOccupancy.get(id) || []
		},
		getOccupancyByAccommodationId() {
			return (id: number) => this.accommodationOccupancy.get(id) || []
		},
	},
})
