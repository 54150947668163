import { Environments } from '../constants/environments'

class UtilHelpDesk {
    openChatbot() {
        const isDev =
            useRuntimeConfig().public.ENVIRONMENT === Environments.Development

        if (isDev) {
            // TODO:
            // - create a loca text as follows: >>0<<
            // - create a ban list for text which are available only on dev
            // - add said loca to the list
            // trigger an info notification saying the freshDesk opening is prevented in dev
        }
        ;(window as any).HubSpotConversations.widget.open()
    }
}

export const utilHelpDesk = new UtilHelpDesk()
