import { SpSvg } from '~/autogen/SpSvg'

const symbolMap = {
    '€': SpSvg.CalendarEuroSmall,
    $: SpSvg.CalendarDollarSmall,
}

export const currencySymbolToIcon = (symbol: string): SpSvg =>
    // @ts-ignore
    symbolMap[symbol] || symbolMap['€']
