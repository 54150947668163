import { WSMessageEvent } from '../../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../../utils/dates';
import {
  GetRoomTypeDateDetailsRequest,
  GetRoomTypeDateDetailsRequestPayload,
} from './GetRoomTypeDateDetailsRequest';

export class GetRoomTypeDateDetailsNetworkObject extends WebSocketNetworkObject<
  GetRoomTypeDateDetailsRequest,
  GetRoomTypeDateDetailsRequestPayload
> {
  constructor(params: GetRoomTypeDateDetailsRequest) {
    super(params, WSMessageEvent.GetRoomTypeDateDetails);
  }

  override getParams(): GetRoomTypeDateDetailsRequestPayload {
    const { roomTypeId, date } = this.params;
    return {
      roomTypeId,
      date: getISODate(date),
    };
  }
}
