import { INetworkObject } from './INetworkObject';
import { NetworkCommunicationType } from './NetworkCommunicationType';

export class WebSocketNetworkObject<T, K = T> implements INetworkObject<T, K> {
  readonly communicationType = NetworkCommunicationType.WEB_SOCKET;
  readonly params: T;
  readonly connectionString: string;

  constructor(params: T, connectionString: string) {
    this.params = params;
    this.connectionString = connectionString;
  }

  getConnectionString(): string {
    return this.connectionString;
  }
  getParams(): K {
    // TODO: compare types and throw must implement
    // @ts-ignore
    return this.params;
  }
}
