export enum ButtonSizes {
    AUTO_SIZE = 'AUTO_SIZE',

    TEXT_L = 'TEXT_L',
    TEXT_M = 'TEXT_M',
    TEXT_S = 'TEXT_S',
    ICON_L = 'ICON_L',
    ICON_M = 'ICON_M',
    ICON_S = 'ICON_S',
}
