import { AccountingLoginNetworkObject } from '@/submodules/sharedTypes/communication/auth/accounting-login/AccountingLoginNetworkObject'
import { AccountingLoginResponse } from '@/submodules/sharedTypes/communication/auth/accounting-login/AccountingLoginResponse'
import { StorageKeys } from '../constants/storageKeys'
import * as jose from 'jose'
import { SalesModes } from '../constants/salesMode'

/**
 * Decode jwt token
 *
 * @param jwt
 * @returns
 */
export function decodeJwt(jwt: string) {
	return jose.decodeJwt(jwt)
}

export const isSessionActive = () => {
	const token = localStorage.getItem(StorageKeys.SessionToken)
	const refreshToken = localStorage.getItem(StorageKeys.RefreshToken)
	return token != undefined && refreshToken != undefined
}

/*
 * Returns whether the base token is valid or not.
 * base64 encoding requires the length of the string to be a multiple of 4, if it's not a multiple of 4
 * the string is padded with as many '=' as needed. We currently encrypt as follows:
 * plain => A&S => BASE64 => URL_ENCODE
 * We cannot spend too much computing time on decoding, but we can surely check quickly if it's base64 by dividing by 4
 *
 * @param {string} token to validate
 * */
export const isTokenValid = (token: string) => {
	const isBase64 = token.length % 4 == 0
	if (!isBase64) {
		console.error('aaaaa not base 64!!!! ', token.length)
		console.warn(token)

		return false
	}

	return true
}

/*
 * Handle accounting token login.
 *
 * @param {string} accountingToken to validate
 *
 * */
export const handleAccountingToken = async (accountingToken: string) => {
	const isAccountingTokenValid = isTokenValid(accountingToken)
	if (!isAccountingTokenValid) {
		return
	}

	await utilNetwork.simpleNonAuthorizedRequest(
		new AccountingLoginNetworkObject({
			accountingToken,
		}),
		onAccountingResponse,
		onAccountingFailed
	)
}

export const onAccountingResponse = (res: AccountingLoginResponse) => {
	localStorage.setItem(StorageKeys.SessionToken, res.tokenData.token)
	localStorage.setItem(StorageKeys.RefreshToken, res.tokenData.refreshToken)

	if (!localStorage.getItem(StorageKeys.SalesMode)) {
		localStorage.setItem(StorageKeys.SalesMode, SalesModes.Hotel)
	}
}

export const onAccountingFailed = (err: any) => {
	console.error('something is wrong with the token! Logout enforced.')

	utilLogout.logout()
}

/*
 * Handle GHT demo token login.
 *
 * @param {string} token to validate
 *
 * */
export const handleGhtDemoToken = async (token: string) => {
	localStorage.setItem(StorageKeys.SessionToken, token)
	localStorage.setItem(StorageKeys.RefreshToken, token)
}
