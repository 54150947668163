// @ts-nocheck
import { useBillingStore } from '../store/billing'
import { usePricesStore } from '../store/prices'
import { Price } from '../submodules/sharedTypes/common/Price'
import { AnonymizationStructure, SalesModesConfig } from './../config/SalesModesConfig'
import { SalesModes } from './../constants/salesMode'
import { useAccommodationsStore } from './../store/accommodations'
import { Accommodation } from './../submodules/sharedTypes/common/Accommodation'
import { RoomType } from './../submodules/sharedTypes/common/RoomType'
export interface CheatHandler {
	cheatName: string
	cheat: () => void
}

export const activateSalesMode = (salesMode: SalesModes): void => {
	const config = SalesModesConfig[salesMode]
	anonymizeStoreAccommodations(config)
	anonymizeStoreRoomTypes(config)
	anonymizeStorePrices(config)
	useBillingStore().anonimizeDeanonymize()
	useCompetitorsStore().anonymizeDeanonymizeCurrentAccommodation('anon', config)
}

const anonymizeStoreAccommodations = (salesConfig: AnonymizationStructure) => {
	const accommodations: Accommodation[] = useAccommodationsStore().accommodations
	anonimyzeAccommodations(salesConfig, accommodations)
}

export const anonimyzeAccommodations = (salesConfig: AnonymizationStructure, accommodations: Accommodation[]) => {
	const name = useLocale().translate(salesConfig.accommodation)

	accommodations.forEach((el: any, index) => {
		// if we didn't anonymise already, we save the original name
		if (el._name == undefined) {
			el._name = el.name
		}

		// we don't display "Hotel 1", just from 2 onwards
		el.name = index == 0 ? name : name + ' ' + (index + 1)
	})
}

const anonymizeStoreRoomTypes = (salesConfig: AnonymizationStructure) => {
	const accommodationStore = useAccommodationsStore()
	const accommodations: Accommodation[] = accommodationStore.accommodations
	const roomTypes: RoomType[] = accommodationStore.roomTypes
	anonymizeRooms(salesConfig, accommodations, roomTypes)
}

export const anonymizeRooms = (
	salesConfig: AnonymizationStructure,
	accommodations: Accommodation[],
	roomTypes: RoomType[]
) => {
	const name = useLocale().translate(salesConfig.rooms)

	accommodations.forEach((accommodation) => {
		accommodation.roomTypes?.forEach((room: any, roomIndex) => {
			if (room._name == undefined) {
				room._name = room.name
			}
			room.name = name + ' ' + (roomIndex + 1)

			if (room.linkedRoomType?.name != undefined) {
				if (room.linkedRoomType._name == undefined) {
					room.linkedRoomType._name = room.linkedRoomType.name
				}

				room.linkedRoomType.name = name + ' ???'
			}

			roomTypes.map((el: any) => {
				if (el.id === room.id) {
					if (el._name == undefined) {
						el._name = el.name
					}
					el.name = name + ' ' + (roomIndex + 1)

					if (el.linkedRoomType?.name != undefined) {
						if (el.linkedRoomType._name == undefined) {
							el.linkedRoomType._name = el.linkedRoomType.name
						}

						el.linkedRoomType.name = name + ' ???'
					}
				}
				return el
			})
		})
	})
}

const anonymizeStorePrices = (salesConfig: AnonymizationStructure) => {
	const pricesStore = usePricesStore()
	const allPrices = pricesStore.roomTypePrices
	allPrices.forEach((prices, key) => allPrices.set(key, getAnonymizedPrices(prices, salesConfig)))
}

export const getAnonymizedPrices = (prices: Price[], salesConfig: AnonymizationStructure): Price[] =>
	prices.map((price) => getAnonymizedPrice(price, salesConfig))

export const getAnonymizedPrice = (price: Price, salesConfig: AnonymizationStructure) => {
	const anonymize = price.indexedPrice?.linkedRoomType?.name != undefined

	let copy = { ...price }
	if (anonymize) {
		const _name =
			// @ts-ignore
			price.indexedPrice!.linkedRoomType._name || price.indexedPrice!.linkedRoomType.name
		const name = useLocale().translate(salesConfig.linkedPrices)
		copy = {
			...price,
			indexedPrice: {
				...price.indexedPrice,
				linkedRoomType: {
					...price.indexedPrice!.linkedRoomType,
					// @ts-ignore
					_name,
					name,
				},
			},
		}
	}

	return copy
}

export const deactivateSalesMode = (): void => {
	deanonimyzeAccommodations()
	deanonymizeRooms()
	deanonymizePrices()
	useBillingStore().anonimizeDeanonymize()
	useCompetitorsStore().anonymizeDeanonymizeCurrentAccommodation('deanon')
}

const deanonimyzeAccommodations = () => {
	const accommodationStore = useAccommodationsStore()
	accommodationStore.accommodations.forEach((el: any) => {
		if (el._name != undefined) {
			el.name = el._name
			delete el._name
		}
	})
}

const deanonymizeRooms = () => {
	const accommodationStore = useAccommodationsStore()

	accommodationStore.accommodations.forEach((accommodation) => {
		accommodation.roomTypes?.forEach((room: any, roomIndex) => {
			if (room._name != undefined) {
				room.name = room._name
				delete room._name
			}

			if (room.linkedRoomType?._name != undefined) {
				room.linkedRoomType.name = room.linkedRoomType._name
				delete room.linkedRoomType._name
			}

			accommodationStore.roomTypes.map((el: any) => {
				if (el.id === room.id) {
					if (el._name != undefined) {
						el.name = el._name
						delete el._name
					}

					if (el.linkedRoomType?._name != undefined) {
						el.linkedRoomType.name = el.linkedRoomType._name
						delete el.linkedRoomType._name
					}
				}
				return el
			})
		})
	})
}

const deanonymizePrices = () => {
	const pricesStore = usePricesStore()

	pricesStore.roomTypePrices.forEach((priceList) => {
		priceList.forEach((price, key) => {
			const isAnonymized = price.indexedPrice?.linkedRoomType?._name != undefined

			if (isAnonymized) {
				price.indexedPrice!.linkedRoomType!.name = price.indexedPrice!.linkedRoomType!._name
			}
		})
	})
}

