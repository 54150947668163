import isSameDay from 'date-fns/isSameDay'
import { Range } from '~/submodules/sharedTypes/common/Range'
import { CardTypes } from '../constants/cardTypes'
import { IMessageCardData } from './../types/IMessageCardData'
import { utilDate } from './../utils/utilDate'
import { TranslationKeys } from '~/i18n/TranslationKeys'

export const makeModifiersNotification = (ids: number[], dateRange: Range<Date>): IMessageCardData => {
	if (ids.length === 1 && isSameDay(dateRange.from, dateRange.to)) {
		return {
			title: TranslationKeys.SINGLE_PRICE_UPDATE,
			message: TranslationKeys.PRICE_UPDATED_ON_DATE,
			messageReplacements: [utilDate.formatShortDate(dateRange.from)],
			canClose: true,
			cardType: CardTypes.SUCCESS,
		}
	} else {
		return {
			title: TranslationKeys.CUSTOMIZE_PRICES,
			message: TranslationKeys.PRICES_UPDATED_CONFIRMATION,
			canClose: true,
			cardType: CardTypes.SUCCESS,
		}
	}
}
