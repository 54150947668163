import { ComputedRef, Ref } from '@vue/runtime-dom'
import { animationsConfig } from '../config/Animations'

export const useRipple = (
    target: Ref<any>,
    color: Ref<string> | ComputedRef<string>
) => {
    const triggerRipple = (event: PointerEvent | MouseEvent) => {
        const ripple = document.createElement('span')
        const targetEl = target?.value?.$el ?? target?.value

        ripple.className =
            'absolute w-full h-full -translate-x-1/2 -translate-y-1/2 rounded-full animate-ripple ' +
            color.value

        const clientRect = targetEl.getBoundingClientRect()
        const x = event.clientX - clientRect.x + 'px'
        const y = event.clientY - clientRect.y + 'px'
        ripple.style.left = x
        ripple.style.top = y
        ripple.style.animationDuration =
            animationsConfig.rippleAnimationMs + 'ms'

        targetEl.appendChild(ripple)
        setTimeout(() => ripple.remove(), animationsConfig.rippleAnimationMs)
    }

    return { triggerRipple }
}
