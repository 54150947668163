import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45token_45grabber_45global from "/opt/build/repo/src/middleware/01.token-grabber.global.ts";
import _02_45force_45logout_45global from "/opt/build/repo/src/middleware/02.force-logout.global.ts";
import _03_45router_45language_45enforcer_45global from "/opt/build/repo/src/middleware/03.router-language-enforcer.global.ts";
import _04_45reset_45mobile_45actions_45global from "/opt/build/repo/src/middleware/04.reset-mobile-actions.global.ts";
import _05_45user_45status_45global from "/opt/build/repo/src/middleware/05.user-status.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.7.6_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_ufivkgwqzhqsm5sbq2lhy66qe4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45token_45grabber_45global,
  _02_45force_45logout_45global,
  _03_45router_45language_45enforcer_45global,
  _04_45reset_45mobile_45actions_45global,
  _05_45user_45status_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}