import { addDays } from 'date-fns'
import { defineStore } from 'pinia'
import { LoadingIds } from '../constants/loadingIds'
import { MarketDemand } from './../submodules/sharedTypes/common/MarketDemand'
import { GetAccommodationsMarketDemandNetworkObject } from './../submodules/sharedTypes/communication/accommodations/market-demand/GetAccommodationsMarketDemandNetworkObject'
import { GetAccommodationsMarketDemandResponse } from './../submodules/sharedTypes/communication/accommodations/market-demand/GetAccommodationsMarketDemandResponse'
import { utilNetwork } from './../utils/UtilNetwork'
import { useAccommodationsStore } from './accommodations'
import { useLoadingStore } from './loading'

type UserState = {
	demandsByAccommodationId: Map<Number, MarketDemand[]>
	startDate: Date
}

export const useMarketDemandStore = defineStore('🏨 market demand', {
	state: (): UserState => ({
		demandsByAccommodationId: new Map(),
		startDate: new Date(),
	}),
	actions: {
		async setDemands(demands: GetAccommodationsMarketDemandResponse) {
			demands.accommodations.forEach((el) => {
				this.demandsByAccommodationId.set(el.id, el.marketDemand)
			})
		},
		setStartDate(date: Date) {
			this.startDate = date
		},
		clearMarketDemands() {
			this.demandsByAccommodationId.clear()
		},
		async requestDemands() {
			this.clearMarketDemands()
			const loadingStore = useLoadingStore()
			await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

			const accommodations = useAccommodationsStore().accommodationsToShow.length
				? useAccommodationsStore().accommodationsToShow
				: useAccommodationsStore().accommodations.map((element) => element.id)

			utilNetwork.simpleRequest(
				new GetAccommodationsMarketDemandNetworkObject({
					accommodationIds: accommodations,
					dateRange: {
						from: this.startDate,
						to: addDays(this.startDate, 28),
					},
				})
			)
		},
	},
	getters: {
		getDemandsByAccommodationId(): (id: Number) => MarketDemand[] {
			return (id: Number) => this.demandsByAccommodationId.get(id) || []
		},
	},
})
