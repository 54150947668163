import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  BulkRemoveModifiersRequest,
  BulkRemoveModifiersRequestPayload,
} from './BulkRemoveModifiersRequest';

export class BulkRemoveModifiersNetworkObject extends WebSocketNetworkObject<
  BulkRemoveModifiersRequest,
  BulkRemoveModifiersRequestPayload
> {
  constructor(params: BulkRemoveModifiersRequest) {
    super(params, WSMessageEvent.BulkRemoveModifiers);
  }

  override getParams(): BulkRemoveModifiersRequestPayload {
    const { roomTypeIds, dateRange, selectedDaysOfWeek, modifierType } =
      this.params;

    return {
      roomTypeIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
      selectedDaysOfWeek,
      modifierType,
    };
  }
}
