<script lang="ts" setup>
import { TextSizes } from '~/constants/textSizes'
import { Localizable } from '@/types/Localizable'
import { ILinkReplacement } from '@/types/INavigationLink'
import { TranslationKey } from '~/i18n/TranslationKeys'

export interface CommonTextProps {
	text: Localizable | TranslationKey
	replacements?: string[]
	textSize?: TextSizes
	adjustCase?: boolean
	linkReplacements?: ILinkReplacement[]
	translate?: boolean
}

const props = withDefaults(defineProps<CommonTextProps>(), {
	replacements: () => [],
	textSize: TextSizes.BODY_REGULAR,
	adjustCase: true,
	linkReplacements: () => [],
	translate: true,
})

const { text, textSize, replacements, adjustCase, linkReplacements, translate } = toRefs(props)

const complexReplacements = computed(() => {
	if (linkReplacements?.value == undefined || linkReplacements.value.length < 1) {
		return replacements.value
	}

	const maxIndex = linkReplacements.value.reduce((prev, next) => Math.max(prev, next.replaceTokenNumber), 0)
	const newReplacements = [...replacements.value]

	for (let i = replacements.value.length; i < maxIndex; i++) {
		newReplacements.push('')
	}

	linkReplacements.value.forEach((el) => (newReplacements[el.replaceTokenNumber] = makeLink(el)))

	return newReplacements
})

const makeLink = (el: ILinkReplacement): string => {
	try {
		return `<a href="${el.link}" class="underline cursor-pointer text-main-blue-600">${useLocale().translate(el.text)}↗</a>`
	} catch (error) {
		useNuxtApp().$sentryCaptureException(error, {
			extra: {
				el,
			},
		})
		throw error
	}
}
</script>

<template>
	<span
		:class="textSize"
		v-if="text != undefined"
		v-html="translate ? useLocale().translate(text, complexReplacements, adjustCase) : text"
	/>
</template>
