import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  BulkUpdateModifiersRequest,
  BulkUpdateModifiersRequestPayload,
} from './BulkUpdateModifiersRequest';

export class BulkUpdateModifiersNetworkObject extends WebSocketNetworkObject<
  BulkUpdateModifiersRequest,
  BulkUpdateModifiersRequestPayload
> {
  constructor(params: BulkUpdateModifiersRequest) {
    super(params, WSMessageEvent.BulkUpdateModifiers);
  }

  override getParams(): BulkUpdateModifiersRequestPayload {
    const { modifiersByRoomType, selectedDaysOfWeek, dateRange } = this.params;

    return {
      dateRange: convertDateRangeToISODateRange(dateRange),
      selectedDaysOfWeek,
      modifiersByRoomType,
    };
  }
}
