import { nonRestrictedZones } from '../config/LogoutConfig'
import { isSessionActive } from '../utils/utilToken'

export default defineNuxtRouteMiddleware((to) => {
	// check if the path is not restricted
	const isPublicPath = nonRestrictedZones.some((zone) => to.path.startsWith(zone))

	if (isPublicPath) {
		return
	}

	if (!isSessionActive()) {
		const redirectAfterLoginPath = to.fullPath
		return navigateTo({
			path: '/auth/login',
			query: {
				redirect: redirectAfterLoginPath,
			},
		})
	}
})
