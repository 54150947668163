import axios, { type AxiosRequestConfig } from 'axios'

export class RestClient {
    static async $get<T = any>(path: string, config?: AxiosRequestConfig) {
        axios.defaults.baseURL = `${useRuntimeConfig().public.SMARTPAYING_API_URL}`
        try {
            const { data } = await axios.get(path, config)
            return data as T
        } catch (err) {
            throw err
        }
    }

    static async $post<T = any>(path: string, body: any, config?: AxiosRequestConfig) {
        axios.defaults.baseURL = `${useRuntimeConfig().public.SMARTPAYING_API_URL}`
        try {
            const { data } = await axios.post(path, body, config)
            return data as T
        } catch (err) {
            throw err
        }
    }

    static async $put<T = any>(path: string, body: any, config?: AxiosRequestConfig) {
        axios.defaults.baseURL = `${useRuntimeConfig().public.SMARTPAYING_API_URL}`
        try {
            const { data } = await axios.put(path, body, config)
            return data as T
        } catch (err) {
            throw err
        }
    }
}
