// these types are lowercase to match axios, in case of need we can
// use uppercase

export const enum RequestType {
  GET = 'get',
  SET = 'set',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
  OPTIONS = 'options',
  WSS = 'wss',
}
