import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationsEventsRequest,
  GetAccommodationsEventsRequestPayload,
} from './GetAccommodationsEventsRequest';

export class GetAccommodationsEventsNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsEventsRequest,
  GetAccommodationsEventsRequestPayload
> {
  constructor(params: GetAccommodationsEventsRequest) {
    super(params, WSMessageEvent.GetAccommodationsEvents);
  }

  override getParams(): GetAccommodationsEventsRequestPayload {
    const { accommodationIds, dateRange } = this.params;

    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
