import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  ToggleEventVisibilityRequest,
  ToggleEventVisibilityRequestPayload,
} from './ToggleEventVisibilityRequest';

export class ToggleEventVisibilityNetworkObject extends WebSocketNetworkObject<
  ToggleEventVisibilityRequest,
  ToggleEventVisibilityRequestPayload
> {
  constructor(params: ToggleEventVisibilityRequest) {
    super(params, WSMessageEvent.ToggleEventVisibility);
  }

  override getParams(): ToggleEventVisibilityRequestPayload {
    const { accommodationId, eventDate, eventId, eventType, isVisible } =
      this.params;

    return {
      accommodationId,
      eventDate: getISODate(eventDate),
      eventId,
      eventType,
      isVisible,
    };
  }
}
