export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const awaitRef = async (refToWatch: Ref) => {
	await new Promise<void>((resolve) => {
		watch(
			refToWatch,
			async () => {
				resolve()
			},
			{ once: true }
		)
	})
}
