import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationsMarketDemandRequest,
  GetAccommodationsMarketDemandRequestPayload,
} from './GetAccommodationsMarketDemandRequest';

export class GetAccommodationsMarketDemandNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsMarketDemandRequest,
  GetAccommodationsMarketDemandRequestPayload
> {
  constructor(params: GetAccommodationsMarketDemandRequest) {
    super(params, WSMessageEvent.GetAccommodationsMarketDemand);
  }

  override getParams(): GetAccommodationsMarketDemandRequestPayload {
    const { accommodationIds, dateRange } = this.params;
    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
