export const enum SpSvg {
	BasicAlertCircle = 'BasicAlertCircle',
	BasicArrowBottomSmall = 'BasicArrowBottomSmall',
	BasicArrowDown = 'BasicArrowDown',
	BasicArrowLeftSmall = 'BasicArrowLeftSmall',
	BasicArrowLeft = 'BasicArrowLeft',
	BasicArrowRightSmall = 'BasicArrowRightSmall',
	BasicArrowRight = 'BasicArrowRight',
	BasicArrowTopSmall = 'BasicArrowTopSmall',
	BasicArrowTop = 'BasicArrowTop',
	BasicBook = 'BasicBook',
	BasicBulb = 'BasicBulb',
	BasicCalculator = 'BasicCalculator',
	BasicCalendar = 'BasicCalendar',
	BasicCarretDown = 'BasicCarretDown',
	BasicCarretLeft = 'BasicCarretLeft',
	BasicCarretRight = 'BasicCarretRight',
	BasicCarretUp = 'BasicCarretUp',
	BasicCart = 'BasicCart',
	BasicCheckCircle = 'BasicCheckCircle',
	BasicCheck = 'BasicCheck',
	BasicComment = 'BasicComment',
	BasicCompetitors = 'BasicCompetitors',
	BasicCopy = 'BasicCopy',
	BasicCreditCard = 'BasicCreditCard',
	BasicDashboard = 'BasicDashboard',
	BasicDots = 'BasicDots',
	BasicDownload = 'BasicDownload',
	BasicEdit = 'BasicEdit',
	BasicEmojiHappy = 'BasicEmojiHappy',
	BasicEmojiQuite = 'BasicEmojiQuite',
	BasicEmojiSad = 'BasicEmojiSad',
	BasicEnter = 'BasicEnter',
	BasicErrorCircle = 'BasicErrorCircle',
	BasicExit = 'BasicExit',
	BasicFile = 'BasicFile',
	BasicGraduationCap = 'BasicGraduationCap',
	BasicHelp = 'BasicHelp',
	BasicHide = 'BasicHide',
	BasicHome = 'BasicHome',
	BasicInfoCircle = 'BasicInfoCircle',
	BasicLockClose = 'BasicLockClose',
	BasicLockOpen = 'BasicLockOpen',
	BasicMoney = 'BasicMoney',
	BasicPlusCircleLight = 'BasicPlusCircleLight',
	BasicPlus = 'BasicPlus',
	BasicSetting = 'BasicSetting',
	BasicShow = 'BasicShow',
	BasicSlider = 'BasicSlider',
	BasicSparkle = 'BasicSparkle',
	BasicSubMenu = 'BasicSubMenu',
	BasicTimesCircleLight = 'BasicTimesCircleLight',
	BasicTimesCircle = 'BasicTimesCircle',
	BasicTimes = 'BasicTimes',
	BasicTrash = 'BasicTrash',
	BasicUndo = 'BasicUndo',
	BasicUpload = 'BasicUpload',
	CalendarCopy = 'CalendarCopy',
	CalendarDollarSmall = 'CalendarDollarSmall',
	CalendarEuroSmall = 'CalendarEuroSmall',
	CalendarMinusSmall = 'CalendarMinusSmall',
	CalendarModFreeze = 'CalendarModFreeze',
	CalendarModLink = 'CalendarModLink',
	CalendarModMarkup = 'CalendarModMarkup',
	CalendarModMax = 'CalendarModMax',
	CalendarModMin = 'CalendarModMin',
	CalendarModSemiLink = 'CalendarModSemiLink',
	CalendarPercentSmall = 'CalendarPercentSmall',
	CalendarPlusSmall = 'CalendarPlusSmall',
	CalendarUserAlt = 'CalendarUserAlt',
	CalendarUser = 'CalendarUser',
	DashboardBarGraph = 'DashboardBarGraph',
	DashboardLineGraph = 'DashboardLineGraph',
	LangDe = 'LangDe',
	LangEn = 'LangEn',
	LangIt = 'LangIt',
}
