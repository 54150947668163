import { createGtm } from '@gtm-support/vue-gtm'
import { Environments } from '../constants/environments'

export default defineNuxtPlugin(({ vueApp }) => {
	const enablePlugin = useRuntimeConfig().public.ENVIRONMENT === Environments.Production

	// this avoids issues in dev & staging due to missing GTM_ID
	if (!enablePlugin) {
		return
	}

	vueApp.use(
		createGtm({
			id: useRuntimeConfig().public.GTM_ID as string, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
			// Pass the router instance to automatically sync with router (optional)
			vueRouter: useRouter(),
			// Whether or not call trackView in Vue.nextTick
			trackOnNextTick: true,
			enabled: enablePlugin,
			// Add `async` and `defer` to the script tag to not block requests for old
			// browsers that do not support `async`
			compatibility: true,
			// Script can be set to `defer` to speed up page load at the cost of less accurate results
			// (in case visitor leaves before script is loaded, which is unlikely but possible).
			defer: true,
		})
	)
})
