import { SpRange } from '@dev.smartpricing/sp-vue-components'
import { GetRulesByAccommodationIdResponse } from '../submodules/sharedTypes/communication/minimumStay/GetRulesByAccommodationIdResponse'
import { MinimumStayRule } from '../submodules/sharedTypes/common/MinimumStay'
import { GetRulesByAccommodationIdNetworkObject } from '../submodules/sharedTypes/communication/minimumStay/GetRulesByAccommodationIdNetworkObject'
import { LoadingIds } from '../constants/loadingIds'
import { cloneDeep } from 'lodash'
import { TrackingMessages } from '../constants/trackingMessages'
import { CardTypes } from '../constants/cardTypes'
import { DayOfTheWeek } from '@dev.smartpricing/sp-shared-network-freemium-types'

export const useMinimumStayStore = defineStore('minimun stay', () => {
	const minimumStayRules = ref<MinimumStayRule[]>()
	const originalMinimumStayRules = ref<MinimumStayRule[]>()
	const fetchMinStay = async (accommodationId: string) => {
		utilNetwork.simpleRequest(
			new GetRulesByAccommodationIdNetworkObject({ accommodationId }),
			undefined,
			LoadingIds.GET_MINIMUM_STAY_RULES
		)

		// reset rules on accommodation change
		minimumStayRules.value = []
	}
	const setMinStayFromResponse = (response: GetRulesByAccommodationIdResponse) => {
		minimumStayRules.value = response.rules

		if (!response.rules.length) {
			minimumStayRules.value = [
				{
					accommodationId: useSettingsStore().selectedAccommodation!.id,
					kind: 'default',
					minStay: {
						dayOfTheWeek: {
							[DayOfTheWeek.Monday]: undefined,
							[DayOfTheWeek.Tuesday]: undefined,
							[DayOfTheWeek.Wednesday]: undefined,
							[DayOfTheWeek.Thursday]: undefined,
							[DayOfTheWeek.Friday]: undefined,
							[DayOfTheWeek.Saturday]: undefined,
							[DayOfTheWeek.Sunday]: undefined,
						},
					},
					gapFilling: {
						active: false,
						dayOfTheWeek: {},
					},
					rollingRules: [],
				},
			]
		}

		originalMinimumStayRules.value = cloneDeep(minimumStayRules.value)
	}

	const { selectedRoomType, getSelectedTab } = storeToRefs(useSettingsStore())
	const defaultRule = computed(() => {
		const isAccommodationMode = getSelectedTab.value === 'accommodation-minimum-stay'

		if (selectedRoomType.value && !isAccommodationMode) {
			return minimumStayRules.value
				?.filter((rule) => !!rule.roomTypeId)
				.find((rule) => rule.kind === 'default' && rule.roomTypeId === selectedRoomType.value?.id.toString())
		}

		return minimumStayRules.value?.find((rule) => rule.kind === 'default')
	})
	const periodRules = computed(() => {
		const isAccommodationMode = getSelectedTab.value === 'accommodation-minimum-stay'

		if (selectedRoomType.value && !isAccommodationMode) {
			return minimumStayRules.value
				?.filter((rule) => !!rule.roomTypeId)
				.find((rule) => rule.kind === 'static' && rule.roomTypeId === selectedRoomType.value?.id.toString())
		}

		return minimumStayRules.value?.filter((rule) => rule.dateRange)
	})

	const addPeriod = (period: MinimumStayRule) => {
		minimumStayRules.value?.push({
			id: crypto.randomUUID(),
			...period,
		})
	}

	const editPeriod = (period: MinimumStayRule) => {
		if (period.kind === 'default') {
			const periodIndex = minimumStayRules.value?.findIndex((rule) => rule.kind === 'default') ?? -1
			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value[periodIndex] = period
			}
		} else {
			const periodIndex = minimumStayRules.value?.findIndex((rule) => rule.id === rule.id) ?? -1
			if (periodIndex > -1 && minimumStayRules.value) {
				minimumStayRules.value[periodIndex] = period
			}
		}
	}

	const removePeriod = (id: string) => {
		if (!id || !minimumStayRules.value) {
			return
		}

		const index = minimumStayRules.value.findIndex((rule) => rule.id === id)
		minimumStayRules.value.splice(index, 1)
	}

	// Save handlers
	const checkForChanges = computed(() => false)
	const save = () => {
		const addedRules: MinimumStayRule[] = []
		const editedRules: MinimumStayRule[] = []
		const removedRules: MinimumStayRule[] = []

		const originalRuleIds = originalMinimumStayRules.value?.map((rule) => rule.id).filter((id) => id)
		minimumStayRules.value?.forEach((rule) => {
			if (originalRuleIds?.includes(rule.id)) {
				editedRules.push(rule)
			} else {
				addedRules.push(rule)
			}
		})
		originalMinimumStayRules.value?.forEach((rule) => {
			if (!minimumStayRules.value?.find((r) => r.id === rule.id)) {
				removedRules.push(rule)
			}
		})

		const accommodationId = useSettingsStore().selectedAccommodation!.id
		if (editedRules.length) {
			// @ts-expect-error
			// utilNetwork.simpleRequest(new UpdateRuleByIdNetworkObject(editedRules))
		}
		if (addedRules.length) {
			// utilNetwork.simpleRequest(
			// 	new InsertRuleNetworkObject({
			// 		accommodationId,
			// 		rule: addedRules[0],
			// 	})
			// )
		}
		if (removedRules.length) {
			// @ts-expect-error
			// utilNetwork.simpleRequest(new DeleteRuleByIdNetworkObject(removedRules))
		}

		useNotificationsStore().addNotification({
			canClose: true,
			cardType: CardTypes.SUCCESS,
			title: TranslationKeys.BASE_PRICES_SAVED,
			message: TranslationKeys.BASE_PRICES_SAVED_TEXT,
		})
		utilTracking.track(TrackingMessages.SETTINGS_MIN_STAY_SAVE, {})
	}

	// Utils
	const $reset = () => {}

	return {
		minimumStayRules,
		fetchMinStay,
		setMinStayFromResponse,
		defaultRule,
		periodRules,

		addPeriod,
		editPeriod,
		removePeriod,

		checkForChanges,
		save,

		$reset,
	}
})
