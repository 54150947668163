export const enum IdModal {
	Mobile = 'Mobile',

	SaveVisualization = 'SaveVisualization',
	DeleteVisualization = 'DeleteVisualization',
	DeleteClosingPeriod = 'DeleteClosingPeriod',
	BadCustomer = 'BadCustomer',
	PriceCalculator = 'PriceCalculator',
	CalendarBulkUpdate = 'CalendarBulkUpdate',
	PriceJustifiersHelp = 'PriceJustifiersHelp',
	PlaygroundInfo = 'PlaygroundInfo',
	ResetStrategies = 'ResetStrategies',
	SaveStrategyWithModifiers = 'SaveStrategyWithModifiers',
	BulkUpdateModifiersConflict = 'BulkUpdateModifiersConflict',
	UserFeedback = 'UserFeedback',
	PasswordForgotten = 'PasswordForgotten',
	PasswordRecoverySent = 'PasswordRecoverySent',
	AddEvent = 'AddEvent',
	EventDetails = 'EventDetails',
	DisabledEventDetails = 'DisabledEventDetails',
	EventSave = 'EventSave',
	EventRemoveModifiers = 'EventRemoveModifiers',
	AddBasePrice = 'AddBasePrice',
	DuplicateBasePrice = 'DuplicateBasePrice',
	Overwrite = 'Overwrite',
	GenericConfirmation = 'GenericConfirmation',
}
