import { useMobileInfos } from '~/hooks/useMobileInfos'
import { CardTypes } from '../constants/cardTypes'
import { IdModal } from '../constants/IdModal'
import { useBannerStore } from './../store/banners'
import { useModalStore } from './../store/modals'
import {
	PaymentStatus,
	PaymentStatusPerBundle,
	SubscriptionBundle,
} from './../submodules/sharedTypes/common/SubscriptionsData'
import { utilDate } from './utilDate'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { Core } from '../config/Core'

class UtilBadPayment {
	bannerDisplayed: boolean = false

	public isBadSmartpricingPayment(paymentStatus: PaymentStatusPerBundle | undefined) {
		return paymentStatus?.[SubscriptionBundle.Smartpricing] === PaymentStatus.Ban
	}

	public isBadSmartpayingPayment(paymentStatus: PaymentStatusPerBundle | undefined) {
		return paymentStatus?.[SubscriptionBundle.Smartpaying] === PaymentStatus.Ban
	}

	public isBadPayment(paymentStatus: PaymentStatusPerBundle) {
		return this.isBadSmartpricingPayment(paymentStatus) || this.isBadSmartpayingPayment(paymentStatus)
	}

	public hasAtLeastOneGoodPayment(paymentStatus: PaymentStatusPerBundle) {
		return Object.values(paymentStatus).some((status) => status !== PaymentStatus.Ban)
	}

	public handlePaymentStatus(paymentStatus: PaymentStatusPerBundle | undefined, lockDate: Date | undefined) {
		const { fullPath } = useRoute()
		const { isMobile } = useMobileInfos()

		if (paymentStatus) {
			Object.keys(paymentStatus).forEach((bundle) => {
				if (
					fullPath !== '/settings/billing' &&
					((Core.smartpricingOnlyRoutes.includes(fullPath) && bundle === SubscriptionBundle.Smartpricing) ||
						(Core.smartpayingOnlyRoutes.some((spRoute) => fullPath.startsWith(spRoute)) &&
							bundle === SubscriptionBundle.Smartpaying) ||
						(!Core.smartpricingOnlyRoutes.includes(fullPath) && !Core.smartpayingOnlyRoutes.includes(fullPath)))
				) {
					switch (paymentStatus[bundle as SubscriptionBundle]) {
						case PaymentStatus.Warning: {
							isMobile.value ? null : this.openModal()
							break
						}
						case PaymentStatus.Severe: {
							this.displayBanner(lockDate)
							isMobile.value ? null : this.openModal()
							break
						}
						case PaymentStatus.Ban: {
							navigateTo('/settings/billing')
							break
						}
						default: {
							return
						}
					}
				}
			})
		}
	}

	private displayBanner(lockDate: Date | undefined) {
		if (!this.bannerDisplayed) {
			this.bannerDisplayed = true

			const bannerStore = useBannerStore()
			bannerStore.addBanner({
				canClose: false,
				title: TranslationKeys.UNPAID_INVOICES_DETECTED,
				cardType: CardTypes.ERROR,
				message: TranslationKeys.PAYMENT_ERROR_BANNER_TEXT,
				messageReplacements: [utilDate.formatDate(lockDate! || new Date())],
				linkText: TranslationKeys.UPDATE_YOUR_DATA,
				link() {
					utilHelpDesk.openChatbot()
				},
			})
		}
	}

	private openModal() {
		const modalStore = useModalStore()
		modalStore.addUniqueModal(IdModal.BadCustomer)
	}
}

export const utilBadPayment = new UtilBadPayment()
