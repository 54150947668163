// store with shared state
export const testStatus = reactive({
	stauts: false,
})

function $x(xpath: string, context: any = document) {
	const result = context.evaluate(xpath, context, null, XPathResult.ANY_TYPE, null)
	const nodes = []
	let node

	while ((node = result.iterateNext())) {
		nodes.push(node)
	}

	return nodes
}

export function useTesting() {
	const testIdsForCalendar = {
		'calendar-calculate-button': '/html/body/div[1]/div/div/div[1]/div[2]/div[1]/div/div/div[1]/div[2]/div/button',
		'calendar-calculate-popup': '/html/body/div[1]/div/div/div[1]/div[2]/div[5]/div/div[2]/div',
	}

	watch(testStatus, (newVal) => {
		if (newVal.stauts) {
			log('Testing mode enabled', 'Debug')
			mountTestIds()
		} else {
			log('Testing mode disabled', 'Debug')
			unmountTestIds()
		}
	})

	const mountTestIds = () => {
		if (!testStatus.stauts) return
		// we react to dom changes and every change we scan the document to add the test ids using their xpath

		Object.entries(testIdsForCalendar).forEach(([key, path]) => {
			const element = $x(path)[0] as Element

			if (element) {
				element.setAttribute('data-testid', key)
			}
		})
	}

	const unmountTestIds = () => {
		Object.entries(testIdsForCalendar).forEach(([key, path]) => {
			const element = $x(path)[0] as Element

			if (element) {
				element.removeAttribute('data-testid')
			}
		})
	}

	onMounted(() => {
		const body = document.querySelector('body')
		const observer = new MutationObserver(mountTestIds)

		if (body) {
			observer.observe(body, { childList: true, subtree: true })
		}
	})
}
