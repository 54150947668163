import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(({ vueApp }) => {
	const { SENTRY_DSN, SENTRY_ENVIRONMENT } = useRuntimeConfig().public
	const isSentryLocalEnv = SENTRY_ENVIRONMENT === 'local'
	const isSentryDevEnv = SENTRY_ENVIRONMENT === 'development'
	const router = useRouter()

	Sentry.init({
		app: vueApp,
		dsn: SENTRY_DSN,
		enabled: !isSentryLocalEnv,
		environment: SENTRY_ENVIRONMENT,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
		],
		tracesSampleRate: isSentryDevEnv ? 1.0 : 0.1,
		tracePropagationTargets: [], //['localhost', /\b(?:[\w-]+\.)*smartpricing\.it\b/],
		attachStacktrace: true,
		autoSessionTracking: true,
		normalizeDepth: 7,
	})

	return {
		provide: {
			sentrySetContext: Sentry.setContext,
			sentrySetUser: Sentry.setUser,
			sentrySetTag: Sentry.setTag,
			sentryAddBreadcrumb: Sentry.addBreadcrumb,
			sentryCaptureException: Sentry.captureException,
		},
	}
})
