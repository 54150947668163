import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  BulkUpdateModifiersMultipleRoomTypesRequest,
  BulkUpdateModifiersMultipleRoomTypesRequestPayload,
} from './BulkUpdateModifiersMultipleRoomTypesRequest';

export class BulkUpdateModifiersMultipleRoomTypesNetworkObject extends WebSocketNetworkObject<
  BulkUpdateModifiersMultipleRoomTypesRequest,
  BulkUpdateModifiersMultipleRoomTypesRequestPayload
> {
  constructor(params: BulkUpdateModifiersMultipleRoomTypesRequest) {
    super(params, WSMessageEvent.BulkUpdateModifiersMultipleRoomTypes);
  }

  override getParams(): BulkUpdateModifiersMultipleRoomTypesRequestPayload {
    const { roomTypeIds, dateRange, selectedDaysOfWeek, tweak } = this.params;

    return {
      roomTypeIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
      selectedDaysOfWeek,
      tweak,
    };
  }
}
