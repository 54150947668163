import { Ref } from 'nuxt/dist/app/compat/capi'
import { getButtonColorConfig } from '../config/buttonColorsConfig'
import { getButtonSizeConfig } from '../config/buttonSizesConfig'
import { ButtonSizes } from '../constants/buttonSizes'
import { ButtonTypes } from './../constants/buttonTypes'

export const useButtonConfig = (
    type: Ref<ButtonTypes>,
    size: Ref<ButtonSizes>,
    selected: Ref<Boolean>,
    disabled: Ref<Boolean>
) => {
    // color matcher
    const colors = computed((): string => {
        // search for matching config
        const buttonConfig = getButtonColorConfig(type.value as ButtonTypes)
        if (!buttonConfig) {
            return ''
        }

        // selected state
        if (selected.value) {
            return buttonConfig.selected
        }

        // disabled state
        if (disabled.value) {
            return buttonConfig.disabled
        }

        return buttonConfig.default
    })

    const ripple = computed((): string => {
        const buttonConfig = getButtonColorConfig(type.value as ButtonTypes)
        if (!buttonConfig) {
            return ''
        }

        return buttonConfig.ripple
    })

    // sizes matcher
    const sizes = computed((): string =>
        getButtonSizeConfig(size.value as ButtonSizes)
    )

    return { colors, sizes, ripple }
}
