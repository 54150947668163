import { Strategy } from '../common/Strategy';
import { StrategyPayload } from '../communication/common/StrategyPayload';
import {
  convertDateRangeToISODateRange,
  convertStringRangeToDateRange,
} from './dates';

export const convertStrategyCommonToPayload = (
  strategy: Strategy
): StrategyPayload => {
  const { accommodationId, baseStrategy, periods } = strategy;
  const payloadPeriods = periods.map((period) => ({
    settings: period.settings,
    dateRange: convertDateRangeToISODateRange(period.dateRange),
  }));

  return {
    accommodationId,
    baseStrategy,
    periods: payloadPeriods,
  };
};

export const convertStrategyPayloadToCommon = (
  strategy: StrategyPayload
): Strategy => {
  const { accommodationId, baseStrategy, periods } = strategy;
  const payloadPeriods = periods.map((period) => ({
    settings: period.settings,
    dateRange: convertStringRangeToDateRange(period.dateRange),
  }));

  return {
    accommodationId,
    baseStrategy,
    periods: payloadPeriods,
  };
};
