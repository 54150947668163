import { useWindowSize } from '@vueuse/core'
import {
    SIZE_TABLET_LANDSCAPE,
    SUPPORTED_MOBILE_DEVICES_REGEX_MATCHER,
} from '../constants/devices'

// using this hook as a singleton
let instance: any

const init = () => {
    const { width } = useWindowSize()
    const isMobile = computed(
        () =>
            process.client != undefined && width.value <= SIZE_TABLET_LANDSCAPE
    )

    // the following are not reactive, therefore no need to use ref. Both require a browser restart
    const isRealMobile = navigator.userAgent.match(
        SUPPORTED_MOBILE_DEVICES_REGEX_MATCHER
    )
    const browserName = navigator.appCodeName

    const instance = { isMobile, isRealMobile, browserName }
    return instance
}

export const useMobileInfos = () => instance || init()
