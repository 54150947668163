import { WSMessageEvent } from '../../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../../common/WebSocketNetworkObject';
import {
  GetAdvanedExpertUserParamsMappingRequestPayload,
  GetAdvanedExpertUserParamsMappingRequest,
} from './GetAdvancedExpertUserParamsMappingRequest';

export class GetAdvancedExpertUserParamsMappingNetworkObject extends WebSocketNetworkObject<
  GetAdvanedExpertUserParamsMappingRequest,
  GetAdvanedExpertUserParamsMappingRequestPayload
> {
  constructor(params: GetAdvanedExpertUserParamsMappingRequest) {
    super(params, WSMessageEvent.GetAdvancedExpertUserParamsMapping);
  }

  override getParams(): GetAdvanedExpertUserParamsMappingRequestPayload {
    const { accommodationId, strategy } = this.params;

    return {
      accommodationId: accommodationId,
      strategy: strategy,
    };
  }
}
