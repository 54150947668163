import { storeToRefs } from 'pinia'
import { CardTypes } from '../constants/cardTypes'
import { useAccommodationsStore } from '../store/accommodations'
import { useBannerStore } from '../store/banners'
import { useStrategiesStore } from '../store/strategies'
import {
	StrategyChartClickType,
	StrategyChartInteractiveTooltipArgsType,
	StrategyChartLineData,
	StrategyChartMarkAreaData,
} from '../types/Strategy'
import { ECharts } from 'echarts'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import { Currency } from '../submodules/sharedTypes/common/Currency'
import { PriceSeries } from '../submodules/sharedTypes/common/PriceSeries'
import { getISODate } from '~/submodules/sharedTypes/utils/dates'
import { utilDate } from '~/utils/utilDate'

export const handleDerivedStrategyBanner = () => {
	const { currentStrategy: customStrategy, isDerivedStrategy } = useStrategiesStore()
	const { getAccommodationById } = useAccommodationsStore()

	if (isDerivedStrategy) {
		const bannerStore = useBannerStore()
		const { parentStrategyAccommodationId } = getAccommodationById(customStrategy.accommodationId)!

		bannerStore.addBanner({
			canClose: true,
			title: TranslationKeys.DERIVED_STRATEGY,
			cardType: CardTypes.WARNING,
			message: TranslationKeys.DERIVED_STRATEGY_WARNING_BANNER_TEXT,
			messageReplacements: [
				getAccommodationById(parentStrategyAccommodationId)?.name ??
					TranslationKeys.DERIVED_STRATEGY_OTHER_ACCOMMODATION,
			],
			adjustCase: false,
		})
	}
}

export const getStrategySettingWithSymbol = (setting: number, scaleSetting = true) => {
	const settingToReturn = scaleSetting ? setting / 10 : setting

	return setting >= 0 ? `+${settingToReturn}` : `${settingToReturn}`
}

export const getStrategyChartSeries = (lineData: StrategyChartLineData) => {
	return {
		id: lineData.id,
		type: 'line',
		symbol: 'none',
		label: lineData.label,
		name: lineData.id,
		visible: lineData.visible ?? true,
		lineStyle: {
			width: 1.5,
			dashOffset: 8,
			lineDash: [3],
			color: lineData.colorConfig.serie.hex,
			type: lineData?.lineConfig?.type === 'dotted' ? [10, 5] : 'solid',
		},
		legendConfig: {
			classes: lineData.colorConfig.legend.classes,
		},
		tooltipConfig: {
			style: lineData.colorConfig.tooltip.classes,
		},
		data: lineData.data.map((dataPoint) => [dataPoint.time, dataPoint.value]),
	}
}

export const getStrategyChartMarkAreas = (markAreaData: StrategyChartMarkAreaData) => {
	return {
		type: 'line',
		name: markAreaData.id,
		visible: markAreaData.visible ?? true,
		markArea: {
			itemStyle: {
				color: markAreaData.colorConfig.markArea,
			},
			emphasis: {
				disabled: true,
			},
			data: [
				[
					{ xAxis: new Date(getISODate(markAreaData.data.from)) },
					{ xAxis: new Date(getISODate(markAreaData.data.to)) },
				],
			],
		},
	}
}

export const getStrategyChartLegend = (series: ReturnType<typeof getStrategyChartSeries>[]) => {
	const seriesLegend = new Map<string, { id: string; label: string; classes: string[] }>()
	series.forEach((serie) =>
		seriesLegend.set(serie.name, {
			id: serie.id,
			label: serie.label,
			classes: serie.legendConfig.classes,
		})
	)
	return Array.from(seriesLegend).flatMap(([_key, value]) => value)
}

export const getStrategyChartInformativeTooltip = (
	entry: any,
	seriesToShow: ReturnType<typeof getStrategyChartSeries>[],
	currency?: Currency
) => {
	const seriesToShowFormatted: {
		label: string
		value: number
		comparisonValue?: number
		color: string
		classes: string[]
	}[] = []
	const strategiesStore = useStrategiesStore()

	const { editableStrategy, strategyPeriodsFromShortest } = storeToRefs(strategiesStore)

	seriesToShow.forEach((serie) => {
		const entryIndex = entry.findIndex((e: any) => e.seriesName === serie.name)

		if (entryIndex === -1) return

		const entryDate = entry[entryIndex].data[0]

		const serieIndex = serie.data.findIndex((data) => data[0] === entryDate)
		const serieValue = serie.data[serieIndex][1] as number

		const classes = serie.tooltipConfig.style

		let comparisonValue: number | undefined
		if (serie.name === PriceSeries.SuggestedPricesPreview) {
			const currentStrategy = seriesToShow.find((serie) => serie.name === PriceSeries.SuggestedPrices)

			if (currentStrategy) {
				const currentStrategyIndex = currentStrategy.data.findIndex(
					(data) => data[0].toString() === entry[entryIndex].data[0].toString()
				)
				const currentStrategyValue =
					currentStrategyIndex > -1 ? (currentStrategy.data[currentStrategyIndex][1] as number) : undefined

				comparisonValue = currentStrategyValue
					? ((currentStrategyValue - serieValue) / currentStrategyValue) * -100
					: undefined
			}
		}

		seriesToShowFormatted.push({
			label: serie.label,
			value: serieValue,
			comparisonValue: comparisonValue,
			color: serie.lineStyle.color,
			classes: classes,
		})
	})

	const tooltip: string[] = [
		'<div class="flex flex-col w-60 gap-2">',
		`<p class="text-secondary-gray-800 text-sm font-semibold leading-5">${utilDate.formatShortLocale(
			entry[0].value[0],
			useLocale().currentLocale.value
		)}</p>`,
		'<div class="flex flex-col gap-1 w-full">',
	]

	seriesToShowFormatted.forEach((serie) => {
		const serieValue = serie.value != undefined ? Math.round(serie.value) : undefined
		const tooltipValue = utilCurrency.formatPrice(serieValue, currency ?? { code: 'EUR' })

		tooltip.push(
			`<div class="w-full flex items-center gap-2 px-2 py-1 rounded-md text-sm font-normal leading-5 ${cn(
				serie.classes
			)}" style="font-family: 'Inter', sans-serif;">
								<div class="w-[9px] h-[9px] rounded-sm flex-shrink-0 self-baseline" style="background: ${serie.color};"/></div>
								<p class="flex-1 overflow-hidden text-ellipsis self-baseline">${serie.label}</p>
                                <div class="flex flex-col gap-1 items-end">
                                    <p>${tooltipValue}</p>
                              `
		)

		const comparisonValue = serie.comparisonValue != undefined ? Math.round(serie.comparisonValue) : undefined
		if (comparisonValue != undefined) {
			comparisonValue > 0
				? tooltip.push(
						`<p class="text-xs font-normal leading-4 tracking-[0.32px] text-main-blue-600">+${comparisonValue}%</p>`
					)
				: tooltip.push(
						`<p class="text-xs font-normal leading-4 tracking-[0.32px] text-main-blue-600">${comparisonValue}%</p>`
					)
		}

		tooltip.push(`</div></div>`)
	})

	const currentlyHoveringPeriod = strategyPeriodsFromShortest.value.find(
		(period) =>
			(utilDate.isBefore(period.dateRange.from, entry[0].value[0]) ||
				utilDate.isSameDate(period.dateRange.from, entry[0].value[0])) &&
			(utilDate.isAfter(period.dateRange.to, entry[0].value[0]) ||
				utilDate.isSameDate(period.dateRange.to, entry[0].value[0]))
	)

	tooltip.push(
		'</div>',
		'<div class="h-[1px] w-full bg-secondary-gray-300"></div>',
		`<div class="flex flex-col gap-2 text-sm" style="font-family: 'Inter', sans-serif;">`,
		`<p class="text-sm text-secondary-gray-800">${
			currentlyHoveringPeriod
				? `${utilDate.formatShortestLocale(
						currentlyHoveringPeriod.dateRange.from,
						useLocale().currentLocale.value
					)} → ${utilDate.formatShortestLocale(currentlyHoveringPeriod.dateRange.to, useLocale().currentLocale.value)}`
				: useLocale().translate(TranslationKeys.STRATEGY_OVERVIEW_DEFAULT_STRATEGY)
		}</p>`,
		'<div class="flex flex-start gap-2">'
	)

	if (currentlyHoveringPeriod?.settings?.isAdvancedExpertUserEnabled) {
		tooltip.push(
			`<div class="inline-flex min-w-[40px] items-center justify-center rounded-md border px-2 py-px border-yellow-400 bg-yellow-100 text-yellow-900">
            ${useLocale().translate(TranslationKeys['strategy.chart_tooltip.ADVANCED'])}
            </div>`
		)
	} else if (!!currentlyHoveringPeriod) {
		tooltip.push(
			`<div class="flex w-20 text-sm text-main-blue-800 justify-center items-center px-2 py-[2px] rounded-md border border-main-blue-200 bg-main-blue-100">${
				currentlyHoveringPeriod
					? `${getStrategySettingWithSymbol(
							currentlyHoveringPeriod.settings.priceDecrease
						)} | ${getStrategySettingWithSymbol(currentlyHoveringPeriod.settings.priceIncrease)}`
					: `${getStrategySettingWithSymbol(
							editableStrategy.value.baseStrategy.priceDecrease
						)} | ${getStrategySettingWithSymbol(editableStrategy.value.baseStrategy.priceIncrease)}`
			}</div>`,
			`<div class="flex w-16 text-sm text-light-blue-900 justify-center items-center px-2 py-[2px] rounded-md border border-light-blue-200 bg-light-blue-100">${
				currentlyHoveringPeriod
					? getStrategySettingWithSymbol(currentlyHoveringPeriod.settings.averagePrice, false)
					: getStrategySettingWithSymbol(editableStrategy.value.baseStrategy.averagePrice, false)
			}%</div>`
		)
	} else if (editableStrategy.value.baseStrategy.isAdvancedExpertUserEnabled) {
		tooltip.push(
			`<div class="inline-flex min-w-[40px] items-center justify-center rounded-md border px-2 py-px border-yellow-400 bg-yellow-100 text-yellow-900">
            ${useLocale().translate(TranslationKeys['strategy.chart_tooltip.ADVANCED'])}
            </div>`
		)
	} else {
		tooltip.push(
			`<div class="flex w-20 text-sm text-main-blue-800 justify-center items-center px-2 py-[2px] rounded-md border border-main-blue-200 bg-main-blue-100">${
				currentlyHoveringPeriod
					? `${getStrategySettingWithSymbol(
							currentlyHoveringPeriod.settings.priceDecrease
						)} | ${getStrategySettingWithSymbol(currentlyHoveringPeriod.settings.priceIncrease)}`
					: `${getStrategySettingWithSymbol(
							editableStrategy.value.baseStrategy.priceDecrease
						)} | ${getStrategySettingWithSymbol(editableStrategy.value.baseStrategy.priceIncrease)}`
			}</div>`,
			`<div class="flex w-16 text-sm text-light-blue-900 justify-center items-center px-2 py-[2px] rounded-md border border-light-blue-200 bg-light-blue-100">${
				currentlyHoveringPeriod
					? getStrategySettingWithSymbol(currentlyHoveringPeriod.settings.averagePrice, false)
					: getStrategySettingWithSymbol(editableStrategy.value.baseStrategy.averagePrice, false)
			}%</div>`
		)
	}

	tooltip.push('</div></div></div>')

	return tooltip.join('')
}

export const getStrategyChartInteractiveTooltip = (
	chartInstance: ECharts,
	...args: StrategyChartInteractiveTooltipArgsType
) => {
	const [clickArea, event] = args

	let firstButtonText = ''
	let secondButtonText = ''

	let firstButtonEvent = ''
	let secondButtonEvent = ''

	if (clickArea === StrategyChartClickType.BLANK) {
		firstButtonText = useLocale().translate(TranslationKeys.STRATEGY_CHART_CLICK_TOOLTIP_EDIT_BASE_STRATEGY)
		secondButtonText = useLocale().translate(TranslationKeys.STRATEGY_CHART_CLICK_TOOLTIP_NEW_PERIOD)

		firstButtonEvent = 'edit-default-strategy'
		secondButtonEvent = 'add-custom-period'

		const dateInMousePosition = chartInstance.convertFromPixel('grid', [event.offsetX, event.offsetY])[0]

		return [
			`<div class="flex flex-col w-[178px] gap-1" style="font-family: 'Inter', sans-serif;">`,
			`<button onclick="window.dispatchEvent(new Event('${firstButtonEvent}'))" class="flex justify-center items-center px-4 h-9 bg-main-blue-100 rounded-md text-main-blue-600 text-sm leading-[14px] font-medium">${firstButtonText}</button>`,
			`<button onclick="window.dispatchEvent(new CustomEvent('${secondButtonEvent}', { detail: { newPeriodStartDate: ${dateInMousePosition} } }))" class="flex justify-center items-center px-4 h-9 bg-main-blue-100 rounded-md text-main-blue-600 text-sm leading-[14px] font-medium">${secondButtonText}</button>`,
			'</div>',
		].join('')
	} else if (clickArea === StrategyChartClickType.MARK_AREA) {
		firstButtonText = useLocale().translate(TranslationKeys.STRATEGY_CHART_CLICK_TOOLTIP_EDIT_PERIOD)
		firstButtonEvent = 'edit-custom-period'

		const fromMarkAreaDate = event.data.coord[0][0] as Date
		const toMarkAreaDate = event.data.coord[1][0] as Date

		return [
			`<div class="flex flex-col w-[178px] gap-1" style="font-family: 'Inter', sans-serif;">`,
			`<button onclick="window.dispatchEvent(new CustomEvent('${firstButtonEvent}', { detail: { editPeriodStartDate: ${fromMarkAreaDate.getTime()}, editPeriodEndDate: ${toMarkAreaDate.getTime()} } }))" class="flex justify-center items-center px-4 h-9 bg-main-blue-100 rounded-md text-main-blue-600 text-sm leading-[14px] font-medium">${firstButtonText}</button>`,
			'</div>',
		].join('')
	}
}
