import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import {
  GetAccommodationsPricesSentRequest,
  GetAccommodationsPricesSentRequestPayload,
} from './GetAccommodationsPricesSentRequest';

export class GetAccommodationsPricesSentNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsPricesSentRequestPayload,
  GetAccommodationsPricesSentRequest
> {
  constructor(params: GetAccommodationsPricesSentRequestPayload) {
    super(params, WSMessageEvent.GetAccommodationsPricesSent);
  }

  override getParams(): GetAccommodationsPricesSentRequest {
    const { accommodationIds, date } = this.params;
    return {
      accommodationIds,
      date: date ? date : new Date(),
    };
  }
}
