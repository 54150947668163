import { defineStore } from 'pinia'

type MobileActionsState = {
    actionHandler: any
}

export const useMobileActionsStore = defineStore('⚙️ mobile actions', {
    state: (): MobileActionsState => ({
        actionHandler: undefined,
    }),
    actions: {
        async setActionHandler(actionHandler: any) {
            this.actionHandler = actionHandler
        },
        removeActionHandler() {
            this.actionHandler = undefined
        },
    },
    getters: {
        hasActionHandler(): boolean {
            return this.actionHandler != undefined
        },
    },
})
