class UtilArray {
    removeAt<T>(arr: T[], index?: number) {
        if (index == undefined || index >= arr.length || index < 0) {
            return arr
        }
        const head = utilArray.stopAt(arr, index)
        const tail = utilArray.startingFrom(arr, index + 1)

        return head.concat(tail)
    }

    removeAtMany<T>(arr: T[], indexes: number[]) {
        const sortedIndexes = indexes.sort((a, b) => b - a)
        let result = arr
        sortedIndexes.forEach((index) => {
            result = utilArray.removeAt(result, index)
        })

        return result
    }

    addAt<T>(arr: T[], item: T, index: number) {
        return [...arr.slice(0, index), item, ...arr.slice(index)]
    }

    startingFrom<T>(arr: T[], index: number) {
        if (index >= arr.length) {
            return []
        }
        return arr.slice(index)
    }

    stopAt<T>(arr: T[], index: number) {
        if (index < 0) {
            return []
        }
        return arr.slice(0, index)
    }

    sortedSubsetInSortedArray<T>(sortedSubset: T[], sortedArray: T[]): boolean {
        if (sortedSubset.length == 0) {
            return true
        }
        if (sortedArray.length < sortedSubset.length) {
            return false
        }

        let index = 0
        for (let i = 0; i < sortedArray.length; i++) {
            if (sortedArray[i] === sortedSubset[index]) {
                index++
            }
            if (index >= sortedSubset.length) {
                break
            }
        }

        return index >= sortedSubset.length
    }

    mergeSortedArrays<T>(arr1: T[], arr2: T[]): T[] {
        if (arr1.length === 0 || arr2.length === 0) {
            return [...arr1, ...arr2]
        }

        const result = []
        let i = 0
        let j = 0
        while (i <= arr1.length && j <= arr2.length) {
            if (arr1[i] <= arr2[j]) {
                result.push(arr1[i])
                i++
                if (i >= arr1.length) {
                    result.push(...arr2.slice(j, arr2.length))
                    j = Number.MAX_VALUE
                }
            } else {
                result.push(arr2[j])
                j++
                if (j >= arr2.length) {
                    result.push(...arr1.slice(i, arr1.length))
                    i = Number.MAX_VALUE
                }
            }
        }

        return result
    }

    removeSortedSubsetFromSortedArray<T>(
        sortedSubset: T[],
        sortedArray: T[]
    ): T[] {
        if (sortedSubset.length === 0) {
            return sortedArray
        }
        const result: T[] = []

        let i = 0
        sortedArray.forEach((el) => {
            while (i < sortedSubset.length && sortedSubset[i] < el) {
                i++
            }
            if (i >= sortedSubset.length || el !== sortedSubset[i]) {
                result.push(el)
            } else {
                i++
            }
        })

        return result
    }

    anyMatchInSortedArrays<T>(arr1: T[], arr2: T[]) {
        if (arr1.length === 0 || arr2.length === 0) {
            return false
        }
        let i = 0
        let j = 0

        while (i < arr1.length && j < arr2.length) {
            if (i >= arr1.length || j >= arr2.length) {
                return false
            }

            if (arr1[i] === arr2[j]) {
                return true
            }

            if (arr1[i] > arr2[j]) {
                j++
            } else {
                i++
            }
        }
        return false
    }

    groupBy<T>(arr: T[], key: keyof T): Map<any, T[]> {
        const result = new Map<any, T[]>()

        arr.forEach((el) => {
            const newKey = el[key]
            if (!result.has(newKey)) {
                result.set(newKey, [])
            }

            result.get(newKey)!.push(el)
        })

        // sort the keys before returning
        return new Map<any, T[]>([...result.entries()].sort())
    }

    removeDuplicates<T>(arr: T[]) {
        const set = new Set<T>()
        arr.forEach((el) => set.add(el))

        return [...set]
    }
}

export const utilArray = new UtilArray()
