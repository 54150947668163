export const enum EmitsEnum {
	Hover = 'hover',
	Click = 'click',
	Add = 'add',
	Close = 'close',
	Save = 'save',
	Cancel = 'cancel',
	Change = 'change',
	Delete = 'delete',
	Undo = 'undo',
	Toggle = 'toggle',
	Select = 'select',
	Complete = 'complete',
	HintHover = 'hintHover',
	HintOut = 'hintOut',
	ErrorStatusChange = 'errorStatusChange',
	AccommodationIdChange = 'accommodationIdChange',
	FilterChange = 'filterChange',
	FocusOut = 'focusOut',
	ExpandHeader = 'expandHeader',
}
