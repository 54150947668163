import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  CreateEventRequest,
  CreateEventRequestPayload,
} from './CreateEventRequest';

export class CreateEventNetworkObject extends WebSocketNetworkObject<
  CreateEventRequest,
  CreateEventRequestPayload
> {
  constructor(params: CreateEventRequest) {
    super(params, WSMessageEvent.CreateEvent);
  }

  override getParams(): CreateEventRequestPayload {
    const {
      removeModifiers,
      event: { label, eventType, accommodationId },
      eventDetails: { repetitionType, impacts },
    } = this.params;

    return {
      removeModifiers,
      event: {
        label,
        eventType,
        accommodationId,
        eventDetails: {
          repetitionType,
          impacts: impacts.map((impact) => ({
            date: getISODate(impact.date),
            impact: impact.impact,
            isTail: impact.isTail,
          })),
        },
      },
    };
  }
}
