enum LogType {
	Debug = 'Debug',
	Tracking = 'Tracking',
	Socket = 'Socket',
	Rest = 'Rest',
}
type LogTypeKeys = keyof typeof LogType

export const log = (data: any, type: LogTypeKeys = 'Debug') => {
	const logStyle: { [key in LogType]: string[] } = {
		[LogType.Debug]: ['color: #fff', 'background-color: #5154db', 'padding: 2px 4px', 'border-radius: 2px'],
		[LogType.Tracking]: ['color: #fff', 'background-color: #444', 'padding: 2px 4px', 'border-radius: 2px'],
		[LogType.Socket]: ['color: #112A46', 'background-color: #10b7ef', 'padding: 2px 4px', 'border-radius: 2px'],
		[LogType.Rest]: ['color: #112A46', 'background-color: #F09A11', 'padding: 2px 4px', 'border-radius: 2px'],
	}

	const label = type
	const value = Array.isArray(data) ? data : [data]

	const logToFilter = useRuntimeConfig().public.FILTER_LOG?.split(',') ?? []
	if (!logToFilter.includes(type)) {
		console.log(`%c♻️ ${label}`, logStyle[type].join(';'), ...value)
	}
}
