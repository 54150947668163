import { BillingProfile, Invoice } from '@/submodules/sharedTypes/common/Billing'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { LoadingIds } from '~/constants/loadingIds'
import { GetBillingInfoNetworkObject } from '~/submodules/sharedTypes/communication/billing/GetBillingProfiles/GetBillingProfilesNetworkObject'
import { GetBillingProfilesResponse } from '~/submodules/sharedTypes/communication/billing/GetBillingProfiles/GetBillingProfilesResponse'
import { UpdateBillingInfoNetworkObject } from '~/submodules/sharedTypes/communication/billing/UpdateBillingInfo/UpdateBillingInfoNetworkObject'
import { UpdateBillingInfoResponse } from '~/submodules/sharedTypes/communication/billing/UpdateBillingInfo/UpdateBillingInfoResponse'
import { GetInvoicesNetworkObject } from '../submodules/sharedTypes/communication/billing/GetInvoices/GetInvoicesNetworkObject'
import { GetInvoicesResponse } from '../submodules/sharedTypes/communication/billing/GetInvoices/GetInvoicesResponse'
import { utilNetwork } from '../utils/UtilNetwork'
import { SubscriptionDefinition, utilBilling } from '../utils/utilBilling'
import { useLoadingStore } from './loading'

export const useBillingStore = defineStore('⚙️ billing', () => {
	// state
	const billingProfiles = ref([] as BillingProfile[])

	const selectedProfileIndex = ref(0 as number)

	const editModeEnabled = ref(false as boolean)

	const invoices = ref([] as Invoice[])

	//const filters = ref({} as InvoiceFilters)

	const isSingleProfile = computed(() => billingProfiles.value?.length === 1)

	const selectedProfile = computed(() => billingProfiles.value[selectedProfileIndex.value])

	const invoicesBySubscriptionID = computed(() => {
		const hashMap: { [key in string]: Invoice[] } = {}

		invoices.value.forEach((invoice) => {
			if (hashMap[invoice.subscriptionId] === undefined) {
				hashMap[invoice.subscriptionId] = []
			}
			hashMap[invoice.subscriptionId].push(invoice)
		})

		return hashMap
	})

	const selectedProfileParsedSubscriptions = computed(() => {
		return (
			selectedProfile.value?.subscriptions?.map((subscription) => {
				const invoices = invoicesBySubscriptionID.value[subscription.id]

				return utilBilling.subscriptionParser(subscription, invoices)
			}) || []
		)
	})

	const subscriptionsParsedByBillingProfile = computed(() => {
		const hashMap: { [key: string]: SubscriptionDefinition[] } = {}

		billingProfiles.value.forEach((profile) => {
			hashMap[profile.id] = []

			profile.subscriptions.forEach((subscription) => {
				const invoices = invoicesBySubscriptionID.value[subscription.id]

				hashMap[profile.id].push(utilBilling.subscriptionParser(subscription, invoices))
			})
		})

		return hashMap
	})

	const subscriptionsTotalByBillingProfile = computed(() => {
		return Object.fromEntries(
			Object.entries(subscriptionsParsedByBillingProfile.value).map(([profileID, subscriptions]) => {
				return [profileID, subscriptions.filter((sub) => sub.toShow).length]
			})
		)
	})

	const recentInvoices = computed(() => {
		return Object.values(subscriptionsParsedByBillingProfile.value)
			.flat(1)
			.map((sub) => sub.invoices)
			.flat(1)
			.filter((invoice) => !!invoice)
	})

	// actions
	async function selectProfile(id: string | undefined) {
		if (id === undefined) {
			selectedProfileIndex.value = 0
			return
		}

		const profileIndex = billingProfiles.value.findIndex((profile) => profile.id === id)
		selectedProfileIndex.value = profileIndex !== -1 ? profileIndex : 0
	}

	async function fetchBillingInfo() {
		useLoadingStore().addLoading(LoadingIds.GET_BILLING_PROFILES)
		await utilNetwork.simpleRequest(new GetBillingInfoNetworkObject({}), undefined, LoadingIds.GET_BILLING_PROFILES)
	}

	async function fetchInvoices() {
		await utilNetwork.simpleRequest(new GetInvoicesNetworkObject({}), undefined, LoadingIds.GET_INVOICES)
	}

	async function updateProfile(newProfile: BillingProfile) {
		await utilNetwork.simpleRequest(
			new UpdateBillingInfoNetworkObject({
				profileId: newProfile.id,
				info: newProfile.info,
			})
		)
	}

	async function setBillingProfiles(res: GetBillingProfilesResponse) {
		billingProfiles.value = res.profiles
		useLoadingStore().removeLoading(LoadingIds.GET_BILLING_PROFILES)
	}

	async function setBillingInfo(res: UpdateBillingInfoResponse) {
		const index = billingProfiles.value.findIndex((val) => val.id === res.profileId)

		if (index !== -1) {
			billingProfiles.value[index].info = res.info
		}
	}

	async function setInvoices(res: GetInvoicesResponse) {
		invoices.value = res.invoices
		useLoadingStore().removeLoading(LoadingIds.GET_INVOICES)
	}

	function anonimizeDeanonymize() {
		fetchBillingInfo()
		fetchInvoices()
	}

	function $reset() {
		billingProfiles.value = []
		selectedProfileIndex.value = 0
		editModeEnabled.value = false
		invoices.value = []
	}

	return {
		billingProfiles,
		isSingleProfile,
		selectedProfile,
		selectProfile,
		setBillingProfiles,
		setBillingInfo,
		editModeEnabled,
		invoicesBySubscriptionID,
		selectedProfileParsedSubscriptions,
		subscriptionsParsedByBillingProfile,
		subscriptionsTotalByBillingProfile,
		recentInvoices,
		setInvoices,
		invoices,
		fetchInvoices,
		updateProfile,
		$reset,
		fetchBillingInfo,
		anonimizeDeanonymize,
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useBillingStore, import.meta.hot))
}
