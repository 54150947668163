import { Granularity } from '../../common/Granularity';

export enum DateGranularity {
  WeekToDate = 'week_to_date',
  MonthToDate = 'month_to_date',
  YearToDate = 'year_to_date',
  Next7Days = 'next_7_days',
  Next30Days = 'next_30_days',
  Next60Days = 'next_60_days',
  Next90Days = 'next_90_days',
  CurrentYear = 'current_year',
  Last7Days = 'last_7_days',
  Last30Days = 'last_30_days',
  Last60Days = 'last_60_days',
  Last90Days = 'last_90_days',
  Last6Months = 'last_6_months',
  LastWinter = 'last_winter',
  LastSpring = 'last_spring',
  LastSummer = 'last_summer',
  LastFall = 'last_fall',
  SinceEver = 'since_ever',
  Ever = 'ever',
  SameDatesLastYear = 'same_dates_last_year',
  SameDates = 'same_dates',
}

export enum Emisphere {
  North = 'north',
  South = 'south',
}

export type StatsRequestPayload = {
  granularity: Granularity;
  bookingFromDate?: string;
  bookingToDate?: string;
  stayFromDate?: string;
  stayToDate?: string;
  stay?: DateGranularity;
  booking?: DateGranularity;
  emisphere?: Emisphere;
  masterFilter?: {
    granularity: Granularity;
    bookingFromDate?: string;
    bookingToDate?: string;
    stayFromDate?: string;
    stayToDate?: string;
    stay?: DateGranularity;
    booking?: DateGranularity;
    emisphere?: Emisphere;
  };
};

export type StatsRequest = {
  granularity: Granularity;
  bookingFromDate?: Date;
  bookingToDate?: Date;
  stayFromDate?: Date;
  stayToDate?: Date;
  stay?: DateGranularity;
  booking?: DateGranularity;
  emisphere?: Emisphere;
  masterFilter?: {
    granularity: Granularity;
    bookingFromDate?: Date;
    bookingToDate?: Date;
    stayFromDate?: Date;
    stayToDate?: Date;
    stay?: DateGranularity;
    booking?: DateGranularity;
    emisphere?: Emisphere;
  };
};
