import { INetworkObject } from './INetworkObject';
import { NetworkCommunicationType } from './NetworkCommunicationType';
import { RequestType } from './RequestTypes';

export class RestNetworkObject<T, K = T> implements INetworkObject<T, K> {
  readonly communicationType = NetworkCommunicationType.REST;
  readonly params: T;
  readonly connectionString: string;
  readonly requestType: RequestType;

  constructor(params: T, connectionString: string, requestType: RequestType) {
    this.params = params;
    this.connectionString = connectionString;
    this.requestType = requestType;
  }

  getConnectionString(): string {
    return this.connectionString;
  }
  getRequestType(): RequestType {
    return this.requestType;
  }
  getParams(): K {
    // TODO: compare types and throw must implement
    // @ts-ignore
    return this.params;
  }
}
