export const calendarConfig = {
	headerCellSize: 240,
	headerCellSizeMeasureUnit: 'px',
	dataCellMinSize: 90, // always in px
	weekendDays: [5, 6], // fri, sat
	lastDayOfWeek: 6,
	maxDays: 28, // 4 weeks

	mobileHeaderCellSize: 60,
	mobileDataCellMinSize: 60,
	mobileDataCellMaxSize: 67,

	maxCellsPerRow: 28,
	minCellsPerRow: 3,
	minScreenWidth: 288,

	headerWidth: 89,
	maxExpandedHeaderWidth: '400px',
	expandedHeaderWidth: '85%',
	mobileHeaderHeight: '52px',

	accommodationCellMaxLettersDesktop: 42,
	roomTypeCellMaxLettersDesktop: 42,
}
