<script lang="ts" setup>
import { TranslationKey } from '~/i18n/TranslationKeys';
import { devToolsConfig } from '~~/src/config/DevToolsConfig';
import { ButtonSizes } from '~~/src/constants/buttonSizes';
import { ButtonTypes } from '~~/src/constants/buttonTypes';
import { devModeCheats } from '~~/src/devMode/cheats';
import { cheatsHelper } from '~~/src/devMode/cheatsHelper';

const visible = ref(false)
window.addEventListener('keydown', (ev) => {
    if (ev.key === devToolsConfig.cheatsToggleButton) {
        visible.value = !visible.value
    }
})

const cheats = devModeCheats


</script>

<template>
    <div v-if="cheatsHelper.canShowCheats()"
        class="bg-white p-3 gap-2 fixed bottom-0 w-full border bt-1 border border-black"
        :class="visible ? 'flex' : 'hidden'" style="z-index: 99999999;">
        <CommonButton v-for="cheat in cheats" :text="cheat.cheatName" @click="cheat.cheat" :size="ButtonSizes.TEXT_M"
            :type="ButtonTypes.PRIMARY">
            <CommonText :text="(cheat.cheatName as TranslationKey)" />
        </CommonButton>
    </div>
</template>