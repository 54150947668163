import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  GetEventDetailsRequest,
  GetEventDetailsRequestPayload,
} from './GetEventDetailsRequest';

export class GetEventDetailsNetworkObject extends WebSocketNetworkObject<
  GetEventDetailsRequest,
  GetEventDetailsRequestPayload
> {
  constructor(params: GetEventDetailsRequest) {
    super(params, WSMessageEvent.GetEventDetails);
  }

  override getParams(): GetEventDetailsRequestPayload {
    const { accommodationId, eventDate, eventId, eventType } = this.params;

    return {
      accommodationId,
      eventId,
      eventType,
      eventDate: getISODate(eventDate),
    };
  }
}
