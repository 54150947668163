import { DayOfTheWeek } from '@dev.smartpricing/sp-shared-network-freemium-types'
import { Seasonality } from '@/submodules/sharedTypes/common/Strategy'

export const DEFAULT_STRATEGY_ADVANCED_PARAMS = {
	startingPrices: 0,
	drop: {
		[Seasonality.High]: 10,
		[Seasonality.Low]: 10,
	},
	pickup: {
		[Seasonality.High]: 10,
		[Seasonality.Low]: 10,
	},
	eventsAndHolidaysImpact: 10,
	dayOfTheWeekMap: {
		[Seasonality.High]: {
			[DayOfTheWeek.Monday]: 0,
			[DayOfTheWeek.Tuesday]: 0,
			[DayOfTheWeek.Wednesday]: 0,
			[DayOfTheWeek.Thursday]: 0,
			[DayOfTheWeek.Friday]: 0,
			[DayOfTheWeek.Saturday]: 0,
			[DayOfTheWeek.Sunday]: 0,
		},
		[Seasonality.Low]: {
			[DayOfTheWeek.Monday]: 0,
			[DayOfTheWeek.Tuesday]: 0,
			[DayOfTheWeek.Wednesday]: 0,
			[DayOfTheWeek.Thursday]: 0,
			[DayOfTheWeek.Friday]: 0,
			[DayOfTheWeek.Saturday]: 0,
			[DayOfTheWeek.Sunday]: 0,
		},
	},
	marketDemandEnabled: false,
	marketDemand: 0,
} as const
