import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetRoomTypesPricesDetailsRequest,
  GetRoomTypesPricesDetailsRequestPayload,
} from './GetRoomTypesPricesDetailsRequest';

export class GetRoomTypesPricesDetailsNetworkObject extends WebSocketNetworkObject<
  GetRoomTypesPricesDetailsRequest,
  GetRoomTypesPricesDetailsRequestPayload
> {
  constructor(params: GetRoomTypesPricesDetailsRequest) {
    super(params, WSMessageEvent.GetRoomTypesPricesDetails);
  }

  override getParams(): GetRoomTypesPricesDetailsRequestPayload {
    const { dateRange, roomTypeIds } = this.params;
    return {
      dateRange: convertDateRangeToISODateRange(dateRange),
      roomTypeIds,
    };
  }
}
