import { defineStore } from 'pinia'
import { IMessageCardData } from '../types/IMessageCardData'
import { IMessageCardDataWithId } from './../types/IMessageCardData'

type BannerState = {
    banners: Array<IMessageCardData | IMessageCardDataWithId>
}

export const useBannerStore = defineStore('🚀 banner store', {
    state: (): BannerState => ({
        banners: [],
    }),
    actions: {
        addBanner(banner: IMessageCardData): void {
            this.banners = [banner]
        },
        addUniqueBanner(banner: IMessageCardDataWithId, replace: boolean) {
            const index = this.banners.findIndex(
                // @ts-ignore
                (el) => el.id == banner.id
            )

            if (index < 0) {
                this.banners.push(banner)
                return
            }

            if (replace) {
                this.banners[index] = banner
            }
        },
        closeTopBanner(): void {
            this.banners.pop()
        },
        closeBannerAtIndex(index: number) {
            this.banners.splice(index, 1)
        },
    },
})
