import { SIZE_TABLET_LANDSCAPE } from './../constants/screenSizes'
export const isMobile = (): boolean => {
    return (
        process.client != undefined &&
        window.innerWidth <= SIZE_TABLET_LANDSCAPE
    )

    //  if we decide to use the user agent to determine if the user is on a mobile device, we can use the following:
    // return isMobileDevice()
}

export const isMobileDevice = () => {
    const mobileKeywords = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|Tablet/i;
    return mobileKeywords.test(navigator.userAgent);
}