import { defineStore } from 'pinia'
import { UserPreferences } from '../submodules/sharedTypes/common/UserPreferences'
import { GetUserPreferencesResponse } from '../submodules/sharedTypes/communication/user-preferences/GetUserPreferences/GetUserPreferencesResponse'
import { GetUserPreferencesNetworkObject } from '../submodules/sharedTypes/communication/user-preferences/GetUserPreferences/GetUserPreferencesNetworkObject'
import { SetUserPreferencesNetworkObject } from '../submodules/sharedTypes/communication/user-preferences/SetUserPreferences/SetUserPreferencesNetworkObject'

export const useUserPreferencesStore = defineStore('⚙️ user preferences', () => {
	const preferences = ref<Partial<UserPreferences>>({})

	const getUserPreferences = () => {
		utilNetwork.simpleRequest(new GetUserPreferencesNetworkObject({}))
	}
	const setUserPreferences = (preferences: Partial<UserPreferences>) => {
		utilNetwork.simpleRequest(new SetUserPreferencesNetworkObject({ preferences }))
	}

	const updateUserPreferences = (res: GetUserPreferencesResponse) => {
		preferences.value = res.preferences
	}

	const userPreferences = computed(() => preferences.value)
	const userPreferencesStrategyPage = computed(() => preferences.value.strategyPage)
	const userPreferencesAccommodationSettingsPage = computed(() => preferences.value.accommodationSettingsPage)

	const $reset = () => {}

	return {
		preferences,

		getUserPreferences,
		setUserPreferences,

		updateUserPreferences,

		userPreferences,
		userPreferencesStrategyPage,
		userPreferencesAccommodationSettingsPage,

		$reset,
	}
})
