import type { WebSocketEventHandlerMap } from '@dev.smartpricing/sp-shared-network-client/dist/core/Client'
import { WSEventServer } from '@dev.smartpricing/sp-shared-network-common'
import type {
	GetCompetitorsResponse,
	GetMarketPriceTrendResponse,
	GetSummaryResponse,
} from '@dev.smartpricing/sp-shared-network-freemium-types'
import { SalesModesConfig } from '~/config/SalesModesConfig'
import { SalesModes } from '~/constants/salesMode'
import { StorageKeys } from '~/constants/storageKeys'
import { useCompetitorsStore } from '~/store/competitors'
import { AccommodationGHT } from '~/types/Competitor'

export const WebSocketEventHandlersGHT: WebSocketEventHandlerMap = new Map([
	[
		WSEventServer.Summary,
		{
			handler: async (res: GetSummaryResponse) => {
				const competitorsStore = useCompetitorsStore()

				// Set current accommodation
				competitorsStore.setCurrentAccommodationFromResponse(res.accommodations?.[0] as AccommodationGHT)

				// Set competitors
				competitorsStore.setCompetitorsFromResponse(res.competitors as GetCompetitorsResponse)

				// Set chart data
				competitorsStore.setChartDataFromResponse(res)

				if (UtilSales.isSalesMode.value) {
					const selectedMode = localStorage.getItem(StorageKeys.SalesMode)! as SalesModes
					const config = SalesModesConfig[selectedMode]

					competitorsStore.anonymizeDeanonymizeCurrentAccommodation('anon', config)
				}

				competitorsStore.setLoading(false)
			},
		},
	],
	[
		WSEventServer.Error,
		{
			handler: async (res: any) => {
				log(['Error!', res], 'Socket')
			},
		},
	],
])
