export const enum StrategyTrackingTypes {
    Base = 'Base',
    Period = 'Period',
}

export const enum StrategyActionTypes {
    Preview = 'Preview',
    Confirm = 'Confirm',
    Reset = 'Reset',
}
