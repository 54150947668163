<script setup lang="ts">
import { PropType, toRefs } from 'vue'
import { ButtonSizes } from '~/constants/buttonSizes'
import { ButtonTypes } from '~/constants/buttonTypes'
import { EmitsEnum } from '~/constants/emits'
import { useButtonConfig } from '~~/src/hooks/useButtonConfig'
import { useRipple } from '~~/src/hooks/useRipple'

const props = defineProps({
	size: { type: String as PropType<ButtonSizes>, required: true },
	type: { type: String as PropType<ButtonTypes>, default: '' },
	onclick: Function as PropType<() => void>,
	selected: { type: Boolean, default: false },
	disabled: Boolean,
	rippleColorOverride: { type: String, required: false },
})
const { size, type, onclick, selected, disabled, rippleColorOverride } = toRefs(props)
const target = ref()

const rippleColor = computed(() => rippleColorOverride?.value || ripple.value)
const { triggerRipple } = useRipple(target, rippleColor)

// click handler
const emit = defineEmits([EmitsEnum.Click])
const handleClick = (event: MouseEvent) => {
	if (!disabled.value) {
		triggerRipple(event)
	}

	if (onclick?.value) {
		onclick.value()
	}
	emit(EmitsEnum.Click)
}

const { colors, sizes, ripple } = useButtonConfig(type, size, selected, disabled)
</script>

<template>
	<button
		:class="`${colors} ${sizes} truncate flex justify-center items-center rounded-md relative overflow-hidden`"
		@click.stop="handleClick"
		:selected="selected"
		:disabled="disabled"
		ref="target"
	>
		<slot></slot>
	</button>
</template>
