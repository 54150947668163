import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationsOccupanciesRequest,
  GetAccommodationsOccupanciesRequestPayload,
} from './GetAccommodationsOccupanciesRequest';

export class GetAccommodationsOccupanciesNetworkObject extends WebSocketNetworkObject<
  GetAccommodationsOccupanciesRequest,
  GetAccommodationsOccupanciesRequestPayload
> {
  constructor(params: GetAccommodationsOccupanciesRequest) {
    super(params, WSMessageEvent.GetAccommodationsOccupancies);
  }

  override getParams(): GetAccommodationsOccupanciesRequestPayload {
    const { accommodationIds, dateRange } = this.params;
    return {
      accommodationIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
