import { toRaw } from 'vue'

class UtilObject {
	public getFilteredObject = (object: any, elementsToFilter: string[]) => {
		const filteredObject: any = {}
		for (const [key, value] of Object.entries(object)) {
			if (!elementsToFilter.includes(key)) {
				filteredObject[key] = value
			}
		}
		return filteredObject
	}

	public objectToMap<T = string, K = any>(object: any): Map<T, K> {
		const resultingMap = new Map<T, K>()
		Object.keys(object).forEach((key) => resultingMap.set(key as T, object[key]))

		return resultingMap
	}

	public resetObject(obj: Object) {
		// @ts-ignore
		Array.from(Object.keys(obj)).forEach((key) => delete obj[key])
	}

	public assignAll(obj: Object, objToAssign: Object) {
		Array.from(Object.keys(objToAssign)).forEach(
			// @ts-ignore
			(key) => (obj[key] = objToAssign[key])
		)
	}

	public flattenObject = (obj: any): object => {
		const flatObject: any = {}

		function flatten(obj: any, prefix: string = '') {
			for (const key in obj) {
				if (obj.hasOwnProperty(key)) {
					const prefixedKey = prefix ? `${prefix}.${key}` : key

					if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
						flatten(obj[key], prefixedKey)
					} else {
						flatObject[prefixedKey] = obj[key]
					}
				}
			}
		}

		flatten(obj)
		return flatObject
	}

	public arrayToObject = <T extends { [key: string]: any }>(array: T[], key: string, value: string) => {
		const mappedObject: { [key: string]: string } = {}
		array.forEach((item) => {
			mappedObject[item[key]] = item[value] || ''
		})

		return mappedObject
	}

	public getIndex<T extends { [key: string]: any }>(object: T, key: string) {
		const keys = Object.keys(object)

		return keys.findIndex((k) => k === key)
	}

	public accessByIndex<T extends { [key: string]: any }>(object: T, index: number) {
		const keys = Object.keys(object)
		const actualKey = keys[index - 1]
		return object[actualKey]
	}
}

export const utilObject = new UtilObject()

/* export const toRawDeep = <T>(observed: T): T => {
    const val = toRaw(observed);

    if (Array.isArray(val)) {
        return val.map(toRawDeep) as T;
    }

    if (val === null) return null as T;

    if (typeof val === 'object') {
        const entries = Object.entries(val).map(([key, val]) => [key, toRawDeep(val)]);

        return Object.fromEntries(entries);
    }

    return val;
}

export const cloneDeep = <T>(obj: T): T => {
	return structuredClone(obj)
} */
