export const SIZE_TABLET_LANDSCAPE = 1024
export const SUPPORTED_MOBILE_DEVICES = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
]
// from array to regex: /(Android)|(webOS)|.../i
export const SUPPORTED_MOBILE_DEVICES_REGEX_MATCHER =
    '/' + SUPPORTED_MOBILE_DEVICES.map((el) => `(${el})`).join('|') + '/i'
