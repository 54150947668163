import { ButtonTypes } from '../constants/buttonTypes'

export interface IButtonTypeStatus {
    default: string
    selected: string
    disabled: string
    ripple: string
}

export const buttonColorsConfig: { [k in ButtonTypes]: IButtonTypeStatus } = {
    [ButtonTypes.PRIMARY]: {
        default: `bg-main-blue-600 hover:bg-main-blue-700 focus-active:bg-main-blue-700 active:bg-main-blue-800 disabled:bg-dark-blue-200
        text-white active:text-light-blue-300 disabled:text-dark-blue-400
        innersvg:fill-white innersvg:active:fill-light-blue-300 innersvg:disabled:fill-dark-blue-300
        focus-active:border-1 focus-active:border-solid focus-active:border-main-blue-700
        border border-1 border-transparent
        focus-active:shadow-white-circle`,
        selected: `bg-main-blue-700
        text-white
        border-1 border-solid border-main-blue-700
        shadow-white-circle`,
        disabled: `bg-dark-blue-200
        border border-1 border-transparent
        text-dark-blue-400
        fill-dark-blue-400`,
        ripple: 'bg-main-blue-300',
    },
    [ButtonTypes.SECONDARY]: {
        default: `bg-dark-blue-600 hover:bg-dark-blue-700 active:dark-blue-800 disabled:bg-dark-blue-200
        text-white active:text-light-blue-300 disabled:text-dark-blue-400
        innersvg:fill-white innersvg:active:fill-light-blue-300 innersvg:disabled:fill-dark-blue-400
        focus-active:shadow-white-circle
        border border-1 border-transparent`,
        selected: `bg-dark-blue-600
        innersvg:fill-white
        shadow-white-circle
        border border-1 border-transparent`,
        disabled: `bg-dark-blue-200
        text-dark-blue-400
        innersvg:fill-dark-blue-400
        border border-1 border-transparent`,
        ripple: 'bg-dark-blue-400',
    },
    [ButtonTypes.TERTIARY]: {
        default: `hover:bg-main-blue-400 active:bg-main-blue-600
        text-main-blue-600 hover:text-white active:text-light-blue-300 disabled:text-dark-blue-400
        innersvg:fill-main-blue-600 innersvg:hover:fill-white innersvg:active:fill-light-blue-300 innersvg:disabled:fill-dark-blue-400
        focus-active:border-1 focus-active:border-double focus-active:border-main-blue-600 disabled:border-1 disabled:border-solid disabled:border-dark-blue-200
        border border-1 border-solid border-main-blue-600`,
        selected: `
        text-main-blue-600
        innersvg:fill-main-blue-600
        border border-1 border-double border-main-blue-600`,
        disabled: `
        text-dark-blue-400
        innersvg:fill-dark-blue-400
        border border-1 border-solid border-dark-blue-200`,
        ripple: 'bg-main-blue-300',
    },
    [ButtonTypes.DANGER]: {
        default: `bg-red-400 hover:bg-red-300 active:bg-red-600 disabled:bg-dark-blue-200
        text-red-900 active:text-white disabled:text-dark-blue-400
        innersvg:fill-red-900 innersvg:active:fill-white innersvg:disabled:fill-dark-blue-400
        border border-1 border-transparent
        focus-active: shadow-white-circle`,
        selected: `bg-red-600
        text-white
        border border-1 border-transparent
        innersvg:fill-white`,
        disabled: `bg-dark-blue-200
        text-dark-blue-400
        border border-1 border-transparent
        innersvg:fill-dark-blue-400`,
        ripple: 'bg-red-200',
    },
    [ButtonTypes.GHOST]: {
        default: `hover:bg-dark-blue-300/40 active:bg-dark-blue-300/40 
        active:text-main-blue-600 disabled:text-dark-blue-300
        border border-1 border-transparent focus-active:border-main-blue-600
        innersvg:focus-active:fill-main-blue-600 innersvg:disabled:fill-dark-blue-300`,
        selected: `bg-dark-blue-300/40
        text-main-blue-600
        border border-1 border-transparent
        innersvg:fill-main-blue-600`,
        disabled: `text-dark-blue-300
        innersvg:fill-dark-blue-300
        border border-1 border-transparent`,
        ripple: 'bg-dark-blue-200',
    },
}

export const getButtonColorConfig = (key: ButtonTypes) => {
    return buttonColorsConfig[key] as IButtonTypeStatus
}
