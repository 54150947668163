import { GetSalesToolTokenNetworkObject } from '@/submodules/sharedTypes/communication/auth/sales-tool-token/GetSalesToolTokenNetworkObject'
import { GetSalesToolTokenResponse } from '@/submodules/sharedTypes/communication/auth/sales-tool-token/GetSalesToolTokenResponse'
import { SearchCompetitorsNetworkObject } from '@dev.smartpricing/sp-shared-network-freemium-types'
import { SearchAccommodationsResponse } from '@dev.smartpricing/sp-shared-network-freemium-types'
import { networkClient } from '@dev.smartpricing/sp-shared-network-client'
import { WebSocketEventHandlersGHT } from '~/networkListeners/socketListenersMapGHT'
import { AxiosResponse, ResponseType } from 'axios'
import { INetworkObject } from '@dev.smartpricing/sp-shared-network-common'

class UtilGHT {
	private accommodationTokensMap: Map<string, string> = new Map()

	init() {
		const { setBaseUrl } = networkClient()
		setBaseUrl({
			rest: useRuntimeConfig().public.SALES_TOOL_API_BASE_URL,
			socket: useRuntimeConfig().public.SALES_TOOL_API_BASE_URL,
		})
	}

	async setSalesToolToken(accommodationId: string) {
		// sets the token to the network client for the selected accommodation
		if (this.accommodationTokensMap.get(accommodationId)) {
			const ghtToken = this.accommodationTokensMap.get(accommodationId)
			networkClient().closeSocketCommunication()
			networkClient().setAuthToken(ghtToken!)

			networkClient().openWebSocketCommunication(
				useRuntimeConfig().public.SALES_TOOL_API_BASE_URL,
				WebSocketEventHandlersGHT
			)
			return
		}

		await utilNetwork.simpleRequest(
			new GetSalesToolTokenNetworkObject({ accommodationId: accommodationId as unknown as string }),
			(response: GetSalesToolTokenResponse) => {
				this.accommodationTokensMap.set(accommodationId, response.salesToolToken)
				networkClient().closeSocketCommunication()
				networkClient().setAuthToken(response.salesToolToken)

				networkClient().openWebSocketCommunication(
					useRuntimeConfig().public.SALES_TOOL_API_BASE_URL,
					WebSocketEventHandlersGHT
				)
			}
		)
		return
	}

	async searchCompetitor(name: string) {
		const response: AxiosResponse<SearchAccommodationsResponse> = await networkClient().simpleRequest(
			new SearchCompetitorsNetworkObject({ name: name })
		)

		return response
	}

	async simplRequest(requestObject: INetworkObject<unknown, unknown>, responseType?: ResponseType | undefined) {
		return await networkClient().simpleRequest(requestObject, responseType)
	}
}

export const utilGHT = new UtilGHT()
