class UtilHubspot {
	email?: string

	identify(email: string) {
		this.email = email

		// @ts-ignore
		if (window._hsq == undefined) {
			return
		}

		// @ts-ignore
		window._hsq.push(['identify', { email }])
	}

	update(data: any) {
		// @ts-ignore
		if (window._hsq == undefined) {
			return
		}

		// @ts-ignore
		window._hsq.push([
			'identify',
			{
				email: this.email,
				...data,
			},
		])
	}
}

export const utilHubspot = new UtilHubspot()
