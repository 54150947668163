<script setup lang="ts">
</script>

<template>
    <div class="p-4 h-dvh">
        <div class="bg-main-blue-50 w-full h-full rounded-[40px] overflow-hidden relative">
            <TransitionGroup name="bubbles">
                <div class="bg-ball-1 absolute h-[1000px] w-[1000px] right-[-350px] top-[-350px] rounded-full" key="ball-1"></div>
                <div class="bg-ball-3 absolute h-[700px] w-[700px] right-[-350px] top-[-350px] rounded-full " key="ball-3"></div>

                
                <div class="bg-ball-4 absolute h-[1000px] w-[1000px] left-[-450px] bottom-[-550px] rounded-full" key="ball-4"></div>
                <div class="bg-ball-2 absolute h-[600px] w-[600px] left-[-300px] bottom-[-350px] rounded-full" key="ball-2"></div>
            </TransitionGroup>
			<div class="w-full h-full absolute">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.bg-ball-1 {
	background: radial-gradient(50% 50% at 50% 50%, #73dbe0 0%, rgba(115, 219, 224, 0) 100%);
	animation: oblique 4s ease-in-out infinite alternate;
}

.bg-ball-2 {
	background: radial-gradient(50% 50% at 50% 50%, #73dbe0 0%, rgba(124, 124, 215, 0) 100%);
	animation: oblique 4s ease-in-out infinite alternate;
}

.bg-ball-3 {
	background: radial-gradient(50% 50% at 50% 50%, #7C7CD7 0%, rgba(124, 124, 215, 0) 100%);
	animation: oblique 4s ease-in-out infinite alternate;
}

.bg-ball-4 {
	background: radial-gradient(50% 50% at 50% 50%, #7c7cd7 0%, rgba(115, 219, 224, 0) 100%);
	animation: oblique 4s ease-in-out infinite alternate;
}


.bubbles-enter-active,
.bubbles-leave-active {
	transition: opacity 0.5s ease;
}

.bubbles-enter-from,
.bubbles-leave-to {
	opacity: 0;
}

@keyframes oblique {
    0% {
		transform: translate(0%, 0%);
	}

	100% {
		transform: translate(10%, -10%);
	}
}
</style>