import { TranslationKeys } from '~/i18n/TranslationKeys'
import { useAccommodationsStore } from './../store/accommodations'
export interface CheatHandler {
	cheatName: string
	cheat: () => void
}

export const devModeCheats: CheatHandler[] = [
	{
		cheatName: 'Anonimizza strutture',
		cheat: () => {
			const adjectives = [' superlativa', ' fantastica', ' incredibile', ' generica', ' vera']
			const accommodationStore = useAccommodationsStore()
			accommodationStore.accommodations.forEach((el, index) => {
				if (adjectives.length == 0) {
					el.name = useLocale().translate(TranslationKeys.ACCOMMODATIONS) + ' ' + index
				} else {
					el.name = useLocale().translate(TranslationKeys.ACCOMMODATIONS) + adjectives.pop()
				}
			})
		},
	},
	{
		cheatName: 'Anonimizza camere',
		cheat: () => {
			const accommodationStore = useAccommodationsStore()

			accommodationStore.accommodations.forEach((accommodation, accommodationIndex) => {
				accommodation.roomTypes?.forEach((room, roomIndex) => {
					const name = useLocale().translate(TranslationKeys.ROOM_TYPE) + ` ${accommodationIndex + 1}_${roomIndex + 1}`

					room.name = name
					accommodationStore.roomTypes.map((el) => {
						if (el.id === room.id) {
							el.name = name
						}
						return el
					})
				})
			})
		},
	},
]
