import { Component } from '@vue/runtime-dom'
import { Ref } from 'nuxt/dist/app/compat/vue-demi'
import { defineStore } from 'pinia'
import { IdModal } from '../constants/IdModal'
import { Localizable } from '../types/Localizable'
import { isMobile } from '../utils/mobile'
import { utilArray } from './../utils/utilArray'
import { TranslationKeys } from '~/i18n/TranslationKeys'

export interface MobileModalRequirements {
	allowClose: boolean
	title: Localizable
	component: Component | undefined
	props: Ref<any>
}

type ModalsState = {
	modals: IdModal[]
	mobile: MobileModalRequirements
	makeAWishBanner: boolean
}

export const useModalStore = defineStore('🚀 modal store', {
	state: (): ModalsState => ({
		modals: [],
		mobile: {
			allowClose: true,
			component: undefined,
			title: {
				key: TranslationKeys.MODIFIERS,
			},
			props: ref({}),
		},
		makeAWishBanner: false,
	}),
	actions: {
		addModal(modal: Exclude<IdModal, IdModal.Mobile>): void {
			const modalWhitelist = [IdModal.PasswordForgotten, IdModal.PasswordRecoverySent, IdModal.UserFeedback]
			if (!modalWhitelist.includes(modal) && isMobile()) {
				throw new Error(
					'You are attempting to open a desktop modal while on mobile mode.\nPlease use addMobileModal or a mobile sheet instead.'
				)
				return
			}
			this.modals.push(modal)
		},
		addUniqueModal(modal: Exclude<IdModal, IdModal.Mobile>): void {
			if (isMobile()) {
				throw new Error(
					'You are attempting to open a desktop modal while on mobile mode.\nPlease use addMobileModal or a mobile sheet instead.'
				)
				return
			}

			if (this.modals.some((el) => el === modal)) {
				return
			}

			this.modals.push(modal)
		},
		addMobileModal(requirements: MobileModalRequirements) {
			if (!isMobile()) {
				throw new Error(
					'You are attempting to open a mobile modal while on desktop mode.\nPlease use addModal or addUniqueModal instead.'
				)
			}

			this.modals.push(IdModal.Mobile)
			requirements = {
				...requirements,
				component: shallowRef(requirements.component),
			}
			this.mobile = requirements
		},
		closeTopModal(): void {
			this.modals.pop()
		},
		closeModal(modal: IdModal): void {
			const length = this.modals.length - 1
			const index = this.modals
				.slice()
				.reverse()
				.findIndex((el) => el === modal)
			if (index == -1) {
				return
			}

			this.modals = utilArray.removeAt(this.modals, length - index)
		},
		closeAllModals(): void {
			this.modals = []
		},
	},
})
