import { TranslationKeys } from '~/i18n/TranslationKeys'

export type OnboardingStepOptionItem = {
	id: string
	text: string
	selected?: boolean
	nextStepIndex?: number
	stepsIndexToClearSelection?: number[]
}

export type OnboardingStepTip = {
	title: string
	content: string
}

export type OnboardingStep = {
	id: string
	title: string
	description: string
	otherDescription?: string
	isDisabled?: boolean
	// If a number, it's the index used to specify the answer from the
	// step.options array (when step.isDisabled === true), otherwise the answer
	// will be null.
	defaultAnswer?: number | null
	tips: OnboardingStepTip[]
	options: OnboardingStepOptionItem[]
}

export const getSteps = (answers: (number | null)[] = []): OnboardingStep[] => {
	const intro: OnboardingStep = {
		id: 'step-intro',
		title: TranslationKeys.ONBOARDING_PROFILE_INTRO_TITLE,
		description: TranslationKeys.ONBOARDING_PROFILE_INTRO_DESCRIPTION,
		tips: [],
		options: [],
	}

	const steps: OnboardingStep[] = [
		{
			id: 'step-1',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_DESCRIPTION,
			otherDescription: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_OTHER_DESCRIPTION,
			// NOTE: temporarily disabled this step until R&D finds a way to generate
			// starting prices based on the customer's currently published prices.
			// See `https://smartpricing-it.atlassian.net/browse/PRD-1392`
			isDisabled: true,
			// This means that, while disabled, this step's answer is going
			// to be options[0].
			defaultAnswer: 0,
			tips: [
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_DYNAMIC_PRICING_CONTENT,
				},
			],
			options: [
				{
					id: 'step-1-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_A,
					nextStepIndex: 4,
					stepsIndexToClearSelection: [2, 3],
				},
				{
					id: 'step-1-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_1_ANSWER_B,
					stepsIndexToClearSelection: [4],
				},
			],
		},
		{
			id: 'step-2a',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2A_DESCRIPTION,
			isDisabled: true,
			defaultAnswer: 0,
			tips: [
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_CONTENT,
				},
			],
			options: [
				{
					id: 'step-2a-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_A,
					stepsIndexToClearSelection: [3],
				},
				{
					id: 'step-2a-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_B,
					nextStepIndex: 5,
				},
			],
		},
		{
			id: 'step-2b',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_DESCRIPTION,
			isDisabled: true,
			// This means that, while disabled, this step's answer is going
			// to be null.
			defaultAnswer: null,
			tips: [
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_CONTENT,
				},
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_CONTENT,
				},
			],
			options: [
				{
					id: 'step-2b-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_A,
					nextStepIndex: 5,
				},
				{
					id: 'step-2b-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2B_ANSWER_B,
					nextStepIndex: 5,
				},
			],
		},
		{
			id: 'step-2',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_DESCRIPTION,
			tips: [
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_STARTING_PRICE_CONTENT,
				},
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_HISTORICAL_PRICES_CONTENT,
				},
			],
			options: [
				{
					id: 'step-2-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_A,
				},
				{
					id: 'step-2-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_2_ANSWER_B,
				},
			],
		},
		{
			id: 'step-3',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-3-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_A,
				},
				{
					id: 'step-3-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_B,
				},
				{
					id: 'step-3-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_C,
				},
				{
					id: 'step-3-d',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_D,
				},
				{
					id: 'step-3-e',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_E,
				},
				{
					id: 'step-3-f',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_3_ANSWER_F,
				},
			],
		},
		{
			id: 'step-4',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-4-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_A,
				},
				{
					id: 'step-4-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_B,
				},
				{
					id: 'step-4-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_C,
				},
				{
					id: 'step-4-d',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_4_ANSWER_D,
				},
			],
		},
		{
			id: 'step-5',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-5-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_A,
				},
				{
					id: 'step-5-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_B,
				},
				{
					id: 'step-5-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_C,
				},
				{
					id: 'step-5-d',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_5_ANSWER_D,
				},
			],
		},
		{
			id: 'step-6',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-6-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_A,
				},
				{
					id: 'step-6-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_B,
				},
				{
					id: 'step-6-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_C,
				},
				{
					id: 'step-6-d',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_6_ANSWER_D,
				},
			],
		},
		{
			id: 'step-7',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-7-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_A,
				},
				{
					id: 'step-7-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_B,
				},
				{
					id: 'step-7-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_7_ANSWER_C,
				},
			],
		},
		{
			id: 'step-8',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_DESCRIPTION,
			tips: [
				{
					title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_TITLE,
					content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_ROOM_TYPES_HIERARCHY_CONTENT,
				},
			],
			options: [
				{
					id: 'step-8-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_A,
				},
				{
					id: 'step-8-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_B,
				},
				{
					id: 'step-8-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_8_ANSWER_C,
				},
			],
		},
		{
			id: 'step-9',
			title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_TITLE,
			description: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_DESCRIPTION,
			tips: [],
			options: [
				{
					id: 'step-9-a',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_A,
				},
				{
					id: 'step-9-b',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_B,
				},
				{
					id: 'step-9-c',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_C,
				},
				{
					id: 'step-9-d',
					text: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_STEP_9_ANSWER_D,
				},
			],
		},
	]

	return [
		intro,
		...steps.map((step, stepIndex) => {
			const answer = answers[stepIndex]
			step.options.forEach((option, optionIndex) => (option.selected = answer === optionIndex))
			return step
		}),
	]
}

export const getProfileTips = (): OnboardingStepTip[] => [
	{
		title: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_TITLE,
		content: TranslationKeys.ONBOARDING_PROFILE_QUESTIONNAIRE_TIP_PROFILE_CONTENT,
	},
]
