import { defineStore } from 'pinia'
import { LoadingIds } from '../constants/loadingIds'

type LoadingStoreState = {
    loading: Set<LoadingIds>
    loadingResolvers: Map<LoadingIds, (value: unknown) => void>
    loadingPromises: Map<LoadingIds, Promise<unknown>>
}

export const useLoadingStore = defineStore('⚙️ loading', {
    state: (): LoadingStoreState => ({
        loading: new Set<LoadingIds>(),
        loadingResolvers: new Map<LoadingIds, () => void>(),
        loadingPromises: new Map<LoadingIds, Promise<unknown>>(),
    }),
    actions: {
        addLoading(loadingId: LoadingIds) {
            this.loading.add(loadingId)
        },
        removeLoading(loadingId: LoadingIds) {
            this.loading.delete(loadingId)
            if (this.loadingPromises.has(loadingId)) {
                // first resolve the pending promise
                const resolve = this.loadingResolvers.get(loadingId)!
                resolve(undefined)

                // then remove it
                this.loadingPromises.delete(loadingId)
                this.loadingResolvers.delete(loadingId)
            }
        },
    },
    getters: {
        isLoading() {
            return (id: LoadingIds) => this.loading.has(id)
        },
        getPromiseDependency() {
            return (id: LoadingIds) => {
                if (!this.loading.has(id)) {
                    return
                }

                if (this.loadingPromises.has(id)) {
                    return this.loadingPromises.get(id)!
                }

                const promise = new Promise((resolve) => {
                    this.loadingResolvers.set(id, resolve)
                })
                this.loadingPromises.set(id, promise)

                return promise
            }
        },
    },
})
