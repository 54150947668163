import { addDays } from 'date-fns'

export const basePricesConfiguration = {
	defaultBasePrice: 100,

	firstSelectableDate: new Date(),
	lastSelectableDate: addDays(new Date(), 500),

	minimumDaysInRange: 7,
	minimumDaysToFillWithPrices: 501,
}
