import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  GetAccommodationBasePricesPredictionPreviewRequest,
  GetAccommodationBasePricesPredictionPreviewRequestPayload,
} from './GetAccommodationBasePricesPredictionPreviewRequest';

export class GetAccommodationBasePricesPredictionPreviewNetworkObject extends WebSocketNetworkObject<
  GetAccommodationBasePricesPredictionPreviewRequest,
  GetAccommodationBasePricesPredictionPreviewRequestPayload
> {
  constructor(params: GetAccommodationBasePricesPredictionPreviewRequest) {
    super(params, WSMessageEvent.GetAccommodationBasePricesPredictionPreview);
  }

  override getParams(): GetAccommodationBasePricesPredictionPreviewRequestPayload {
    const { accommodationId, previewType, roomTypes } = this.params;

    return {
      accommodationId,
      previewType,
      roomTypes: roomTypes.map((roomType) => ({
        id: roomType.id,
        basePrices: roomType.basePrices.map((basePrice) => ({
          ...basePrice,
          dateRange: convertDateRangeToISODateRange(basePrice.dateRange),
        })),
      })),
    };
  }
}
