export default defineNuxtPlugin(() => {})
/* import { createPinia } from 'pinia'
import { utilTracking } from '../utils/utilTracking'

export default defineNuxtPlugin(async ({ vueApp }) => {
    // We need to start tracking from here, otherwise we can't be sure tracking is initialized before pinia.
    // TODO: find a way to force initialize order
    

    const pinia = createPinia()

    vueApp.use(pinia)
})
 */
