import userflow from 'userflow.js'
import { useFeedbackStore } from '../store/feedback'
import { useUserStore } from '../store/user'
import { useMobileInfos } from './useMobileInfos'

export const useMobile = (showOnMobile = true) => {
	const { userflowInitialized } = useUserStore()
	const { isMobile } = useMobileInfos()
	let currentPageLayout: 'default' | 'mobile' = 'default'

	watch(() => isMobile.value, changeMode, { immediate: true })
	watch(() => userflowInitialized, setUserflowUserDeviceType)

	async function changeMode(toMobile: boolean) {
		const feedbackStore = useFeedbackStore()
		feedbackStore.init()

		setUserflowUserDeviceType()

		const pageLayoutChange = toMobile ? 'mobile' : 'default'

		if (currentPageLayout !== pageLayoutChange) {
			currentPageLayout = pageLayoutChange
			setPageLayout(currentPageLayout)
		}

		if (toMobile) {
			if (!showOnMobile) {
				await navigateTo('/calendar')
			}
		}
	}

	function setUserflowUserDeviceType() {
		if (userflowInitialized) {
			userflow.updateUser({ device_type: isMobile.value ? 'mobile' : 'desktop' })
		}
	}
}

export const useMobileViewport = () => {
	const { orientation } = useScreenOrientation()
	const { width } = useWindowSize()

	const isMobile = computed(() => width.value <= 1024)
	const isLandscape = computed(() => orientation.value?.includes('landscape'))

	return {
		isMobile,
		isLandscape,
	}
}
