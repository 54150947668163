import { defineStore } from 'pinia'
import { PerformanceMailComparisonType, UserSettings } from '../submodules/sharedTypes/common/UserSettings'
import { GetUserSettingsNetworkObject } from './../submodules/sharedTypes/communication/user-settings/getUserSettings/GetUserSettingsNetworkObject'
import { GetUserSettingsResponse } from './../submodules/sharedTypes/communication/user-settings/getUserSettings/GetUserSettingsResponse'
import { UpdateUserSettingsNetworkObject } from './../submodules/sharedTypes/communication/user-settings/updateUserSettings/UpdateUserSettingsNetworkObject'
import { utilNetwork } from './../utils/UtilNetwork'
import { utilTracking } from '../utils/utilTracking'
import { UserProfileTrackingProperties } from '../submodules/sharedTypes/tracking/UserProfileTrackingProperties'

type UserState = {
	ready: boolean
	userSettings: UserSettings
}

export const useUserSettingsStore = defineStore('⚙️ user settings', {
	state: (): UserState => ({
		ready: false,
		userSettings: {} as UserSettings,
	}),
	actions: {
		setUserSettings(userSettings: GetUserSettingsResponse) {
			this.userSettings = userSettings
			this.ready = true
			utilTracking.setUserProperty(UserProfileTrackingProperties.MarketOccupancyStatus, userSettings.showMarketDemand)
		},
		requestUserSettings() {
			utilNetwork.simpleRequest(new GetUserSettingsNetworkObject({}))
		},
		setEmailNotificationState(isEnabled: boolean, comparisonType: PerformanceMailComparisonType) {
			this.userSettings.isPerformanceMailDisabled = !isEnabled
			utilNetwork.simpleRequest(
				new UpdateUserSettingsNetworkObject({
					isPerformanceMailDisabled: !isEnabled,
					performanceMailComparisonType: comparisonType,
				})
			)
		},
		setShowMarketDemand(showDemand: boolean) {
			this.userSettings.showMarketDemand = showDemand
			utilTracking.setUserProperty(UserProfileTrackingProperties.MarketOccupancyStatus, showDemand)
			utilNetwork.simpleRequest(
				new UpdateUserSettingsNetworkObject({
					showMarketDemand: showDemand,
				})
			)
		},
	},
	getters: {
		getMailNotificationsEnabled(): boolean {
			return this.userSettings?.isPerformanceMailDisabled == undefined || !this.userSettings.isPerformanceMailDisabled
		},
		getNotificationComparisonType(): PerformanceMailComparisonType {
			return this.userSettings?.performanceMailComparisonType
		},
		getShowMarketDemand(): Boolean {
			return true
			return this.userSettings.showMarketDemand
		},
	},
})
