import { NumericVariationType } from './NumericVariationType';
import { Range } from './Range';

export enum BasePricePredictionPreviewType {
  Regular = 'regular',
  Temporary = 'temporary',
}

export type BasePrice = {
  dateRange: Range<Date>;
  price: number;
};

export type BasePriceVariation = {
  variationType: NumericVariationType;
  variationValue: number;
  targetRoomTypeId: number;
};
