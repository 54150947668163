/**
 * Please use this and don't trust app.i18n.lang during redirects
 * @param route the analyzed route
 * @returns a 2 chars composed string containing the language
 */
export const getRouteLocale = (route: string, i18n: any) => {
    // check if route is structured as /**/ where the two * are the lang codes
    if (
        route.charAt(0) === '/' &&
        (route.length == 3 || route.charAt(3) === '/')
    ) {
        return route.charAt(1)! + route.charAt(2)!
    }

    return getLanguageNotInUrl(i18n)
}

export const getLanguageNotInUrl = (i18n: any) => i18n.defaultLocale
