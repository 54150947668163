<script setup lang="ts">
import { TranslationKeys } from '~/i18n/TranslationKeys';
import { InvoiceDefinition } from '../../utils/utilBilling';


const props = defineProps({
    invoice: { type: Object as PropType<InvoiceDefinition>, default: {} }
})

const { invoice } = toRefs(props)
</script>

<template>
    <div class="flex flex-col gap-2 p-1">
        <div class="flex gap-1">
            <SpText :text="`${useLocale().translate(TranslationKeys.ACCOMMODATIONS)}:`" :size="SpTextSize.BodyMd01" />
            <div class="flex flex-col gap-1">
                <SpText v-for="accommodation in invoice.accommodations" :text="accommodation.name" :size="SpTextSize.BodyMd03" :key="accommodation.name" />
            </div>
        </div>
    </div>
</template>