import mixpanel from 'mixpanel-browser'
import { TrackingMessages } from '../constants/trackingMessages'
import { SharedTrackingProperties } from '../submodules/sharedTypes/tracking/SharedTrackingProperties'
import { UserProfileTrackingProperties } from '../submodules/sharedTypes/tracking/UserProfileTrackingProperties'
import { IS_DEV_MODE, IS_STAGING } from './../constants/index'

class UtilTracking {
	private lastId = ''
	private initialized = false
	private isAccounting = false

	public init(id: string, proxy?: string) {
		this.initialized = true

		if (proxy == undefined) {
			mixpanel.init(id, {
				debug: IS_STAGING() || IS_DEV_MODE(),
				ignore_dnt: true,
			})
			return
		}

		mixpanel.init(id, { api_host: proxy, ignore_dnt: true })
		this.setSharedProperty(SharedTrackingProperties.TrackingOrigin, 'Frontend')
		this.setSharedProperty(SharedTrackingProperties.IsOldTrackingOrigin, false)
	}

	public setSharedProperty(id: SharedTrackingProperties, value: any) {
		mixpanel.register({
			[id]: value,
		})
	}

	public setUserProperty(id: UserProfileTrackingProperties, value: any) {
		mixpanel.people.set(id, value)
	}

	public identify(id: string, isAccountingSession: boolean) {
		this.isAccounting = isAccountingSession

		if (this.isAccounting) {
			log('Init Mixpanel, prevented!', 'Tracking')
			mixpanel.reset()

			// erase all mixpanel cookies to prevent errors with socket.io connection (max header size)
			// const cookies = document.cookie.split(';')
			// cookies.forEach((cookie) => {
			// 	const cookieName = cookie.split('=')[0].trim()

			// 	if (cookieName.includes('mixpanel')) {
			// 		document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.smartpricing.it`
			// 	}
			// })
			return
		}

		if (!this.initialized) {
			throw new Error("MixPanel wasn't initialized")
		}

		if (this.lastId != id) {
			this.lastId = id
			mixpanel.identify(id)
		}
	}

	public track(message: TrackingMessages | string, details: any) {
		if (IS_DEV_MODE() || this.isAccounting) {
			log(['Tracking prevented. Data: ', message, details], 'Tracking')
			return
		}

		if (!this.initialized) {
			throw new Error("MixPanel wasn't initialized")
		}

		mixpanel.track(message, {
			...details,
			[SharedTrackingProperties.TrackingOrigin]: 'client',
		})

		if (this.lastId == '') {
			if (IS_DEV_MODE()) {
				log('Mixpanel tracking prevented. Missing user id', 'Tracking')
			}
			return
		}
	}
}

export const utilTracking = new UtilTracking()
