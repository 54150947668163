import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  RemoveEventRequest,
  RemoveEventRequestPayload,
} from './RemoveEventRequest';

export class RemoveEventNetworkObject extends WebSocketNetworkObject<
  RemoveEventRequest,
  RemoveEventRequestPayload
> {
  constructor(params: RemoveEventRequest) {
    super(params, WSMessageEvent.RemoveEvent);
  }

  override getParams(): RemoveEventRequestPayload {
    const { eventDate, eventId, eventType, accommodationId } = this.params;

    return {
      eventId,
      eventType,
      eventDate: getISODate(eventDate),
      accommodationId,
    };
  }
}
