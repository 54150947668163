import { PaymentStatusPerBundle } from './../submodules/sharedTypes/common/SubscriptionsData'

class UtilHotjar {
	id?: number
	email?: string
	billingStatus?: PaymentStatusPerBundle

	identify(id: number, email: string, billingStatus: PaymentStatusPerBundle | undefined) {
		// @ts-ignore
		if (window.hj == undefined) {
			return
		}

		this.id = id
		this.email = email
		this.billingStatus = billingStatus

		// @ts-ignore
		window.hj('identify', this.id, {
			email,
			billingStatus,
			lang: useLocale().currentLocale.value,
		})
	}

	update(data: any) {
		// @ts-ignore
		if (window.hj == undefined || !this.id) {
			return
		}

		// @ts-ignore
		window.hj('identify', this.id, {
			email: this.email,
			billingStatus: this.billingStatus,
			lang: useLocale().currentLocale.value,
			...data,
		})
	}
}

export const utilHotjar = new UtilHotjar()
