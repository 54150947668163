import { defineStore } from 'pinia'
import { Accommodation } from '@dev.smartpricing/sp-shared-network-freemium-types'
import {
	AutoOnboardingAdditionalInfo,
	AutoOnboardingAvailablePartnerProperties,
	AutoOnboardingSteps,
} from '../types/AutoOnboarding'
import { StorageKeys } from '../constants/storageKeys'
import {
	OnboardingStepStatus,
	Partner,
	PartnerAccesInfoParam,
	RoomTypeMapping,
	SelfOnboardingStep,
} from '../submodules/sharedTypes/common/SelfOnboarding'
import { GetAvailablePartnersResponse } from '../submodules/sharedTypes/communication/partners/GetAvailablePartners/GetAvailablePartnersResponse'
import { GetPartnerAccessInfoSchemaResponse } from '../submodules/sharedTypes/communication/partners/GetPartnerAccessInfoSchema/GetPartnerAccessInfoSchemaResponse'
import { useLoadingStore } from './loading'
import { LoadingIds } from '../constants/loadingIds'
import { SetSelfOnboardingProgressNetworkObject } from '../submodules/sharedTypes/communication/onboarding/self/SetSelfOnboardingProgress/SetSelfOnboardingProgressNetworkObject'
import { SetSelfOnboardingProgressRequest } from '../submodules/sharedTypes/communication/onboarding/self/SetSelfOnboardingProgress/SetSelfOnboardingProgressRequest'
import {
	SetSelfOnboardingProgressGetPartnerIdResponseData,
	SetSelfOnboardingProgressResponse,
} from '../submodules/sharedTypes/communication/onboarding/self/SetSelfOnboardingProgress/SetSelfOnboardingProgressResponse'
import { SpInputSelectItem } from '@dev.smartpricing/sp-vue-components'
import { OnboardingTip } from '#build/components'
//import { SetSelfOnboardingProgressStepNetworkObject } from '../submodules/sharedTypes/communication/onboarding/self/SetSelfOnboardingProgressStep/SetSelfOnboardingProgressStepNetworkObject'
//import { SetSelfOnboardingProgressStepRequest } from '../submodules/sharedTypes/communication/onboarding/self/SetSelfOnboardingProgressStep/SetSelfOnboardingProgressStepRequest'

export const useAutoOnboardingStore = defineStore('auto-onboarding', () => {
	// Accommodation info
	const accommodation = ref<Accommodation>()
	const updateAccommodation = (data: Accommodation) => {
		accommodation.value = data
	}
	const additionalInfo = ref<AutoOnboardingAdditionalInfo>()
	const updateAdditionalInfo = (data: AutoOnboardingAdditionalInfo) => {
		additionalInfo.value = data
	}

	const roomSummaryCompleted = ref<boolean>(false)
	const genericAccommodationData = ref<{
		name?: string
		pmsName?: string
		channelManagerName?: string
	}>({})

	const roomTypeMapping = ref<RoomTypeMapping[]>([])
	const roomTypeMappingAdded = ref<RoomTypeMapping[]>([])

	const addRoomTypeMapping = (roomTypeID: string) => {
		// adds a room type mapping from the left to the right
		const index = roomTypeMapping.value.findIndex((roomType) => roomType.id === roomTypeID)

		if (index !== -1) {
			const removedRoomType = roomTypeMapping.value.splice(index, 1)[0]
			roomTypeMappingAdded.value.push(removedRoomType)
		}
	}

	const removeRoomTypeMapping = (roomTypeID: string) => {
		// removes a room type mapping from the right to the left
		const index = roomTypeMappingAdded.value.findIndex((roomType) => roomType.id === roomTypeID)

		if (index !== -1) {
			const removedRoomType = roomTypeMappingAdded.value.splice(index, 1)[0]
			roomTypeMapping.value.push(removedRoomType)

			// remove all the connection to virtual parent rooms
			roomTypeMappingAdded.value.forEach((roomTypeV) => {
				if (roomTypeV.isVirtual && roomTypeV.virtualRoomSettings.parentRoomTypeId === roomTypeID) {
					roomTypeV.virtualRoomSettings.parentRoomTypeId = ''
				}
			})
		}
	}

	const allRoomTypeMappings = computed(() => [...roomTypeMapping.value, ...roomTypeMappingAdded.value])

	const parentRoomsAdded = computed(() => {
		return roomTypeMappingAdded.value
			.filter((roomType) => roomType.isVirtual)
			.map((roomType) => {
				// @ts-ignore
				return roomType.virtualRoomSettings.parentRoomTypeId
			})
	})

	const roomTypeOptions = computed<SpInputSelectItem[]>(() => [
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STANDARD_DOUBLE_ROOM),
			value: 'standard_double_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STANDARD_TWIN_ROOM),
			value: 'standard_twin_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STANDARD_SINGLE_ROOM),
			value: 'standard_single_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STANDARD_TRIPLE_ROOM),
			value: 'standard_triple_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STANDARD_QUADRUPLE_ROOM),
			value: 'standard_quadruple_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DELUXE_DOUBLE_ROOM),
			value: 'deluxe_double_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DELUXE_TWIN_ROOM),
			value: 'deluxe_twin_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DELUXE_SINGLE_ROOM),
			value: 'deluxe_single_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DELUXE_TRIPLE_ROOM),
			value: 'deluxe_triple_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DELUXE_QUADRUPLE_ROOM),
			value: 'deluxe_quadruple_room',
		},
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_FAMILY_ROOM), value: 'family_room' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUITE), value: 'suite' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_JUNIOR_SUITE), value: 'junior_suite' },
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_EXECUTIVE_SUITE),
			value: 'executive_suite',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_ROOM),
			value: 'superior_double_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TWIN_ROOM),
			value: 'superior_twin_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_SINGLE_ROOM),
			value: 'superior_single_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_TRIPLE_ROOM),
			value: 'superior_triple_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_QUADRUPLE_ROOM),
			value: 'superior_quadruple_room',
		},
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_KING_ROOM), value: 'king_room' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_QUEEN_ROOM), value: 'queen_room' },
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_DOUBLE_OR_TWIN_ROOM),
			value: 'double_or_twin_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_SUPERIOR_DOUBLE_OR_TWIN_ROOM),
			value: 'superior_double_or_twin_room',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_TWIN_ROOM_WITH_EXTRA_BED),
			value: 'twin_room_with_extra_bed',
		},
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_TWO_BEDROOM_SUITE),
			value: 'two_bedroom_suite',
		},
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_STUDIO), value: 'studio' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_APARTMENT), value: 'apartment' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_LOFT), value: 'loft' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_BUNGALOW), value: 'bungalow' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_VILLA), value: 'villa' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_CHALET), value: 'chalet' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_COTTAGE), value: 'cottage' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_CABIN), value: 'cabin' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_MOBILE_HOME), value: 'mobile_home' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_TENT), value: 'tent' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_YURT), value: 'yurt' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_HOUSEBOAT), value: 'houseboat' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_TREEHOUSE), value: 'treehouse' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_ROOM_TYPE_IGLOO), value: 'igloo' },
	])

	const boardTypeOptions = computed<SpInputSelectItem[]>(() => [
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_BOARD_TYPE_ROOM_ONLY), value: 'room_only' },
		{
			label: useLocale().translate(TranslationKeys.AUTOONBOARDING_BOARD_TYPE_BED_AND_BREAKFAST),
			value: 'bed_and_breakfast',
		},
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_BOARD_TYPE_HALF_BOARD), value: 'half_board' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_BOARD_TYPE_FULL_BOARD), value: 'full_board' },
		{ label: useLocale().translate(TranslationKeys.AUTOONBOARDING_BOARD_TYPE_ALL_INCLUSIVE), value: 'all_inclusive' },
	])

	// Partners
	const selectedPartner = ref<Partner>()
	const setSelectedPartner = (id: string) => {
		const partner = availablePartners.value.find((partner) => partner.id === id)
		if (partner) {
			selectedPartner.value = partner
		}
	}
	const partnerSchema = ref<PartnerAccesInfoParam[]>()
	const setPartnerSchema = (response: GetPartnerAccessInfoSchemaResponse) => {
		partnerSchema.value = response.schema
		useLoadingStore().removeLoading(LoadingIds.AUTO_ONBOARDING_PARTNER_SCHEMA)
	}
	const availablePartners = ref<Partner[]>([])
	const setAvailablePartners = (response: GetAvailablePartnersResponse) => {
		availablePartners.value = response.partners
	}
	const formCredentials = ref<Record<string, string | number>>({})
	const setFormCredentials = (key: string, value: string | number) => {
		formCredentials.value[key] = value
	}
	const availablePropertiesOnPartner = ref<AutoOnboardingAvailablePartnerProperties[] | 'manual'>()
	const setAvailablePropertiesOnPartner = (partners: AutoOnboardingAvailablePartnerProperties[]) => {
		availablePropertiesOnPartner.value = partners
	}
	const partnerId = ref<'manual' | { id: string; name: string }[]>()
	const selectedProperty = ref<string>()

	const basePriceGenerationSource = ref<'historical' | 'average' | undefined>(undefined)
	const basePriceGenerationStepData = ref<{
		hasBasePriceMapping: boolean
		hasBasePricesGenerated: boolean
		basePriceMap: {
			id: string
			name: string
			value: number
		}[]
	}>({
		hasBasePriceMapping: false,
		hasBasePricesGenerated: false,
		basePriceMap: [],
	})

	// Progress(server-side)
	const currentStep = ref<SelfOnboardingStep | null>(null)
	const isAccommodationCreated = ref(false)
	const isPMSFailedConnection = ref(false)
	const isPMSSuccesedConnection = ref(false)
	const isDownloadReservationsFailed = ref(false)
	const isdDownloadReservationsSuccesed = ref(false)
	const updateProgress = (progressRequestObject: SetSelfOnboardingProgressRequest) => {
		utilNetwork.simpleRequest(new SetSelfOnboardingProgressNetworkObject(progressRequestObject))
	}

	const updateProgressStep = (data: any /* SetSelfOnboardingProgressStepRequest */) => {
		//utilNetwork.simpleRequest(new SetSelfOnboardingProgressStepNetworkObject(data as SetSelfOnboardingProgressStepRequest))
	}

	const setProgressFromResponse = (response: SetSelfOnboardingProgressResponse) => {
		if (response.accommodation) {
			genericAccommodationData.value = response.accommodation
		}

		currentStep.value = response.step || undefined
		if (response.step === SelfOnboardingStep.PartnerOauthURL && useUserStore().isAccountingUser) {
			window.open(response.data.partnerOauthURL, '_about')
		}
		if (response.step === SelfOnboardingStep.AccommodationCreated) {
			isAccommodationCreated.value = true
		}

		// PMS connection
		if (response.data?.status === OnboardingStepStatus.Error) {
			isPMSFailedConnection.value = true
			isPMSSuccesedConnection.value = false
		}

		if (response.step === SelfOnboardingStep.PartnerIdSelection) {
			updateProgress({ step: SelfOnboardingStep.GetPartnerId, data: {} })
		}
		if (response.step === SelfOnboardingStep.GetPartnerId) {
			const isManual = response.data.requiresManualInput
			if (isManual) {
				partnerId.value = 'manual'
			} else {
				setAvailablePropertiesOnPartner(response.data.partnerAccommodations)
				partnerId.value = response.data.partnerAccommodations
			}
		}
		if (
			response.step === SelfOnboardingStep.PartnerConnection &&
			response.data.status === OnboardingStepStatus.Pending
		) {
			isPMSFailedConnection.value = false
			isPMSSuccesedConnection.value = false
		}
		if (response.step === SelfOnboardingStep.PartnerConnection && response.data.status === OnboardingStepStatus.Error) {
			isPMSFailedConnection.value = true
			isPMSSuccesedConnection.value = false
		}
		if (
			response.step === SelfOnboardingStep.PartnerConnection &&
			response.data.status === OnboardingStepStatus.Success
		) {
			isPMSSuccesedConnection.value = true
			isPMSFailedConnection.value = false
		}

		if (response.step === SelfOnboardingStep.GetRoomTypesMapping) {
			roomTypeMapping.value = response.data.roomTypes
		}

		// Download reservations
		if (
			response.step === SelfOnboardingStep.DownloadReservations &&
			response.data.status === OnboardingStepStatus.Pending
		) {
			isDownloadReservationsFailed.value = false
			isdDownloadReservationsSuccesed.value = false
		}
		if (
			response.step === SelfOnboardingStep.DownloadReservations &&
			response.data.status === OnboardingStepStatus.Error
		) {
			isDownloadReservationsFailed.value = true
			isdDownloadReservationsSuccesed.value = false
		}
		if (
			response.step === SelfOnboardingStep.DownloadReservations &&
			response.data.status === OnboardingStepStatus.Success
		) {
			isdDownloadReservationsSuccesed.value = true
			isDownloadReservationsFailed.value = false
		}

		if (response.step === SelfOnboardingStep.RoomTypesMapped) {
			roomSummaryCompleted.value = true
		}

		if (response.step === SelfOnboardingStep.GenerateBasePrices) {
			basePriceGenerationStepData.value = response.data
		}

		useLoadingStore().removeLoading(LoadingIds.AUTO_ONBOARDING_GET_PROGRESS)
	}

	// Progress(client-side)
	const saveProgress = (currentStep: AutoOnboardingSteps) => {
		localStorage.setItem(
			StorageKeys.AutoOnboardingProgress,
			JSON.stringify({ currentStep: currentStep, accommodation: accommodation.value })
		)
	}
	const resumeProgress = () => {
		const progress = localStorage.getItem(StorageKeys.AutoOnboardingProgress)
		if (progress) {
			const { accommodation } = JSON.parse(progress)
			updateAccommodation(accommodation)
		}
	}

	const $reset = () => {
		accommodation.value = undefined
		additionalInfo.value = undefined
		selectedPartner.value = undefined
		partnerSchema.value = undefined
		availablePartners.value = []
	}

	return {
		accommodation,
		updateAccommodation,
		additionalInfo,
		updateAdditionalInfo,

		selectedPartner,
		setSelectedPartner,
		partnerSchema,
		setPartnerSchema,
		availablePartners,
		setAvailablePartners,
		formCredentials,
		setFormCredentials,
		partnerId,
		selectedProperty,
		availablePropertiesOnPartner,
		setAvailablePropertiesOnPartner,

		currentStep,
		isAccommodationCreated,
		isPMSFailedConnection,
		isPMSSuccesedConnection,
		isDownloadReservationsFailed,
		isdDownloadReservationsSuccesed,
		updateProgress,
		updateProgressStep,
		setProgressFromResponse,
		roomTypeMappingAdded,

		basePriceGenerationSource,
		basePriceGenerationStepData,

		saveProgress,
		resumeProgress,

		roomTypeOptions,
		boardTypeOptions,
		roomTypeMapping,
		genericAccommodationData,
		roomSummaryCompleted,
		allRoomTypeMappings,
		parentRoomsAdded,
		addRoomTypeMapping,
		removeRoomTypeMapping,

		$reset,
	}
})
