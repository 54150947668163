import { WSMessageEvent } from '../../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../../utils/dates';
import {
  GetAccommodationDateDetailsRequest,
  GetAccommodationDateDetailsRequestPayload,
} from './GetAccommodationDateDetailsRequest';

export class GetAccommodationDateDetailsNetworkObject extends WebSocketNetworkObject<
  GetAccommodationDateDetailsRequest,
  GetAccommodationDateDetailsRequestPayload
> {
  constructor(params: GetAccommodationDateDetailsRequest) {
    super(params, WSMessageEvent.GetAccommodationDateDetails);
  }

  override getParams(): GetAccommodationDateDetailsRequestPayload {
    const { accommodationId, date } = this.params;
    return {
      accommodationId,
      date: getISODate(date),
    };
  }
}
