import { Range } from './Range';

export const enum StrategyType {
  Current = 'current',
  Preview = 'preview',
}

export enum Seasonality {
  High = 'high',
  // Mid = 'mid',
  Low = 'low',
}
export type DayOfTheWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type StrategySettings = {
  priceIncrease: number;
  priceDecrease: number;
  averagePrice: number;
  expertUserAveragePrice?: number;
  advancedExpertUserAveragePrice?: number;
  marketDemand?: number;
  isMarketDemandEnabled: boolean;
  rules: DropPickupRule[];
} & (
  | {
      isAdvancedExpertUserEnabled: false;
      drop?: Record<Seasonality, number>;
      pickup?: Record<Seasonality, number>;
      eventsAndHolidaysImpact?: number;
      dayOfTheWeekMap?: Record<Seasonality, Record<DayOfTheWeek, number>>;
    }
  | {
      isAdvancedExpertUserEnabled: true;
      drop: Record<Seasonality, number>;
      pickup: Record<Seasonality, number>;
      eventsAndHolidaysImpact: number;
      dayOfTheWeekMap: Record<Seasonality, Record<DayOfTheWeek, number>>;
    }
);

export type StrategyPeriod = {
  dateRange: Range<Date>;
  settings: StrategySettings;
};

export type Strategy = {
  accommodationId: string;
  baseStrategy: StrategySettings;
  periods: StrategyPeriod[];
};
export type DefaultStrategySettings = {
  weekSeasonalityMap: Record<string, Seasonality>; // { '1': Seasonality.High, '2': Seasonality.Mid, '3': Seasonality.Low, ... }
  dayOfTheWeekMap: Record<Seasonality, Record<string, number>>; // { [Seasonality.High]: { '1': 0.1, '2': 0.2, '3': 0.3, ... }, ... }
  demandMap: Record<Seasonality, Record<string, Record<string, number>>>; // { [Seasonality.High]: { '0': { '1': 0.1, '2': 0.2, '3': 0.3, ... }, ... }, ... }
};

export enum ExpectedChangeType {
  Percentage = 'percentage',
  Amount = 'amount',
}
export type OccupancyInterval = {
  from: number;
  to: number;
  expectedChange: {
    type: ExpectedChangeType;
    value: number;
  };
};
export type BookDaysInterval = {
  from: number;
  to: number;

  occupancyInterval: OccupancyInterval[];
};
export type DropPickupRule = {
  id: string;
  bookDaysInterval: BookDaysInterval;
};
