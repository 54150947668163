import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  GetRoomTypesEnabledModifiersRequest,
  GetRoomTypesEnabledModifiersRequestPayload,
} from './GetRoomTypesEnabledModifiersRequest';

export class GetRoomTypesEnabledModifiersNetworkObject extends WebSocketNetworkObject<
  GetRoomTypesEnabledModifiersRequest,
  GetRoomTypesEnabledModifiersRequestPayload
> {
  constructor(params: GetRoomTypesEnabledModifiersRequest) {
    super(params, WSMessageEvent.GetRoomTypesEnabledModifiers);
  }

  override getParams(): GetRoomTypesEnabledModifiersRequestPayload {
    const { roomTypeIds, dateRange } = this.params;

    return {
      roomTypeIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
