import { utilDate } from './utilDate'
class UtilJson {
    private readonly isoDateFormat =
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?/

    private isIsoDateString(value: any): boolean {
        return (
            value && typeof value === 'string' && this.isoDateFormat.test(value)
        )
    }

    public handleDates(body: any) {
        if (body === null || body === undefined || typeof body !== 'object')
            return body

        for (const key of Object.keys(body)) {
            const value = body[key]
            if (this.isIsoDateString(value)) {
                body[key] = utilDate.utcToCurrentTimezone(new Date(value))
            } else if (typeof value === 'object') this.handleDates(value)
        }
    }
}

export const utilJson = new UtilJson()
