<script setup lang="ts">
</script>

<template>
    <BallBackground>
        <div class="flex justify-center items-center h-full w-full">
            <SpLogo :product="SpLogoProduct.Smartpricing" :theme="SpLogoTheme.Dark" class="max-w-[290px]"></SpLogo>
        </div>
    </BallBackground>
</template>
