import { SIZE_TABLET_LANDSCAPE } from '../constants/screenSizes'

class UtilMobile {
	private _routesWhitelist = [
		'/auth',
		'/calendar',
		'/onboarding',
		'/competitors',
		'/strategies',
		'/maintenance',
		'/settings/language',
		'/settings/billing',
	]

	isMobileDevice() {
		return process.client && window.innerWidth <= SIZE_TABLET_LANDSCAPE
	}

	showRouteOnMobile(path: string) {
		return this._routesWhitelist.some((route) => path.startsWith(route))
	}
}

export const utilMobile = new UtilMobile()
