import { WSMessageEvent } from '../../common/WebSocket';
import { WebSocketNetworkObject } from '../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../utils/dates';
import {
  GetCalculateQuotationRequest,
  GetCalculateQuotationRequestPayload,
} from './GetCalculateQuotationRequest';

export class GetCalculateQuotationNetworkObject extends WebSocketNetworkObject<
  GetCalculateQuotationRequest,
  GetCalculateQuotationRequestPayload
> {
  constructor(params: GetCalculateQuotationRequest) {
    super(params, WSMessageEvent.CalculateQuotation);
  }

  override getParams(): GetCalculateQuotationRequestPayload {
    const { roomTypeIds, dateRange } = this.params;

    return {
      roomTypeIds,
      dateRange: convertDateRangeToISODateRange(dateRange),
    };
  }
}
