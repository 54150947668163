import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { getISODate } from '../../../utils/dates';
import {
  GetEventImpactPreviewRequest,
  GetEventImpactPreviewRequestPayload,
} from './GetEventImpactPreviewRequest';

export class GetEventImpactPreviewNetworkObject extends WebSocketNetworkObject<
  GetEventImpactPreviewRequest,
  GetEventImpactPreviewRequestPayload
> {
  constructor(params: GetEventImpactPreviewRequest) {
    super(params, WSMessageEvent.GetEventImpactPreview);
  }

  override getParams(): GetEventImpactPreviewRequestPayload {
    const {
      accommodationIds,
      event: { label, eventType, accommodationId },
      eventDetails: { repetitionType, impacts },
    } = this.params;

    return {
      accommodationIds,
      event: {
        label,
        eventType,
        accommodationId,
        eventDetails: {
          repetitionType,
          impacts: impacts.map((impact) => ({
            date: getISODate(impact.date),
            impact: impact.impact,
          })),
        },
      },
    };
  }
}
