import { useMobileActionsStore } from '../store/mobileActions'
import { utilMobile } from '../utils/utilMobile'

export default defineNuxtRouteMiddleware((to) => {
    useMobileActionsStore().removeActionHandler()

    const showOnMobile = utilMobile.showRouteOnMobile(to.path)
    const isMobile = utilMobile.isMobileDevice()

    if (isMobile && !showOnMobile) {
        return navigateTo('/calendar')
    }
})
