import { UserFeatureEvent } from './types/UserFeatureEvent'
import { UserFeedback } from './types/UserFeedback'

export class FeedbackHandler {
	// @ts-ignore
	projectId: string
	// @ts-ignore
	token: string
	// @ts-ignore
	headers: Headers
	// @ts-ignore
	serverUrl: string
	// @ts-ignore
	requestHandler: (event: UserFeatureEvent) => void
	events: UserFeatureEvent[] = []

	constructor(projectId: string, token: string, serverUrl: string, requestHandler: (event: UserFeatureEvent) => void) {
		this.init(projectId, token, serverUrl, requestHandler)
		document.addEventListener('pointerdown', this.eventListener)
	}

	public kill() {
		document.removeEventListener('pointerdown', this.eventListener)
	}

	private eventListener = (e: PointerEvent) => {
		setTimeout(() => {
			const event = this.events
				.filter((ev) => ev.event.onClick.length > 0)
				.find((feedbackEvent) =>
					feedbackEvent.event.onClick.some((identifier) =>
						this.elementOrParentMatches(e.target, ':not([disabled])' + identifier)
					)
				)

			if (event != undefined && this.attemptTrigger(event)) {
				this.requestHandler(event)
			}
		}, 100)
	}

	public init(projectId: string, token: string, serverUrl: string, requestHandler: (event: UserFeatureEvent) => void) {
		this.projectId = projectId
		this.token = token
		this.serverUrl = serverUrl
		this.requestHandler = requestHandler
		this.headers = new Headers()
		this.headers.append('Authorization', 'Bearer ' + token)
		this.headers.append('Content-Type', 'application/json')
		this.requestHandler = requestHandler
		this.updateEvents()
	}

	private elementOrParentMatches(element: EventTarget | null, queryString: string): Boolean {
		if (element == undefined) {
			return false
		}

		return (
			// @ts-ignore
			(element.matches != undefined && element.matches(queryString)) ||
			// @ts-ignore
			this.elementOrParentMatches(element.parentNode, queryString)
		)
	}

	private baseUrl(destination: string): URL {
		return `${this.serverUrl}/${destination}?project_key=${this.projectId}` as unknown as URL
	}

	private updateEvents() {
		fetch(this.baseUrl('events'), {
			method: 'GET',
			headers: this.headers,
		})
			.then(async (res) => {
				const response = await res.json()
				this.events = response.data.userFeatureEvents
			})
			.catch()
	}

	public onReject(eventId: number) {
		const event = this.events.find((ev) => ev.event.id === eventId)
		if (event == undefined) {
			return
		}

		event.userRejectionsCount++
		fetch(this.baseUrl('reject'), {
			method: 'POST',
			headers: this.headers,
			body: JSON.stringify({
				projectId: this.projectId,
				eventId: eventId,
			}),
		})
	}

	public onTrack(data: UserFeedback) {
		const event = this.events.find((el) => el.event.id === data.eventId)
		if (event == undefined) {
			return
		}

		event.userFeedbacksCount++
		fetch(this.baseUrl('track'), {
			method: 'POST',
			headers: this.headers,
			body: JSON.stringify(data),
		})
	}

	public onManualTrigger(id: number) {
		const event = this.events.find((el) => el.event.id === id)
		if (event == undefined) {
			return
		}

		if (this.attemptTrigger(event)) {
			this.requestHandler(event)
		}
	}

	// TODO: improve, lots of side-effects
	private attemptTrigger(ev: UserFeatureEvent): Boolean {
		// if the event is invalid remove it from the list & return false
		if (this.isEventInvalid(ev)) {
			this.events = this.events.filter((evFromList) => evFromList.event.id !== ev.event.id)

			return false
		}

		this.confirmInteraction(ev.event.id)
		const updatedEvent = this.events.find((e) => e.event.id === ev.event.id)!
		return updatedEvent.userInteractionsBeforeTriggerCount == 0 || updatedEvent.event.maxInteractionsBeforeTrigger == 0
	}

	private isEventInvalid(ev: UserFeatureEvent) {
		const triggersFulfilled = ev.event.maxTriggers > 0 && ev.event.maxTriggers <= ev.userFeedbacksCount
		const rejectionsFulfilled = ev.event.maxRejections > 0 && ev.event.maxRejections <= ev.userRejectionsCount

		return triggersFulfilled || rejectionsFulfilled
	}

	private confirmInteraction(eventId: number) {
		const ev = this.events.find((e) => e.event.id === eventId)
		if (ev == undefined) {
			return
		}

		// track the atempted interaction
		fetch(this.baseUrl('interaction'), {
			method: 'POST',
			headers: this.headers,
			body: JSON.stringify({
				eventId: ev.event.id,
			}),
		})

		// update the interactions before trigger
		const maxInteractions = Math.max(1, ev.event.maxInteractionsBeforeTrigger)
		ev.userInteractionsBeforeTriggerCount =
			Math.min(ev.userInteractionsBeforeTriggerCount + 1, ev.event.maxInteractionsBeforeTrigger) % maxInteractions
	}
}
