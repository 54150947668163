export const enum StorageKeys {
	SessionToken = 'smartpricing_token',
	RefreshToken = 'smartpricing_refresh_token',
	LastTrialPeriodNotification = 'last_trial_period_notification',
	PreferredLanguage = 'preferred_language',
	PlaygroundPopupShown = 'playground_popup_shown',
	JustLogged = 'just_logged',
	LogMethod = 'log_method',
	SalesMode = 'sales_mode',
	AutoOnboardingProgress = 'smartpricing_auto-onboarding-progress',
}
