import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { SalesModes } from '../constants/salesMode'

export interface AnonymizationStructure {
	name: TranslationKey
	accommodation: TranslationKey
	rooms: TranslationKey
	linkedPrices: TranslationKey
	competitors: TranslationKey
}

export const SalesModesConfig: { [k in SalesModes]: AnonymizationStructure } = {
	[SalesModes.Partner]: {
		name: TranslationKeys.SALES_MODE_HOTEL_MODE,
		accommodation: TranslationKeys.SALES_MODE_HOTEL,
		rooms: TranslationKeys.SALES_MODE_ROOM_TYPE,
		linkedPrices: TranslationKeys.OTHER_ROOM,
		competitors: TranslationKeys.SALES_MODE_COMPETITOR,
	},

	[SalesModes.Hotel]: {
		name: TranslationKeys.SALES_MODE_HOTEL_MODE,
		accommodation: TranslationKeys.SALES_MODE_HOTEL,
		rooms: TranslationKeys.SALES_MODE_ROOM_TYPE,
		linkedPrices: TranslationKeys.OTHER_ROOM,
		competitors: TranslationKeys.SALES_MODE_COMPETITOR,
	},

	[SalesModes.BnB]: {
		name: TranslationKeys.SALES_MODE_BNB_MODE,
		accommodation: TranslationKeys.SALES_MODE_BNB,
		rooms: TranslationKeys.SALES_MODE_ROOM_TYPE,
		linkedPrices: TranslationKeys.OTHER_ROOM,
		competitors: TranslationKeys.SALES_MODE_COMPETITOR,
	},

	[SalesModes.MultiProperty]: {
		name: TranslationKeys.SALES_MODE_MULTI_PROPERTY_MODE,
		accommodation: TranslationKeys.SALES_MODE_MULTI_PROPERTY,
		rooms: TranslationKeys.SALES_MODE_APARTMENT,
		linkedPrices: TranslationKeys.OTHER_APARTMENT,
		competitors: TranslationKeys.SALES_MODE_COMPETITOR,
	},
}
