<script lang="ts" setup>
import { devToolsConfig } from '~~/src/config/DevToolsConfig'
import { SalesModesConfig } from '~~/src/config/SalesModesConfig'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { SalesModes } from '~~/src/constants/salesMode'
import { activateSalesMode, deactivateSalesMode } from '~~/src/devMode/salesModes'
import { UtilSales } from '~~/src/utils/UtilSales'
import { useUserStore } from '../../store/user'
import { TranslationKeys } from '~/i18n/TranslationKeys'

let salesModes = Object.values(SalesModes).filter((mode) => mode !== SalesModes.Partner)
const visible = ref(false)
const canDisable = UtilSales.isSalesMode

const salesNames = Object.values(SalesModes).map((el) => SalesModesConfig[el].name)

// toggle shift + F9
window.addEventListener('keydown', (ev) => {
	// prevent demo users to access to sales mode config
	if (useUserStore().isDemoUser) {
		return
	}

	const shiftF9 = ev.key === devToolsConfig.salesModeToggleButton && ev.shiftKey
	const ctrlShiftM = ev.key === 'M' && ev.ctrlKey && ev.shiftKey

	if (shiftF9 || ctrlShiftM) {
		visible.value = !visible.value
	}
})

// on click!
const setSalesMode = (mode: SalesModes) => {
	UtilSales.setSalesMode(mode)
	activateSalesMode(mode)
	visible.value = false
}
const removeSalesMode = () => {
	UtilSales.setSalesMode(undefined)
	deactivateSalesMode()
	visible.value = false
}
</script>

<template>
	<div
		v-if="UtilSales.canUseSalesMode()"
		class="bt-1 fixed bottom-0 w-full gap-2 border border-black bg-white p-3"
		:class="visible ? 'flex' : 'hidden'"
		style="z-index: 99999999"
	>
		<CommonButton
			v-for="(mode, index) in salesModes"
			@click="setSalesMode(mode)"
			:size="ButtonSizes.TEXT_M"
			:type="ButtonTypes.PRIMARY"
		>
			<CommonText :text="salesNames[index]" />
		</CommonButton>
		<CommonButton v-if="canDisable" @click="removeSalesMode()" :size="ButtonSizes.TEXT_M" :type="ButtonTypes.PRIMARY">
			<CommonText :text="TranslationKeys.SALES_MODE_TURN_OFF" />
		</CommonButton>
		<CommonButton
			v-if="false"
			@click="testStatus.stauts = !testStatus.stauts"
			:type="!testStatus.stauts ? ButtonTypes.PRIMARY : ButtonTypes.SECONDARY"
			:size="ButtonSizes.TEXT_M"
			class="ml-10"
		>
			<CommonText text="Test Mode" />
		</CommonButton>
	</div>
</template>
