import {
	BasePrice,
	BasePricePredictionPreviewType,
	BasePriceVariation,
} from '../submodules/sharedTypes/common/BasePrice'
import { DataPoint } from '../submodules/sharedTypes/common/DataPoint'
import { TweakModifierType } from '../submodules/sharedTypes/common/Modifiers'

export enum BasePriceSourceType {
	StartingPrices = 'starting_prices',
	ParentRoom = 'parent_room',
}

export enum BasePricePeriodType {
	Future = 'future',
	Past = 'past',
}

export enum BasePriceDuplicatePeriodsType {
	Periods = 'periods',
	PeriodsAndPrices = 'periods_and_prices',
}

export type BasePriceEdited = Omit<BasePrice, 'price'> & {
	price: number | undefined
	isGap?: boolean
}

export type BasePrices = Map<number, Map<number, BasePrice[]>>

export type BasePricesEdited = Map<number, Map<number, BasePriceEdited[]>>

export type BasePricesVariations = Map<number, BasePriceVariationSettings | undefined>

export type BasePricesPreview = Map<number, DataPoint<Date, number | null>[]>

export type CurrentlyEditingBasePrice = {
	accommodationId: number
	roomTypeId: number
	basePrice: BasePriceEdited
}

export type BasePricePreviewList = {
	[BasePricePredictionPreviewType.Regular]: BasePricesPreview
	[BasePricePredictionPreviewType.Temporary]: BasePricesPreview
}

export type BasePriceVariationSettings = {
	[P in keyof BasePriceVariation]: BasePriceVariation[P] | undefined
}

export type BasePriceVariationModifierSettings = Omit<TweakModifierType, 'variationPrice' | 'targetPrice'>
