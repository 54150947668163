import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import {
  BillingInfoPayload,
  UpdateBillingInfoRequest,
  UpdateBillingInfoRequestPayload,
} from './UpdateBillingInfoRequest';

export class UpdateBillingInfoNetworkObject extends WebSocketNetworkObject<
  UpdateBillingInfoRequest,
  UpdateBillingInfoRequestPayload
> {
  constructor(params: UpdateBillingInfoRequest) {
    super(params, WSMessageEvent.UpdateBillingInfo);
  }

  override getParams(): UpdateBillingInfoRequestPayload {
    const { profileId, info } = this.params;
    const { city, countryCode, street, zipCode } = info.address;
    const infoPayload: BillingInfoPayload = {
      ...info,
      address: {
        city,
        countryCode,
        street,
        zipCode,
      },
    };

    return { profileId, info: infoPayload };
  }
}
