import { clsx, ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import tailwindConfig from '../../tailwind/defaultThemeColors'
import { TailwindColorKeys, TailwindColorWeight } from '@/types/Tailwind'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export type ColorConfig = {
	hex: string
	classes: string[]
}

export enum ComponentColorConfig {
	ChartSerie,
	ChartLegend,
	ChartSerieTooltip,
}

const colorConfig: {
	[key in ComponentColorConfig]: {
		[key in TailwindColorKeys]?: {
			[key in TailwindColorWeight]?: string[]
		}
	}
} = {
	[ComponentColorConfig.ChartSerie]: {
		'main-blue': {
			200: ['bg-main-blue-200', 'text-main-blue-900', 'hover:bg-main-blue-300', 'hover:text-main-blue-900'],
			300: ['bg-main-blue-300', 'text-main-blue-900', 'hover:bg-main-blue-200'],
			400: ['bg-main-blue-400', 'text-main-blue-900', 'hover:bg-main-blue-500', 'hover:text-main-blue-50'],
			500: ['bg-main-blue-500', 'text-white', 'hover:bg-main-blue-400'],
			600: ['bg-main-blue-600', 'text-main-blue-50', 'hover:bg-main-blue-700', 'hover:text-main-blue-50'],
		},
		'light-blue': {
			100: ['bg-light-blue-100', 'text-light-blue-900', 'hover:bg-light-blue-200', 'hover:text-light-blue-900'],
			200: ['bg-light-blue-200', 'text-light-blue-900', 'hover:bg-light-blue-300', 'hover:text-light-blue-900'],
			300: ['bg-light-blue-300', 'text-light-blue-900', 'hover:bg-light-blue-400', 'hover:text-light-blue-900'],
			400: ['bg-light-blue-400', 'text-light-blue-900', 'hover:bg-light-blue-500', 'hover:text-light-blue-50'],
			500: ['bg-light-blue-500', 'text-light-blue-50', 'hover:bg-light-blue-600', 'hover:text-light-blue-50'],
			600: ['bg-light-blue-600', 'text-light-blue-50', 'hover:bg-light-blue-700', 'hover:text-light-blue-50'],
		},
		'dark-red': {
			200: ['bg-dark-red-200', 'text-dark-red-900', 'hover:bg-dark-red-300', 'hover:text-dark-red-900'],
			300: ['bg-dark-red-300', 'text-white', 'hover:bg-dark-red-200', 'hover:text-white'],
			400: ['bg-dark-red-400', 'text-dark-red-50', 'hover:bg-dark-red-500', 'hover:text-dark-red-50'],
			600: ['bg-dark-red-600', 'text-dark-red-50', 'hover:bg-dark-red-700', 'hover:text-dark-red-50'],
		},
		lavander: {
			200: ['bg-lavander-200', 'text-lavander-900', 'hover:bg-lavander-300', 'hover:text-lavander-900'],
			400: ['bg-lavander-400', 'text-lavander-50', 'hover:bg-lavander-500', 'hover:text-lavander-50'],
			500: ['bg-lavander-500', 'text-lavander-50', 'hover:bg-lavander-600', 'hover:text-lavander-50'],
			600: ['bg-lavander-600', 'text-lavander-50', 'hover:bg-lavander-700', 'hover:text-lavander-50'],
		},
		'water-blue': {
			200: ['bg-water-blue-200', 'text-water-blue-900', 'hover:bg-water-blue-300', 'hover:text-water-blue-900'],
			300: ['bg-water-blue-300', 'text-water-blue-900', 'hover:bg-water-blue-400', 'hover:text-water-blue-900'],
			400: ['bg-water-blue-400', 'text-water-blue-900', 'hover:bg-water-blue-500', 'hover:text-water-blue-50'],
			500: ['bg-water-blue-500', 'text-white', 'hover:bg-water-blue-600', 'hover:text-water-blue-50'],
			600: ['bg-water-blue-600', 'text-water-blue-50', 'hover:bg-water-blue-700', 'hover:text-water-blue-50'],
		},
		'light-orange': {
			200: ['bg-light-orange-200', 'text-light-orange-900', 'hover:bg-light-orange-300', 'hover:text-light-orange-900'],
			400: ['bg-light-orange-400', 'text-light-orange-900', 'hover:bg-light-orange-500', 'hover:text-light-orange-900'],
			500: ['bg-light-orange-500', 'text-light-orange-900', 'hover:bg-light-orange-600', 'hover:text-light-orange-900'],
			600: ['bg-light-orange-600', 'text-light-orange-900', 'hover:bg-light-orange-700', 'hover:text-light-orange-50'],
		},
		'light-yellow': {
			200: ['bg-light-yellow-200', 'text-light-yellow-900', 'hover:bg-light-yellow-300', 'hover:text-light-yellow-900'],
			400: ['bg-light-yellow-400', 'text-light-yellow-900', 'hover:bg-light-yellow-500', 'hover:text-light-yellow-900'],
			600: ['bg-light-yellow-600', 'text-light-yellow-900', 'hover:bg-light-yellow-700', 'hover:text-light-yellow-50'],
			700: ['bg-light-yellow-700', 'text-white', 'hover:bg-light-yellow-800'],
		},
		yellow: {
			100: ['bg-yellow-100', 'text-yellow-900', 'hover:bg-yellow-50'],
			200: ['bg-yellow-200', 'text-yellow-900', 'hover:bg-yellow-300', 'hover:text-yellow-900'],
			300: ['bg-yellow-300', 'text-yellow-900', 'hover:bg-yellow-200'],
			400: ['bg-yellow-400', 'text-yellow-900', 'hover:bg-yellow-500', 'hover:text-yellow-900'],
			600: ['bg-yellow-600', 'text-yellow-900', 'hover:bg-yellow-700', 'hover:text-yellow-50'],
		},
		'light-green': {
			200: ['bg-light-green-200', 'text-light-green-900', 'hover:bg-light-green-300', 'hover:text-light-green-900'],
			400: ['bg-light-green-400', 'text-light-green-900', 'hover:bg-light-green-500', 'hover:text-light-green-900'],
			600: ['bg-light-green-600', 'text-light-green-900', 'hover:bg-light-green-700', 'hover:text-light-green-50'],
		},
		'gem-green': {
			200: ['bg-gem-green-200', 'text-gem-green-900', 'hover:bg-gem-green-300', 'hover:text-gem-green-900'],
			300: ['bg-gem-green-300', 'text-gem-green-900', 'hover:bg-gem-green-400', 'hover:text-gem-green-900'],
			400: ['bg-gem-green-400', 'text-gem-green-900', 'hover:bg-gem-green-500', 'hover:text-gem-green-900'],
			500: ['bg-gem-green-500', 'text-gem-green-900', 'hover:bg-gem-green-600', 'hover:text-gem-green-900'],
			600: ['bg-gem-green-600', 'text-gem-green-900', 'hover:bg-gem-green-700', 'hover:text-gem-green-50'],
		},
		'secondary-gray': {
			200: ['bg-secondary-gray-200', 'text-secondary-gray-800', 'hover:bg-secondary-gray-100'],
			300: ['bg-secondary-gray-300', 'text-secondary-gray-800', 'hover:bg-secondary-gray-200'],
			500: ['bg-secondary-gray-500', 'text-white', 'hover:bg-secondary-gray-400'],
		},
		red: {
			200: ['bg-red-200', 'text-red-900', 'hover:bg-red-300', 'hover:text-red-900'],
			400: ['bg-red-400', 'text-red-900', 'hover:bg-red-500', 'hover:text-red-50'],
			600: ['bg-red-600', 'text-red-50', 'hover:bg-red-700', 'hover:text-red-50'],
		},
		green: {
			300: ['bg-green-300', 'text-green-900', 'hover:bg-green-400', 'hover:text-green-900'],
		},
	},
	[ComponentColorConfig.ChartLegend]: {
		'main-blue': {
			100: ['bg-main-blue-100', 'text-main-blue-800'],
			300: ['bg-main-blue-300', 'text-main-blue-900', 'hover:bg-main-blue-200'],
			500: ['bg-main-blue-500', 'text-white', 'hover:bg-main-blue-400'],
		},
		'light-blue': {
			100: ['bg-light-blue-100', 'text-light-blue-800'],
			300: ['bg-light-blue-300', 'text-light-blue-900', 'hover:bg-light-blue-400', 'hover:text-light-blue-900'],
			500: ['bg-light-blue-500', 'text-light-blue-50', 'hover:bg-light-blue-600', 'hover:text-light-blue-50'],
		},
		'water-blue': {
			300: ['bg-water-blue-300', 'text-water-blue-900'],
			500: ['bg-water-blue-500', 'text-white'],
		},
		'light-orange': {
			500: ['bg-light-orange-500', 'text-light-orange-900'],
			600: ['bg-light-orange-600', 'text-light-orange-900', 'hover:bg-light-orange-700', 'hover:text-light-orange-50'],
		},
		'light-yellow': {
			700: ['bg-light-yellow-700', 'text-white', 'hover:bg-light-yellow-800'],
		},
		'gem-green': {
			300: ['bg-gem-green-300', 'text-gem-green-900'],
			500: ['bg-gem-green-500', 'text-gem-green-900'],
		},
		green: {
			300: ['bg-green-300', 'text-green-900', 'hover:bg-green-400', 'hover:text-green-900'],
		},
		yellow: {
			100: ['bg-yellow-100', 'text-yellow-900', 'hover:bg-yellow-50'],
			200: ['bg-yellow-200', 'text-yellow-900', 'hover:bg-yellow-300'],
			300: ['bg-yellow-300', 'text-yellow-900', 'hover:bg-yellow-200'],
			400: ['bg-yellow-400', 'text-yellow-900', 'hover:bg-yellow-500'],
		},
		lavander: {
			400: ['bg-lavander-400', 'text-lavander-50', 'hover:bg-lavander-500', 'hover:text-lavander-50'],
			500: ['bg-lavander-500', 'text-lavander-50', 'hover:bg-lavander-600', 'hover:text-lavander-50'],
		},
		'secondary-gray': {
			200: ['bg-secondary-gray-200', 'text-secondary-gray-800', 'hover:bg-secondary-gray-100'],
			300: ['bg-secondary-gray-300', 'text-secondary-gray-800', 'hover:bg-secondary-gray-200'],
			500: ['bg-secondary-gray-500', 'text-white', 'hover:bg-secondary-gray-400'],
		},
		red: {
			100: ['bg-red-100', 'text-red-900', 'hover:bg-red-200', 'hover:text-red-900'],
			200: ['bg-red-200', 'text-red-900', 'hover:bg-red-300', 'hover:text-red-900'],
			400: ['bg-red-400', 'text-red-900', 'hover:bg-red-500', 'hover:text-red-50'],
			500: ['bg-red-500', 'text-white', 'hover:bg-red-400'],
			600: ['bg-red-600', 'text-red-50', 'hover:bg-red-700', 'hover:text-red-50'],
		},
		'dark-red': {
			200: ['bg-dark-red-200', 'text-dark-red-900', 'hover:bg-dark-red-300', 'hover:text-dark-red-900'],
			400: ['bg-dark-red-400', 'text-dark-red-50', 'hover:bg-dark-red-500', 'hover:text-dark-red-50'],
		},
	},
	[ComponentColorConfig.ChartSerieTooltip]: {
		'main-blue': {
			50: ['bg-main-blue-50', 'text-main-blue-900'],
			100: ['bg-main-blue-100', 'text-main-blue-900'],
			200: ['bg-main-blue-200', 'text-main-blue-900'],
			300: ['bg-main-blue-300', 'text-main-blue-900'],
			500: ['bg-main-blue-500', 'text-white'],
		},
		'light-blue': {
			50: ['bg-light-blue-50', 'text-light-blue-900'],
			100: ['bg-light-blue-100', 'text-light-blue-900'],
			300: ['bg-light-blue-300', 'text-light-blue-900'],
			500: ['bg-light-blue-500', 'text-light-blue-50'],
		},
		'water-blue': {
			300: ['bg-water-blue-300', 'text-water-blue-900'],
			500: ['bg-water-blue-500', 'text-white'],
		},
		'light-orange': {
			500: ['bg-light-orange-500', 'text-light-orange-900'],
			600: ['bg-light-orange-600', 'text-light-orange-900'],
		},
		'gem-green': {
			300: ['bg-gem-green-300', 'text-gem-green-900'],
			500: ['bg-gem-green-500', 'text-gem-green-900'],
		},
		yellow: {
			50: ['bg-yellow-50', 'text-yellow-900'],
			100: ['bg-yellow-100', 'text-yellow-900'],
			200: ['bg-yellow-200', 'text-yellow-900'],
			400: ['bg-yellow-400', 'text-yellow-900'],
		},
		'light-yellow': {
			700: ['bg-light-yellow-700', 'text-white'],
		},
		lavander: {
			400: ['bg-lavander-400', 'text-lavander-50'],
			500: ['bg-lavander-500', 'text-lavander-50'],
		},
		'secondary-gray': {
			50: ['bg-secondary-gray-50', 'text-secondary-gray-800'],
			100: ['bg-secondary-gray-100', 'text-secondary-gray-800'],
		},
		'dark-red': {
			100: ['bg-dark-red-100', 'text-dark-red-900'],
			200: ['bg-dark-red-200', 'text-dark-red-900'],
			400: ['bg-dark-red-400', 'text-dark-red-50'],
		},
		red: {
			50: ['bg-red-50', 'text-red-900'],
			100: ['bg-red-100', 'text-red-900'],
			200: ['bg-red-200', 'text-red-900'],
		},
		green: {
			300: ['bg-green-300', 'text-green-900'],
		},
	},
}

export function getSingleColorConfig(
	component: ComponentColorConfig,
	options: { color: TailwindColorKeys; weight: TailwindColorWeight }
): ColorConfig {
	const { color, weight } = options
	const weights = colorConfig[component][color] ?? {}

	return {
		hex: options != undefined ? tailwindConfig[color][weight] : '',
		classes: weights[weight] ?? [],
	}
}

/**
 * Return a color object based on Tailwind configuration.
 * The pattern is defined here: https://www.figma.com/file/V0GTlF36wbnQoVxvMsKhyy/SPUXUI-176%3A-GHT-Product-Experience?type=design&node-id=2317-36316&mode=design&t=bzd0vZ8aLZm0GvSJ-0
 *
 * @param color name from Tailwind config
 * @param weight from Tailwind config
 * @returns an object with computed color values
 */
export function getManyColorConfigWithContrast(
	component: ComponentColorConfig,
	options: { colors: TailwindColorKeys[]; weights: TailwindColorWeight[] }[]
): ColorConfig[] {
	return options.reduce((acc: ReturnType<typeof getSingleColorConfig>[], { colors, weights }) => {
		colors.forEach((color) => {
			weights.forEach((weight) => {
				acc.push(getSingleColorConfig(component, { color, weight }))
			})
		})
		return acc
	}, [])
}
