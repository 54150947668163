import { WSMessageEvent } from '../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../common/WebSocketNetworkObject';
import { convertDateRangeToISODateRange } from '../../../utils/dates';
import {
  UpdateAccommodationBasePricesRequest,
  UpdateAccommodationBasePricesRequestPayload,
} from './UpdateAccommodationBasePricesRequest';

export class UpdateAccommodationBasePricesNetworkObject extends WebSocketNetworkObject<
  UpdateAccommodationBasePricesRequest,
  UpdateAccommodationBasePricesRequestPayload
> {
  constructor(params: UpdateAccommodationBasePricesRequest) {
    super(params, WSMessageEvent.UpdateAccommodationBasePrices);
  }

  override getParams(): UpdateAccommodationBasePricesRequestPayload {
    const { accommodationId, roomTypes } = this.params;

    return {
      accommodationId,
      roomTypes: roomTypes.map((roomType) => ({
        id: roomType.id,
        variation: roomType.variation,
        basePrices: roomType.basePrices.map((basePrice) => ({
          ...basePrice,
          dateRange: convertDateRangeToISODateRange(basePrice.dateRange),
        })),
      })),
    };
  }
}
