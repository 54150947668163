export interface ILanguageSpecificUrls {
  language: string
  url: string
  extendsToChilds: boolean
}

export const languageLimitedPages: ILanguageSpecificUrls[] = [
  {
    language: 'it',
    url: '/smartpaying',
    extendsToChilds: true,
  },
]
