import { differenceInCalendarDays, intervalToDuration } from 'date-fns'
import { notificationPeriod } from '../config/trialPeriodConfig'
import { CardTypes } from '../constants/cardTypes'
import { NotificationDuration } from '../constants/notificationDuration'
import { StorageKeys } from '../constants/storageKeys'
import { useBannerStore } from './../store/banners'
import { useNotificationsStore } from './../store/notifications'
import { TranslationKeys } from '~/i18n/TranslationKeys'

class UtilTrialPeriod {
	public handlePeriod(endDate: Date | undefined) {
		const remainingDays = this.getDaysUntilPeriodEnd(endDate)

		// if we can't get the amount of missing date, it means the trial is over
		if (remainingDays == undefined) {
			return
		}

		if (remainingDays > notificationPeriod) {
			this.makeNotification(remainingDays)
		} else {
			this.makeBanner(remainingDays)
		}
	}

	private getDaysUntilPeriodEnd(endDate: Date | undefined): number | undefined {
		// no date = no banner/notification
		if (endDate == undefined) {
			return undefined
		}

		return differenceInCalendarDays(endDate, new Date())
	}

	private makeBanner(days: number) {
		const banners = useBannerStore()
		const replacementAmount = days.toString()
		const replacement = days == 1 ? TranslationKeys.DAY : TranslationKeys.DAYS

		banners.addBanner({
			canClose: true,
			cardType: CardTypes.INFO,
			title: TranslationKeys.TRIAL_PERIOD,
			message: TranslationKeys.TRIAL_PERIOD_MESSAGE,
			messageReplacements: [replacementAmount, replacement],
		})
	}

	private makeNotification(days: number) {
		// max 1 notification per day
		if (this.notificationAlreadyBooked()) {
			return
		}

		this.saveNotificationInteraction()

		const replacement = days == 1 ? TranslationKeys.DAY : TranslationKeys.DAYS
		const notifications = useNotificationsStore()
		notifications.addNotification(
			{
				canClose: false,
				cardType: CardTypes.INFO,
				title: TranslationKeys.TRIAL_PERIOD,
				message: TranslationKeys.TRIAL_PERIOD_MESSAGE,
				messageReplacements: [days.toString(), replacement],
			},
			NotificationDuration.LONG
		)
	}

	private notificationAlreadyBooked(): boolean {
		const lastNotification = this.getLastNotificationDate()

		// no notification saved = no notification previously shown
		if (lastNotification == undefined) {
			return false
		}

		const today = new Date()
		let start: Date = new Date()
		const daysDiff = intervalToDuration({
			start,
			end: today,
		})

		return daysDiff.days != undefined && daysDiff.days < 1
	}

	private getLastNotificationDate() {
		const lastNotification = localStorage.getItem(StorageKeys.LastTrialPeriodNotification)

		if (lastNotification == undefined) {
			return undefined
		}

		try {
			return new Date(lastNotification)
		} catch {
			return undefined
		}
	}

	private saveNotificationInteraction() {
		localStorage.setItem(StorageKeys.LastTrialPeriodNotification, new Date().toISOString())
	}
}

export const utilTrialPeriod = new UtilTrialPeriod()
