import { SpSvg } from '~/autogen/SpSvg'
import { ModifierType } from './../submodules/sharedTypes/common/Modifiers'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

export type modifiersDisplayData = {
	text: TranslationKey
	icon: SpSvg
	backgroundColor: string
	textColor: string
	iconColor: string
	borderColor: string
	editorHoverBorder: string
	editorFocusBorder: string
	activeBackground: string
	activeCurrencyIconColor: string
}

export const modifiersConfig: { [k in ModifierType]: modifiersDisplayData } = {
	[ModifierType.Frozen]: {
		text: TranslationKeys.MODIFIER_FROZEN,
		icon: SpSvg.CalendarModFreeze,
		backgroundColor: 'bg-main-blue-200',
		textColor: 'text-main-blue-600',
		iconColor: 'fill-main-blue-600',
		borderColor: 'border-main-blue-400',
		editorHoverBorder: 'hover:border-main-blue-300',
		editorFocusBorder:
			'focus-within:!border-main-blue-400 focus-within:outline focus-within:outline-1 focus-within:outline-main-blue-400',
		activeBackground: 'bg-main-blue-100',
		activeCurrencyIconColor: 'text-main-blue-500',
	},
	[ModifierType.Min]: {
		text: TranslationKeys.MODIFIER_MIN,
		icon: SpSvg.CalendarModMin,
		backgroundColor: 'bg-light-blue-200',
		textColor: 'text-light-blue-600',
		iconColor: 'fill-light-blue-600',
		borderColor: 'border-light-blue-300',
		editorHoverBorder: 'hover:border-light-blue-300',
		editorFocusBorder:
			'focus-within:!border-light-blue-400 focus-within:outline focus-within:outline-1 focus-within:outline-light-blue-400',
		activeBackground: 'bg-light-blue-100',
		activeCurrencyIconColor: 'text-light-blue-700',
	},
	[ModifierType.Max]: {
		text: TranslationKeys.MODIFIER_MAX,
		icon: SpSvg.CalendarModMax,
		backgroundColor: 'bg-light-blue-200',
		textColor: 'text-light-blue-600',
		iconColor: 'fill-light-blue-600',
		borderColor: 'border-light-blue-300',
		editorHoverBorder: 'hover:border-light-blue-300',
		editorFocusBorder:
			'focus-within:!border-light-blue-400 focus-within:outline focus-within:outline-1 focus-within:outline-light-blue-400',
		activeBackground: 'bg-light-blue-100',
		activeCurrencyIconColor: 'text-light-blue-700',
	},
	[ModifierType.Tweak]: {
		text: TranslationKeys.MODIFIER_TWEAK,
		icon: SpSvg.CalendarModMarkup,
		backgroundColor: 'bg-red-200',
		textColor: 'text-red-500',
		iconColor: 'fill-red-500',
		borderColor: 'border-red-300',
		editorHoverBorder: 'hover:border-red-300',
		editorFocusBorder:
			'focus-within:!border-red-400 focus-within:outline focus-within:outline-1 focus-within:outline-red-400',
		activeBackground: 'bg-red-100',
		activeCurrencyIconColor: '',
	},
}
