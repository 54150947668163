import { Accommodation } from '@/types/Accommodation'

export function getBadgeType(score: number, maxScore: number = 10) {
	if (score < 0.5 * maxScore) {
		return SpBadgeType.Low
	} else if (score < 0.75 * maxScore) {
		return SpBadgeType.Mid
	} else {
		return SpBadgeType.High
	}
}

export function isMarketIntelligenceEnabledOnAccommodation(accommodation: Accommodation) {
	return accommodation.purchases.marketIntelligence.permissions.access
}
