import userflow from 'userflow.js/src/userflow'
import { SalesModes } from '~/constants/salesMode'
import { StorageKeys } from '~/constants/storageKeys'
import { activateSalesMode } from '~/devMode/salesModes'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { useUserStore } from '~/store/user'
import { Localizable } from '~/types/Localizable'

export default function () {
	const { $i18n } = useNuxtApp()
	const { locale: currentLocale, setLocale: setI18nLocale, availableLocales: i18nAvailableLocales, t: $t, te } = $i18n

	const availableLocales = computed(() => i18nAvailableLocales)

	const initLocale = () => {
		const browserLocale = useNavigatorLanguage().language.value?.split('-')[0] || ''
		const localStorageLocale = localStorage.getItem(StorageKeys.PreferredLanguage) || ''

		if (localStorageLocale && availableLocales.value.includes(localStorageLocale)) {
			setLocale(localStorageLocale)
		} else if (browserLocale && availableLocales.value.includes(browserLocale)) {
			setLocale(browserLocale)
		} else {
			setLocale('en')
		}
	}

	const setLocale = (locale: string) => {
		if (availableLocales.value.includes(locale)) {
			setI18nLocale(locale)

			const mode = localStorage.getItem(StorageKeys.SalesMode) || SalesModes.Hotel
			//if (mode) activateSalesMode(mode as SalesModes)

			// and the update the storage
			localStorage.setItem(StorageKeys.PreferredLanguage, locale)

			// update Userflow locale
			if (useUserStore().userflowInitialized) {
				userflow.updateUser({
					selected_lang: locale,
					locale_code: locale,
				})
				utilHotjar.update({})
			}

			// salesMode refresh!
			if (UtilSales.isSalesMode.value) {
				activateSalesMode(localStorage.getItem(StorageKeys.SalesMode) as SalesModes)
			}
		} else {
			console.error(`You are attempting to set an unsupported locale language!
            You are attempting to set: ${locale}
            While the available locales are: ${availableLocales}`)
		}
	}

	const translate = (key: TranslationKey | Localizable, replacements: string[] = [], adjustCase = true): string => {
		if (typeof key !== 'string') {
			const localizable = key as Localizable
			return translateLocalizable(localizable, adjustCase)
		}

		return $t(key, replacements)
	}

	const translateLocalizable = (localizable: Localizable, adjustCase: boolean): string => {
		// @ts-ignore
		if ((localizable.key as String) == '' || !te(localizable.key)) {
			return localizable.key.toString()
		}

		let replacements = (localizable.replacements?.() || []).map((el) => translateLocalizable(el, adjustCase))

		return $t(localizable.key, replacements)
	}

	return {
		currentLocale,
		availableLocales,
		initLocale,
		setLocale,
		translate,
		translateLocalizable,
	}
}
