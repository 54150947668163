// TODO: move to a hook (useSpRouter)
class UtilRouter {
    navigate(url: string, options?: { isExternalLink?: boolean, openToNewWindow?: boolean }) {
        if (options?.isExternalLink) {
            if (options?.openToNewWindow) {
                window.open(url, '_blank')
                return
            }

            window.location.assign(url)
            return
        }


        useRouter().push(url)
    }

    currentPath() {
        const router = useRouter()
        return router.currentRoute.value.path
    }

    hashParams() {
        const router = useRouter()
        let val = router.currentRoute.value.hash
        if (val.startsWith('#')) {
            val = val.replace('#', '')
        }

        return val
    }
}

export const utilRouter = new UtilRouter()
