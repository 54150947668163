import { defineStore } from 'pinia'
import { OnboardingStep, getSteps } from '../config/OnboardingSteps'
import { TrackingMessages } from '../constants/trackingMessages'
import { GetOnboardingProfileLastUpdateNetworkObject } from '../submodules/sharedTypes/communication/onboarding/profile/GetOnboardingProfileLastUpdateNetworkObject'
import { GetOnboardingProfileLastUpdateResponse } from '../submodules/sharedTypes/communication/onboarding/profile/GetOnboardingProfileLastUpdateResponse'
import { SetOnboardingProfileLastUpdateNetworkObject } from '../submodules/sharedTypes/communication/onboarding/profile/SetOnboardingProfileUpdateNetworkObject'
import { GenerateOnboardingProfileNetworkObject } from '../submodules/sharedTypes/communication/onboarding/profile/GenerateOnboardingProfileNetworkObject'
import { OnboardingProfile } from '../submodules/sharedTypes/common/OnboardingProfile'
import { UploadOnboardingBasePricesNetworkObject } from '../submodules/sharedTypes/communication/onboarding/profile/UploadOnboardingBasePricesNetworkObject'

type OnboardingState = {
	ready: boolean
	steps: OnboardingStep[]
	currentStepIndex: number
	profile?: OnboardingProfile
	areBasePricesUploaded: boolean
}

export const useOnboardingStore = defineStore('❓ onboarding', {
	state: (): OnboardingState => ({
		ready: false,
		steps: getSteps(),
		currentStepIndex: 0,
		profile: undefined,
		areBasePricesUploaded: false,
	}),

	actions: {
		init() {
			utilNetwork.simpleRequest(new GetOnboardingProfileLastUpdateNetworkObject({}))
		},

		setLastUpdate(props: GetOnboardingProfileLastUpdateResponse) {
			this.ready = true
			this.steps = getSteps(props.answers)
			this.currentStepIndex = props.lastAnswerIndex ? props.lastAnswerIndex + 1 : 0
			this.profile = props.profile
			this.areBasePricesUploaded = props.areBasePricesUploaded ?? false

			if (props.selectedLocale) {
				useLocale().setLocale(props.selectedLocale)
			}
		},

		nextStep() {
			let nextStepIndex =
				this.currentStep.options.find((option) => option.selected)?.nextStepIndex ?? this.currentStepIndex + 1

			// Take into consideration disabled steps and skip over them
			// while going forward in the customer profile process.
			while (this.steps[nextStepIndex].isDisabled) {
				nextStepIndex = nextStepIndex + 1
			}

			this.currentStepIndex = nextStepIndex
		},

		prevStep() {
			const prevStepId = this.currentStep.id
			const prevStepIndex = this.steps
				.slice(0, this.currentStepIndex)
				.findIndex((step) =>
					step.options.find((option) => option.selected && option.nextStepIndex === this.currentStepIndex)
				)

			let goToStepIndex = prevStepIndex >= 0 ? prevStepIndex : this.currentStepIndex - 1

			// Take into consideration disabled steps and skip over them
			// while going backwards in the customer profile process.
			while (this.steps[goToStepIndex].isDisabled) {
				goToStepIndex = goToStepIndex - 1
			}

			this.currentStepIndex = goToStepIndex

			const currentStepId = this.currentStep.id

			utilTracking.track(TrackingMessages.CUSTOMER_PROFILE_BACK, {
				current_step_id: currentStepId,
				prev_step_id: prevStepId,
			})
		},

		setAnswer(answer: number) {
			this.steps[this.currentStepIndex].options.forEach((option, index) => {
				option.selected = answer === index

				if (option.stepsIndexToClearSelection) {
					option.stepsIndexToClearSelection.forEach((stepIndex: number) => {
						this.steps[stepIndex].options.forEach((option) => (option.selected = false))
					})
				}
			})

			let trackingStepIndex = this.currentStepIndex

			if (trackingStepIndex >= 4) {
				trackingStepIndex = this.answers[2] != null ? this.currentStepIndex - 1 : this.currentStepIndex - 2
			}

			utilTracking.track(`Customer profile step ${trackingStepIndex}`, {
				response_id: this.currentStep.options.find((option) => option.selected)?.id,
				step_id: this.currentStep.id,
			})

			utilNetwork.simpleRequest(
				new SetOnboardingProfileLastUpdateNetworkObject({
					answers: this.answers,
					selectedLocale: useLocale().currentLocale.value,
				})
			)

			if (!this.isLastStep) {
				this.nextStep()
			}
		},

		generateProfile() {
			utilTracking.track(TrackingMessages.CUSTOMER_PROFILE_END, {})
			utilNetwork.simpleRequest(
				new GenerateOnboardingProfileNetworkObject({
					answers: this.answers,
					selectedLocale: useLocale().currentLocale.value,
				})
			)
		},

		uploadBasePricesFiles(files: any[]) {
			utilNetwork.simpleRequest(
				new UploadOnboardingBasePricesNetworkObject({
					files,
				})
			)

			utilTracking.track(TrackingMessages.PRICE_LIST_UPLOAD, {})
		},
	},

	getters: {
		isIntroStep(): boolean {
			return this.currentStepIndex === 0
		},
		isLastStep(): boolean {
			return this.currentStepIndex === this.steps.length - 1
		},
		totalSteps(): number {
			const isStep2bSelected = this.steps
				.find((step) => step.id === 'step-2b')
				?.options.some((option) => option.selected)
			return isStep2bSelected ? 10 : 9
		},
		currentStep(): OnboardingStep {
			return this.steps[this.currentStepIndex]
		},
		// NOTE: this should not be used anywhere since we're explicitly avoiding
		// the possibility for clients to upload their own base prices sheets.
		canUploadBasePrices(): boolean {
			// We do not care about how clients have answered onboarding
			// questions since we're not allowing them to upload their own
			// base prices in any case.

			// return this.answers[1] === 1 || this.answers[2] === 1 || this.answers[3] === 1
			return false
		},
		answers(): (number | null)[] {
			return this.steps.slice(1).reduce((acc: Array<number | null>, step) => {
				let finalAnswer = null
				if (step.isDisabled && step.defaultAnswer !== undefined) {
					finalAnswer = step.defaultAnswer
				} else {
					const answerIndex = step.options.findIndex((option) => option.selected)
					finalAnswer = answerIndex >= 0 ? answerIndex : null
				}

				return acc.concat(finalAnswer)
			}, [])
		},
	},
})
