import { defineStore, storeToRefs } from 'pinia'
import { useAccommodationsStore } from './accommodations'
import { UpdateOrphanNightsSettingsNetworkObject } from '../submodules/sharedTypes/communication/orphan-nights/UpdateOrphanNightsSettings/UpdateOrphanNightsSettingsNetworkObject'
import { OrphanNightsFixedDate } from '../submodules/sharedTypes/common/OrphanNights'
import { GetOrphanNightsFixedDatesNetworkObject } from '../submodules/sharedTypes/communication/orphan-nights/GetOrphanNightsFixedDates/GetOrphanNightsFixedDatesNetworkObject'
import { addDays } from 'date-fns'
import { calendarConfig } from '../config/CalendarConfig'
import { GetOrphanNightsFixedDatesResponse } from '../submodules/sharedTypes/communication/orphan-nights/GetOrphanNightsFixedDates/GetOrphanNightsFixedDatesResponse'
import { useLoadingStore } from './loading'
import { LoadingIds } from '../constants/loadingIds'
import { CardTypes } from '@/constants/cardTypes'
import { IMessageCardData } from '@/types/IMessageCardData'
import _ from 'lodash'
import { TrackingMessages } from '~/constants/trackingMessages'

export const useOrphanNightsStore = defineStore('⚙️ orphan nights', () => {
	const accommodationsStore = useAccommodationsStore()
	const { selectedAccommodation, getSelectedTab } = storeToRefs(useSettingsStore())
	const notificationsStore = useNotificationsStore()

	// Orphan Nights Settings
	// Accommodation
	const isOrphanNightsAvailableForSelectedAccommodation = computed(
		() => !!selectedAccommodation.value?.isOrphanNightsManagementAvailable
	)
	const isOrphanNightsEnabledForSelectedAccommodation = ref(
		selectedAccommodation.value?.settings?.orphanNights?.enabled ?? false
	)
	const orphanNightsMinStaySelectedAccommodation = ref(
		selectedAccommodation.value?.settings?.orphanNights?.minStay ?? 1
	)

	// Room Types settings
	const getSelectedAccommodationRoomTypesSettings = () => {
		return (
			selectedAccommodation.value?.roomTypes?.reduce(
				(roomTypes, currentRoomType) => {
					roomTypes[currentRoomType.id] = {
						enabled: currentRoomType.settings.orphanNights?.enabled ?? false,
						minStay: currentRoomType.settings.orphanNights?.minStay ?? 1,
					}
					return roomTypes
				},
				{} as { [key: number]: OrphanNightsRoomTypeItem }
			) ?? {}
		)
	}
	const selectedAccommodationRoomTypesSettings = ref<{ [key: string]: OrphanNightsRoomTypeItem }>(
		getSelectedAccommodationRoomTypesSettings()
	)

	// Handle select accommodation change
	watch([selectedAccommodation, getSelectedTab], async () => {
		isOrphanNightsEnabledForSelectedAccommodation.value =
			selectedAccommodation.value?.settings?.orphanNights.enabled ?? false
		orphanNightsMinStaySelectedAccommodation.value = selectedAccommodation.value?.settings?.orphanNights.minStay ?? 1
		selectedAccommodationRoomTypesSettings.value = getSelectedAccommodationRoomTypesSettings()
	})

	// Orphan Nights Calendar
	const fixedDatesStartDate: Ref<Date> = ref(new Date())
	const fixedDates = ref(new Map<number, OrphanNightsFixedDate[]>())
	const showOrphanNightsCalendarLegend = computed(
		() =>
			accommodationsStore.accommodations.find(
				(accommodation) => accommodation.settings?.orphanNights.enabled === true
			) !== undefined
	)
	const showOrphanNightsFixedDate = computed(() => {
		return (roomTypeId: number | undefined, date: Date) => {
			if (!roomTypeId) {
				return false
			}

			const fixedDatesToConsider = fixedDates.value.get(roomTypeId) || []
			const fixedDateIndex = fixedDatesToConsider.findIndex((fixedDate) => fixedDate.date.getTime() === date.getTime())

			if (fixedDateIndex <= -1) {
				return false
			}

			return fixedDatesToConsider[fixedDateIndex].orphanNightsCount > 0
		}
	})
	const getFixedDatesByRoomTypeId = computed(() => {
		return (roomTypeId: number) => fixedDates.value.get(roomTypeId) || []
	})
	function setFixedDatesStartDate(newDate: Date) {
		fixedDatesStartDate.value = newDate
	}
	function setFixedDates(res: GetOrphanNightsFixedDatesResponse) {
		res.accommodations.forEach((accommodation) => {
			accommodation.fixedDates.forEach((fixedDate) => {
				if (!fixedDates.value.has(fixedDate.roomTypeId)) {
					fixedDates.value.set(fixedDate.roomTypeId, [])
				}
				fixedDates.value.get(fixedDate.roomTypeId)!.push(fixedDate)
			})
		})
	}
	function resetFixedDates() {
		fixedDates.value.clear()
	}
	async function requestFixedDates() {
		resetFixedDates()
		const loadingStore = useLoadingStore()
		await loadingStore.getPromiseDependency(LoadingIds.ACCOMMODATIONS)

		const accommodations = accommodationsStore.accommodationsToShow.length
			? accommodationsStore.accommodationsToShow
			: accommodationsStore.accommodations.map((element) => element.id)

		const request = new GetOrphanNightsFixedDatesNetworkObject({
			accommodationIds: accommodations,
			dateRange: {
				from: fixedDatesStartDate.value,
				to: addDays(fixedDatesStartDate.value, calendarConfig.maxDays - 1),
			},
		})
		utilNetwork.simpleRequest(request, undefined, LoadingIds.GET_FIXED_DATES)
	}

	// Methods for settings store
	const checkForChanges = computed(
		() =>
			!_.isEqual(selectedAccommodationRoomTypesSettings.value, getSelectedAccommodationRoomTypesSettings()) ||
			isOrphanNightsEnabledForSelectedAccommodation.value !==
				selectedAccommodation.value?.settings?.orphanNights.enabled
	)

	async function save() {
		try {
			const isEnabledForAccommodation = isOrphanNightsEnabledForSelectedAccommodation.value
			utilNetwork.simpleRequest(
				new UpdateOrphanNightsSettingsNetworkObject({
					accommodationId: selectedAccommodation.value?.id!,
					accommodationMinStay: orphanNightsMinStaySelectedAccommodation.value,
					roomTypes: isEnabledForAccommodation
						? Object.entries(selectedAccommodationRoomTypesSettings.value).map(([key, value]) => ({
								id: parseInt(key),
								enabled: value.enabled,
								minStay: value.minStay,
							}))
						: [],
				})
			)

			utilTracking.track(TrackingMessages.GAP_FILLING_SETTING_CHANGED, {
				accommodation_name: selectedAccommodation.value?.name,
				is_activated: !!isEnabledForAccommodation,
				is_threshold:
					!!isEnabledForAccommodation &&
					(orphanNightsMinStaySelectedAccommodation.value > 1 ||
						Object.entries(selectedAccommodationRoomTypesSettings.value).some(([_key, value]) => value.minStay > 1)),
			})
		} catch (error) {
			notificationsStore.addNotification(<IMessageCardData>{
				title: useLocale().translate(TranslationKeys.UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_TITLE),
				message: useLocale().translate(TranslationKeys.UPDATE_ORPHAN_NIGHTS_SETTINGS_NOTIFICATION_ERROR_DESCRIPTION),
				canClose: true,
				cardType: CardTypes.ERROR,
			})
		}
	}

	// Utils
	function $reset() {}

	return {
		selectedAccommodation,

		// Accommodation settings
		isOrphanNightsAvailableForSelectedAccommodation,
		isOrphanNightsEnabledForSelectedAccommodation,
		orphanNightsMinStaySelectedAccommodation,

		// Room types settings
		selectedAccommodationRoomTypesSettings,

		// Settings store methods
		checkForChanges,
		save,

		// Calendar
		showOrphanNightsCalendarLegend,
		showOrphanNightsFixedDate,
		getFixedDatesByRoomTypeId,
		setFixedDatesStartDate,
		setFixedDates,
		resetFixedDates,
		requestFixedDates,

		// Utils
		$reset,
	}
})
