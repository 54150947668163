import { defineStore } from 'pinia'
import { FeedbackId } from '../constants/FeedbackId'
import { RoomTypeEnabledModifiers } from '../submodules/sharedTypes/common/RoomTypeEnabledModifiers'
import { BulkUpdateModifiersNetworkObject } from '../submodules/sharedTypes/communication/modifiers/BulkUpdateModifiersNetworkObject'
import { BulkUpdateModifiersRequest } from '../submodules/sharedTypes/communication/modifiers/BulkUpdateModifiersRequest'
import { GetRoomTypesEnabledModifiersNetworkObject } from '../submodules/sharedTypes/communication/modifiers/GetRoomTypesEnabledModifiersNetworkObject'
import { GetRoomTypesEnabledModifiersResponse } from '../submodules/sharedTypes/communication/modifiers/GetRoomTypesEnabledModifiersResponse'
import { makeModifiersNotification } from './../notifications/ModifiersNotifications'
import { Modifiers, ModifierType, TweakModifierType } from './../submodules/sharedTypes/common/Modifiers'
import { Range } from './../submodules/sharedTypes/common/Range'
import { BulkRemoveModifiersNetworkObject } from './../submodules/sharedTypes/communication/modifiers/BulkRemoveModifiersNetworkObject'
import { BulkUpdateModifiersMultipleRoomTypesNetworkObject } from './../submodules/sharedTypes/communication/modifiers/BulkUpdateModifiersMultipleRoomTypesNetworkObject'
import { utilModifiers } from './../utils/utilModifiers'
import { utilNetwork } from './../utils/UtilNetwork'
import { useFeedbackStore } from './feedback'
import { useNotificationsStore } from './notifications'

// TODO: change

type UserState = {
	dateRange: Range<Date>[]
	modifiers: RoomTypeEnabledModifiers[]

	// TODO: this should be handled in a better way
	trackingMessage: any
	dateFilter: number[]
	selectedIds: number[]
	singleModifiersPopupContent: Map<number, Modifiers>
}

export const useModifiersStore = defineStore('modifiers', {
	state: (): UserState => ({
		dateRange: [{ from: new Date(), to: new Date() }],
		modifiers: [],

		// TODO: this should be handled in a better way
		trackingMessage: {},
		dateFilter: [],
		selectedIds: [],
		singleModifiersPopupContent: new Map<number, Modifiers>(),
	}),
	actions: {
		setModifiers(data: GetRoomTypesEnabledModifiersResponse) {
			this.dateRange.push(data.dateRange)
			this.modifiers.push(...data.roomTypes)
		},
		clearModifiers() {
			this.modifiers = []
		},
		requestForRange(dateRange: Range<Date>, roomTypeIds: number[], cleanCurrentModifier: boolean = true) {
			this.dateRange = [dateRange]

			if (cleanCurrentModifier) {
				this.modifiers = []
			}

			utilNetwork.simpleRequest(
				new GetRoomTypesEnabledModifiersNetworkObject({
					dateRange,
					roomTypeIds,
				})
			)
		},
		bulkUpdateSingleRoomsModifiers(
			dateRange: Range<Date>,
			selectedDaysOfWeek: number[],
			values: Map<number, Modifiers>
		) {
			const keys = Array.from(values.keys())
			const request: BulkUpdateModifiersRequest = {
				dateRange: dateRange,
				selectedDaysOfWeek,
				modifiersByRoomType: keys.map((key) => ({
					roomTypeId: key,
					modifiers: values.get(key)!,
				})),
			}

			utilNetwork.simpleRequest(new BulkUpdateModifiersNetworkObject(request))

			useNotificationsStore().addNotification(makeModifiersNotification(Array.from(values.keys()), dateRange))
			useFeedbackStore().requestFeedback(FeedbackId.BulkPriceCustomization)
		},
		bulkUpdateMultipleRoomsModifiers(
			dateRange: Range<Date>,
			selectedDaysOfWeek: number[],
			roomTypeIds: number[],
			tweak: TweakModifierType
		) {
			utilNetwork.simpleRequest(
				new BulkUpdateModifiersMultipleRoomTypesNetworkObject({
					dateRange,
					roomTypeIds,
					tweak,
					selectedDaysOfWeek,
				})
			)

			useNotificationsStore().addNotification(makeModifiersNotification(roomTypeIds, dateRange))
			useFeedbackStore().requestFeedback(FeedbackId.BulkPriceCustomization)
		},
		bulkRemoveModifier(
			dateRange: Range<Date>,
			selectedDaysOfWeek: number[],
			roomTypeIds: number[],
			modifierType: ModifierType
		) {
			this.modifiers = this.modifiers.map((el) => {
				if (roomTypeIds.includes(el.roomTypeId)) {
					return {
						roomTypeId: el.roomTypeId,
						enabledModifiers: el.enabledModifiers.filter((mod) => mod != modifierType),
					}
				}

				return el
			})

			utilNetwork.simpleRequest(
				new BulkRemoveModifiersNetworkObject({
					dateRange,
					modifierType,
					roomTypeIds,
					selectedDaysOfWeek,
				})
			)

			useNotificationsStore().addNotification(makeModifiersNotification(roomTypeIds, dateRange))
		},
		bulkRemoveAllModifiers(dateRange: Range<Date>, selectedDaysOfWeek: number[], roomTypeIds: number[]) {
			const modifierTypes = Array.from(Object.values(ModifierType))

			modifierTypes.forEach((modifierType) =>
				utilNetwork.simpleRequest(
					new BulkRemoveModifiersNetworkObject({
						dateRange,
						modifierType,
						roomTypeIds,
						selectedDaysOfWeek,
					})
				)
			)

			useNotificationsStore().addNotification(makeModifiersNotification(roomTypeIds, dateRange))
		},
		setSingleModifiersForModal(
			dateRange: Range<Date>,
			dateFilter: number[],
			singleModifiersPopupContent: Map<number, Modifiers>,
			trackingMessage: any
		) {
			this.dateRange = [dateRange]
			this.dateFilter = dateFilter
			this.singleModifiersPopupContent = singleModifiersPopupContent
			this.trackingMessage = trackingMessage
		},
	},
	getters: {
		appliedModifiersInRange() {
			return (roomTypeIds: number[]) => this.modifiers.filter((mod) => roomTypeIds.includes(mod.roomTypeId))
		},
		getModifiersForRoomTypeId() {
			return (id: number) => this.modifiers.find((el) => el.roomTypeId === id)?.enabledModifiers || []
		},
		getCommonModifiersForRoomTypeId() {
			return (ids: number[]): ModifierType[] => {
				const commonModifiers = new Set<ModifierType>()

				const consideredModifiers = this.modifiers
					.filter((el) => ids.includes(el.roomTypeId))
					.map((el) => el.enabledModifiers)

				consideredModifiers.forEach((appliedModifiers) => appliedModifiers.forEach((mod) => commonModifiers.add(mod)))

				return Array.from(commonModifiers) as ModifierType[]
			}
		},
		getCountActiveModifiers() {
			return (ids: number[]) => {
				const consideredRooms = this.modifiers.filter((el) => ids.includes(el.roomTypeId))
				const modifiers: Map<ModifierType, number> = new Map<ModifierType, number>([
					[ModifierType.Frozen, 0],
					[ModifierType.Max, 0],
					[ModifierType.Min, 0],
					[ModifierType.Tweak, 0],
				])

				consideredRooms.forEach((el) => {
					el.enabledModifiers.forEach((modifier) => modifiers.set(modifier, modifiers.get(modifier)! + 1))
				})

				return modifiers
			}
		},
		getAssociatedModifiers() {
			return (ids: number[]): Modifiers => {
				const modifiers = this.modifiers.filter((el) => ids.includes(el.roomTypeId))
				const max = modifiers.reduce(
					(prev: number, next) => (next?.lowestMax != undefined && next.lowestMax < prev ? next.lowestMax : prev),
					Number.MAX_VALUE
				)
				const min = modifiers.reduce(
					(prev: number, next) => (next?.highestMin != undefined && next.highestMin > prev ? next.highestMin : prev),
					Number.MIN_VALUE
				)

				return {
					min,
					max,
				}
			}
		},
		getModifiersConflict() {
			return (values: Map<number, Modifiers>) =>
				// check if there's any error comparing against the fetched values
				Array.from(values.keys()).some((key) => {
					const existingModifiers = this.modifiers.find((el) => el.roomTypeId === key)

					const preExistingModifiers: Modifiers[] = []
					if (existingModifiers != undefined) {
						preExistingModifiers.push({
							max: existingModifiers.lowestMax,
							min: existingModifiers.highestMin,
						})
					}

					return utilModifiers.hasErrors(values.get(key)!, preExistingModifiers)
				})
		},
	},
})
