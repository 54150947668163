import { UserProfileTrackingProperties } from './UserProfileTrackingProperties';

export const enum SharedTrackingProperties {
  IsOldTrackingOrigin = 'is_old_tracking_origin',
  TrackingOrigin = 'tracking_origin',
  AccommodationsCount = 'accommodations_count',
  VisibleRoomTypesCount = 'visible_room_types_count',
  RoomTypesCount = 'room_types_count',
  AccommodationsCountries = 'accommodations_countries',
  AccommodationsRegions = 'accommodations_regions',
  AccommodationsCities = 'accommodations_cities',
  AccommodationsIds = UserProfileTrackingProperties.AccommodationsIds,
  AccommodationsNames = UserProfileTrackingProperties.AccommodationsNames,
  EnvironmentType = 'environment_type',
  BillingStatus = 'billing_status',
  IsMobileVersion = 'is_mobile_version',
}
