export const enum TextSizes {
	PRODUCTIVE = 'text-xs font-normal leading-4',
	PRODUCTIVE_LABEL = 'text-xs font-normal leading-6',
	BODY_LIGHT = 'text-sm font-light leading-5',
	BODY_REGULAR = 'text-sm font-normal leading-5',
	BODY_SEMIBOLD = 'text-sm font-semibold leading-5',
	BODY_LARGE = 'font-semibold leading-6',
	HEADING_REGULAR = 'text-lg leading-6',
	HEADING_MD = 'text-lg font-semibold leading-6',
	HEADING_MD_REGULAR = 'text-lg font-normal leading-6',
	HEADING_LG = 'text-2xl font-semibold leading-8',
	HEADING_LG_MINOR_LEADING = 'text-2xl font-semibold leading-6',
}
