import { ButtonSizes } from './../constants/buttonSizes'
export const buttonSizesConfig: { [k in ButtonSizes]: string } = {
    [ButtonSizes.AUTO_SIZE]: 'w-full max-w-full truncate h-11 p-3',

    [ButtonSizes.TEXT_L]: 'w-full truncate h-11',
    [ButtonSizes.ICON_L]: 'w-full truncate h-11 px-3',

    [ButtonSizes.TEXT_M]: 'truncate h-9 px-4 flex justify-center items-center',
    [ButtonSizes.ICON_M]: 'truncate h-9 w-9 ',

    [ButtonSizes.TEXT_S]:
        'truncate h-fit h-6 px-4 flex justify-center items-center',
    [ButtonSizes.ICON_S]: 'truncate p-1 h-fit',
}

export const getButtonSizeConfig = (key: ButtonSizes): string => {
    return buttonSizesConfig[key] as string
}
