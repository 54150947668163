import { differenceInDays } from 'date-fns'
import { defineStore } from 'pinia'
import { LoadingIds } from '../constants/loadingIds'
import { TrackingMessages } from '../constants/trackingMessages'
import { DateRange } from '../submodules/sharedTypes/common/DateRange'
import { GetCalculateQuotationNetworkObject } from '../submodules/sharedTypes/communication/quote/GetCalculateQuotationNetworkObject'
import { GetCalculateQuotationResponse } from '../submodules/sharedTypes/communication/quote/GetCalculateQuotationResponse'
import { utilNetwork } from '../utils/UtilNetwork'
import { utilTracking } from './../utils/utilTracking'

type CalculatedQuotationsState = {
    calculatedQuotation?: number
    roomAmount?: number
    nightsAmount?: number
}

export const useCalculatedQuotationsStore = defineStore(
    'calculated quotations',
    {
        state: (): CalculatedQuotationsState => ({
            calculatedQuotation: undefined,
            roomAmount: undefined,
            nightsAmount: undefined,
        }),
        actions: {
            resetQuotation() {
                this.calculatedQuotation = undefined
                this.roomAmount = undefined
                this.nightsAmount = undefined
            },
            setQuotation(quotation: GetCalculateQuotationResponse) {
                this.calculatedQuotation = quotation.quote
            },
            requestQuotation(roomTypeIds: number[], dateRange: DateRange) {
                this.resetQuotation()

                const request = new GetCalculateQuotationNetworkObject({
                    roomTypeIds,
                    dateRange,
                })

                utilNetwork.simpleRequest(
                    request,
                    undefined,
                    LoadingIds.CALCULATED_QUOTATIONS
                )
                this.nightsAmount = differenceInDays(
                    dateRange.to,
                    dateRange.from
                )
                this.roomAmount = roomTypeIds.length
                utilTracking.track(TrackingMessages.QUOTE_GET, {
                    nights: this.nightsAmount,
                    rooms: this.roomAmount,
                })
            },
        },
    }
)
