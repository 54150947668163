import forOwn from 'lodash/forOwn'
import { utilDate } from './utilDate'

class UtilParser {
    private readonly isoDateFormat =
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?/

    public format(val: any) {
        const objectType = typeof val
        if (objectType == 'object') {
            forOwn(val, (value, key) => {
                val[key] = this.format(value)
            })
        } else if (objectType == 'string') {
            if (this.isIsoDateString(val)) {
                val = this.parseDate(val)
            }
        }
        return val
    }

    private isIsoDateString(value: any): boolean {
        return (
            value && typeof value === 'string' && this.isoDateFormat.test(value)
        )
    }

    public parseDate(isoDateString: string) {
        if (this.isIsoDateString(isoDateString)) {
            return utilDate.utcToCurrentTimezone(new Date(isoDateString))
        } else {
            throw Error('not a date!')
        }
    }
}

export const utilParser = new UtilParser()
