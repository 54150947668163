import { defineStore } from 'pinia'
import { StorageKeys } from '~/constants/storageKeys'
type IntegrationType = 'full' | 'partial' | null

export const useSmartpayingStore = defineStore('smartpayingStore', () => {
	const integration = useLocalStorage<IntegrationType>('paymentAccomodationStore_integration', null)
	const hasCityTax = useLocalStorage<boolean>('paymentAccomodationStore_hasCityTax', true)
	const linkOptions = useSessionStorage<
		Array<{ label: string | undefined; value: number; hasCityTax: boolean; integration: IntegrationType }>
	>('paymentAccomodationStore_links', [])

	const canManageReservations = computed(() => {
		return integration.value !== 'full' && integration.value !== 'partial'
	})

	const pgId = ref<number>(0)

	const isUsingAllAccomodations = computed(() => {
		return pgId.value === -1
	})

	const hasFullIntegration = computed(() => {
		return linkOptions.value.some((pg) => pg.integration === 'full')
	})
	const hasPartialIntegration = computed(() => {
		return linkOptions.value.some((pg) => pg.integration === 'partial')
	})

	async function load() {
		try {
			const propertyGroups = await RestClient.$get<Array<any>>(`v1/propertySet`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem(StorageKeys.SessionToken)}`,
				},
			})
			linkOptions.value = propertyGroups
				.filter((pg) => pg.id)
				.map((propertyGroup) => {
					return {
						label: propertyGroup.name,
						value: propertyGroup.id,
						hasCityTax: propertyGroup.config?.cityTaxEnabled ?? false,
						integration: propertyGroup.integration,
					}
				})
		} catch {}
	}

	const $reset = () => {
		integration.value = null
		hasCityTax.value = true
		linkOptions.value = []
		pgId.value = 0
	}

	return {
		linkOptions,
		integration,
		hasCityTax,
		canManageReservations,
		pgId,
		isUsingAllAccomodations,
		hasFullIntegration,
		hasPartialIntegration,
		load,
		$reset,
	}
})
