<script setup lang="ts">
import axios from 'axios'
import { cheatsHelper } from './devMode/cheatsHelper'
import { UtilSales } from './utils/UtilSales'
import { loadLoggedSectionPrereqs, redirectUserAfterLogin } from './utils/loggedSectionPrereqs'
import { isSessionActive } from './utils/utilToken'
import userflow from 'userflow.js/src/userflow'
import { useTesting } from '~/hooks/useTesting'

const ready = ref(false)

useTesting()

const { initLocale } = useLocale()
initLocale()

const checkForMaintenance = async () => {
	try {
		const isAuthRoute = useRouter().currentRoute.value.path.includes('auth')
		const isMaintenancePage = useRouter().currentRoute.value.path.includes('maintenance')
		const { data } = await axios.get(useRuntimeConfig().public.API_BASE_URL + '/maintenance')
		const { status: isMaintenance } = data.data
		if (isMaintenance && !isAuthRoute && !isMaintenancePage) {
			await utilLogout.logout()
		}
		if (isMaintenance && !isMaintenancePage) {
			await navigateTo('/maintenance')
		}

		if (!isMaintenance && isMaintenancePage) {
			await navigateTo('/calendar')
		}
	} catch (e) {}
}

onMounted(async () => {
	userflow.init(useRuntimeConfig().public.USERFLOW_TOKEN)

	if (isSessionActive()) {
		await loadLoggedSectionPrereqs()

		await redirectUserAfterLogin(false)
	}

	ready.value = true

	if (process.client) {
		checkForMaintenance()
		setInterval(async () => {
			checkForMaintenance()
		}, 60000)
	}
})
</script>

<template>
	<div>
		<Head>
			<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
		</Head>
		<NuxtLayout v-if="ready">
			<NuxtPage />
			<DevToolsCheats v-if="cheatsHelper.canShowCheats()" />
			<DevToolsSalesModes v-if="UtilSales.canUseSalesMode()" />
		</NuxtLayout>
		<ScreensLoading v-else />
	</div>
</template>
