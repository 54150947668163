import { Environments } from './environments'

export const CURRENCY_EUR_WITH_3_DIGITS = {
	maximumSignificantDigits: 3,
	style: 'currency',
	currency: 'EUR',
}

export const CURRENCY_EUR_WITH_5_DIGITS = {
	maximumSignificantDigits: 5,
	style: 'currency',
	currency: 'EUR',
}

export const CURRENCY_EUR_WITHOUT_FRACTION_DIGITS = {
	maximumFractionDigits: 0,
	style: 'currency',
	currency: 'EUR',
}

export const MODIFIERS = ['frozen', 'max', 'min', 'tweak']

export const LOWER_MONTHS_BOUND = 24
export const IS_PROD = () => useRuntimeConfig().public.ENVIRONMENT === Environments.Production
export const IS_DEMO_ENV = () => useRuntimeConfig().public.ENVIRONMENT === Environments.Demo
export const IS_PLAYGROUND_ENV = () => useRuntimeConfig().public.ENVIRONMENT === Environments.Playground
export const IS_DEV_MODE = () => useRuntimeConfig().public.ENVIRONMENT === Environments.Development
export const IS_STAGING = () => useRuntimeConfig().public.ENVIRONMENT === Environments.Staging
export const IS_ACCOUNTING_SESSION = () => window.localStorage.getItem('smartpricing_accounting_session')
