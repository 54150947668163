export enum TestIds {
	// Login
	LoginContainer = 'login-container',
	LoginEmailField = 'login-email-field',
	LoginPasswordField = 'login-password-field',
	LoginShowPassword = 'login-show-password',
	LoginLangDropdown = 'login-lang-dropdown',
	LoginDropdownContainer = 'login-dropdown-container',
	LoginLogin = 'login-login',
	LoginTry = 'login-try',
	LoginReset = 'login-reset',
	LoginForgotDialog = 'login-forgot-dialog',
	LoginForgotEmailField = 'login-forgot-email-field',
	LoginForgotX = 'login-forgot-x',
	LoginForgotCancel = 'login-forgot-cancel',
	LoginForgotReset = 'login-forgot-reset',
	LoginFailedPopup = 'login-failed-popup',

	// Navbar
	NavbarContainer = 'navbar',
	NavbarCalendar = 'navbar-calendar',
	NavbarDashboard = 'navbar-dashboard',
	NavbarCharts = 'navbar-charts',
	NavbarStrategies = 'navbar-strategies',
	NavbarMarket = 'navbar-market',
	NavbarAccomodations = 'navbar-accomodations',
	NavbarSupportGuided = 'navbar-support-guided',
	NavbarUserDropdown = 'navbar-user-dropdown',
	NavbarUserSettings = 'navbar-user-settings',
	NavbarUserLogout = 'navbar-user-logout',

	// Dasboard
	DashboardViewDropdownBtn = 'dashboard-view-dropdown-btn',
	DashboardViewDropdownContainer = 'dashboard-view-dropdown-container',
	DashboardComparisonContainer = 'dashboard-comparison-container',
	DashboardComparisonMaster = 'dashboard-comparison-master',
	DashboardComparisonElementN = 'dashboard-comparison-element-',
	DashboardAddComparisonPlus = 'dashboard-add-comparison-plus',
	DashboardAddComparison = 'dashboard-add-comparison',
	DashboardComparisonBin = 'dashboard-comparison-bin',
	DashboardEditComparisonX = 'dashboard-edit-comparison-x',
	DashboardEditComparisonCancel = 'dashboard-edit-comparison-cancel',
	DashboardEditComparisonConfirm = 'dashboard-edit-comparison-confirm',
	DashboardEditComparisonPgDropdown = 'dashboard-edit-comparison-pg-dropdown',
	DashboardEditComparisonPgDropdownContainer = 'dashboard-edit-comparison-pg-dropdown-container',
	DashboardEditComparisonCreationInput = 'dashboard-edit-comparison-creation-input',
	DashboardEditComparisonStayInput = 'dashboard-edit-comparison-stay-input',
	DashboardEditComparisonDatepicker = 'dashboard-edit-comparison-datepicker',
	DashboardEditComparisonQuickactionsContainer = 'dashboard-edit-comparison-quickactions-container',
	DashboardTable = 'dashboard-table',
	DashboardMainChartContainer = 'dashboard-main-chart-container',
	DashboardChartTooltip = 'dashboard-chart-tooltip',
	DashboardGranularityDropdownBtn = 'dashboard-granularity-dropdown-btn',
	DashboardGranularityDropdownContainer = 'dashboard-granularity-dropdown-container',
	DashboardSideChartsContainer = 'dashboard-side-charts-container',
	DashboardMainChartExpandBtn = 'dashboard-main-chart-expand-btn',
	DashboardFourChartsBtn = 'dashboard-four-charts-btn',
	DashboardExitFullViewBtn = 'dashboard-exit-full-view-btn',
	DashboardSaveNewviewBtn = 'dashboard-save-newview-btn',
	DashboardSaveViewBtn = 'dashboard-save-view-btn',
	DashboardDeleteViewBtn = 'dashboard-delete-view-btn',
	DashboardSaveNewviewDialog = 'dashboard-save-newview-dialog',
	DashboardSaveNewviewDialogNameInput = 'dashboard-save-newview-dialog-name-input',
	DashboardSaveNewviewDialogSaveBtn = 'dashboard-save-newview-dialog-save-btn',
	DashboardSaveNewviewDialogCancelBtn = 'dashboard-save-newview-dialog-cancel-btn',
	DashboardSaveViewConfirmationPopup = 'dashboard-save-view-confirmation-popup',

	// Strategies
	StrategiesDropdownBtn = 'strategies-dropdown-btn',
	StrategiesDropdownContainer = 'strategies-dropdown-container',
	StrategiesSupportBtn = 'strategies-support-btn',
	StrategiesGuidesPopup = 'strategies-guides-popup',
	StrategiesGuidesXBtn = 'strategies-guides-x-btn',
	StrategiesGuidesFulldocBtn = 'strategies-guides-fulldoc-btn',
	StrategiesInfoboxContainer = 'strategies-infobox-container',
	StrategiesInfoboxXBtn = 'strategies-infobox-x-btn',
	StrategiesRestoreBtn = 'strategies-restore-btn',
	StrategiesRestorePopup = 'strategies-restore-popup',
	StrategiesRestorePopupCancelBtn = 'strategies-restore-popup-cancel-btn',
	StrategiesRestorePopupXButton = 'strategies-restore-popup-x-button',
	StrategiesRestorePopupResetBtn = 'strategies-restore-popup-reset-btn',
	StrategiesUndoBtn = 'strategies-undo-btn',
	StrategiesSaveStrategyBtn = 'strategies-save-strategy-btn',
	StrategiesConfigurationContainer = 'strategies-configuration-container',
	StrategiesNewPeriodBtn = 'strategies-new-period-btn',
	StrategiesNewPeriodPopupContainer = 'strategies-new-period-popup-container',
	StrategiesNewPeriodPopupPeriodField = 'strategies-new-period-popup-period-field',
	StrategiesNewPeriodPopupDatepicker = 'strategies-new-period-popup-datepicker',
	StrategiesNewPeriodPopupCancelBtn = 'strategies-new-period-popup-cancel-btn',
	StrategiesNewPeriodPopupConfirmBtn = 'strategies-new-period-popup-confirm-btn',
	StrategiesConfigurationDefault = 'strategies-configuration-default',
	StrategiesConfigurationExpand = 'strategies-configuration-expand',
	StrategiesConfigurationExpandedContainer = 'strategies-configuration-expanded-container',
	StrategiesConfigurationExpandedShrinkView = 'strategies-configuration-expanded-shrink-view',
	StrategiesConfigurationExpandedAggressivness = 'strategies-configuration-expanded-aggressivness',
	StrategiesConfigurationExpandedStartingprice = 'strategies-configuration-expanded-startingprice',
	StrategiesConfigurationExpandedDeleteBtn = 'strategies-configuration-expanded-delete-btn',
	StrategiesConfigurationExpandedEditBtn = 'strategies-configuration-expanded-edit-btn',
	StrategiesModifyPeriodPopupContainer = 'strategies-modify-period-popup-container',
	StrategiesModifyPeriodPopupPeriodField = 'strategies-modify-period-popup-period-field',
	StrategiesModifyPeriodPopupDatepicker = 'strategies-modify-period-popup-datepicker',
	StrategiesModifyPeriodPopupCancelBtn = 'strategies-modify-period-popup-cancel-btn',
	StrategiesModifyPeriodPopupConfirmBtn = 'strategies-modify-period-popup-confirm-btn',
	StrategiesConfigurationExpandedEditAdvancedBtn = 'strategies-configuration-expanded-edit-advanced-btn',
	StrategiesConfigurationExpandedBacktobasicBtn = 'strategies-configuration-expanded-backtobasic-btn',
	StrategiesConfigurationCustomContainer = 'strategies-configuration-custom-container',
	StrategiesAdvancedBackBtn = 'strategies-advanced-back-btn',
	StrategiesAdvancedSectionsContainer = 'strategies-advanced-sections-container',
	StrategiesAdvancedDropLink = 'strategies-advanced-drop-link',
	StrategiesAdvancedDropBar = 'strategies-advanced-drop-bar',
	StrategiesAdvancedDayLink = 'strategies-advanced-day-link',
	StrategiesAdvancedDayMonday = 'strategies-advanced-day-monday',
	StrategiesAdvancedDayTuesday = 'strategies-advanced-day-tuesday',
	StrategiesAdvancedDayWednesday = 'strategies-advanced-day-wednesday',
	StrategiesAdvancedDayThursday = 'strategies-advanced-day-thursday',
	StrategiesAdvancedDayFriday = 'strategies-advanced-day-friday',
	StrategiesAdvancedDaySaturday = 'strategies-advanced-day-saturday',
	StrategiesAdvancedDaySunday = 'strategies-advanced-day-sunday',
	StrategiesAdvancedEventsLink = 'strategies-advanced-events-link',
	StrategiesAdvancedEventsBar = 'strategies-advanced-events-bar',
	StrategiesAdvancedStartingLink = 'strategies-advanced-starting-link',
	StrategiesAdvancedStartingField = 'strategies-advanced-starting-field',
	StrategiesChartContainer = 'strategies-chart-container',
	StrategiesChartExpandBtn = 'strategies-chart-expand-btn',
	StrategiesChartRoomsDropdownBtn = 'strategies-chart-rooms-dropdown-btn',
	StrategiesChartRoomsDropdownContainer = 'strategies-chart-rooms-dropdown-container',
	StrategiesConfigurationExpandedMarketOccupancyToggle = 'strategies-configuration-expanded-market-occupancy-toggle',
	StrategiesConfigurationExpandedMarketOccupancy = 'strategies-configuration-expanded-market-occupancy',
	StrategiesAdvancedMarketImpactLink = 'strategies-advanced-market-impact-link',
	StrategiesAdvancedMarketImpactToggle = 'strategies-advanced-market-impact-toggle',
	StrategiesAdvancedMarketImpactBar = 'strategies-advanced-market-impact-bar',
	// market intelligence
	MarketDropdown = 'market-dropdown',
	MarketDropdownContainer = 'market-dropdown-container',
	MarketCompetitorsContainer = 'market-competitors-container',
	MarketCompetitorsHelpButton = 'market-competitors-help-button',
	MarketCompetitorsRestoreButton = 'market-competitors-restore-button',
	MarketCompetitorsAddButton = 'market-competitors-add-button',
	MarketRestorePopup = 'market-restore-popup',
	MarketRestoreCancelButton = 'market-restore-cancel-button',
	MarketRestoreConfirmButton = 'market-restore-confirm-button',
	MarketAddPopup = 'market-add-popup',
	MarketAddNameField = 'market-add-name-field',
	MarketAddSearchButton = 'market-add-search-button',
	MarketAddSearchResultsList = 'market-add-search-results-list',
	MarketCompetitorsMapContainer = 'market-competitors-map-container',
	MarketCompetitorsMapExpandButton = 'market-competitors-map-expand-button',
	MarketCompetitorsMapShrinkButton = 'market-competitors-map-shrink-button',
	MarketCompetitorsMapZoominButton = 'market-competitors-map-zoomin-button',
	MarketCompetitorsMapZoomoutButton = 'market-competitors-map-zoomout-button',
	MarketPricetrendContainer = 'market-pricetrend-container',
	MarketPricetrendPeriodField = 'market-pricetrend-period-field',
	MarketPricetrendDatepicker = 'market-pricetrend-datepicker',
	MarketPricetrendExpandButton = 'market-pricetrend-expand-button',
	MarketPricetrendShrinkButton = 'market-pricetrend-shrink-button',
	MarketPricetrendChartContainer = 'market-pricetrend-chart-container',
	MarketPricetrendChartFilters = 'market-pricetrend-chart-filters',

	// Calendar
	CalendarCalculateButton = 'calendar-calculate-button',
	CalendarCalculatePopup = 'calendar-calculate-popup',
	CalendarCalculatePopupPeriodField = 'calendar-calculate-popup-period-field',
	CalendarCalculatePopupPeriodDatepicker = 'calendar-calculate-popup-period-datepicker',
	CalendarCalculatePopupAccomodationField = 'calendar-calculate-popup-accomodation-field',
	CalendarCalculatePopupAccomodationDopdown = 'calendar-calculate-popup-accomodation-dopdown',
	CalendarCalculatePopupSelectallCheckbox = 'calendar-calculate-popup-selectall-checkbox',
	CalendarCalculatePopupRoomsContainer = 'calendar-calculate-popup-rooms-container',
	CalendarCalculatePopupCancelButton = 'calendar-calculate-popup-cancel-button',
	CalendarCalculatePopupConfirmButton = 'calendar-calculate-popup-confirm-button',

	CalendarCustomizeButton = 'calendar-customize-button',
	CalendarCustomizePopup = 'calendar-customize-popup',
	CalendarCustomizePopupSingleButton = 'calendar-customize-popup-single-button',
	CalendarCustomizePopupMultiButton = 'calendar-customize-popup-multi-button',
	CalendarCustomizePopupPeriodField = 'calendar-customize-popup-period-field',
	CalendarCustomizePopupPeriodDatepicker = 'calendar-customize-popup-period-datepicker',
	CalendarCustomizePopupWeekdaysButton = 'calendar-customize-popup-weekdays-button',
	CalendarCustomizePopupWeekendButton = 'calendar-customize-popup-weekend-button',
	CalendarCustomizePopupMonButton = 'calendar-customize-popup-mon-button',
	CalendarCustomizePopupTueButton = 'calendar-customize-popup-tue-button',
	CalendarCustomizePopupWedButton = 'calendar-customize-popup-wed-button',
	CalendarCustomizePopupThuButton = 'calendar-customize-popup-thu-button',
	CalendarCustomizePopupFriButton = 'calendar-customize-popup-fri-button',
	CalendarCustomizePopupSatButton = 'calendar-customize-popup-sat-button',
	CalendarCustomizePopupSunButton = 'calendar-customize-popup-sun-button',
	CalendarCustomizePopupAccomodationDopdown = 'calendar-customize-popup-accomodation-dopdown',
	CalendarCustomizePopupSelectallCheckbox = 'calendar-customize-popup-selectall-checkbox',
	CalendarCustomizePopupRoomsContainer = 'calendar-customize-popup-rooms-container',
	CalendarCustomizePopupRemovefiltersContainer = 'calendar-customize-popup-removefilters-container',
	CalendarCustomizePopupFiltersContainer = 'calendar-customize-popup-filters-container',
	CalendarCustomizePopupToggle1 = 'calendar-customize-popup-toggle-1',
	CalendarCustomizePopupToggle2 = 'calendar-customize-popup-toggle-2',
	CalendarCustomizePopupCancelButton = 'calendar-customize-popup-cancel-button',
	CalendarCustomizePopupConfirmButton = 'calendar-customize-popup-confirm-button',

	CalendarDateButton = 'calendar-date-button',
	CalendarDatepicker = 'calendar-datepicker',
	CalendarTodayButton = 'calendar-today-button',
	CalendarNextButton = 'calendar-next-button',
	CalendarPrevButton = 'calendar-prev-button',
	CalendarContainer = 'calendar-container',
	CalendarQuestionIcon = 'calendar-question-icon',
	CalendarSetIcon = 'calendar-set-icon',
	CalendarActiveIcon = 'calendar-active-icon',

	CalendarQuickactionContainer = 'calendar-quickaction-container',
	CalendarQuickactionPopupContainer = 'calendar-quickaction-popup-container',
	CalendarQuickactionPopupXButton = 'calendar-quickaction-popup-x-button',
	CalendarQuickactionPopupVButton = 'calendar-quickaction-popup-v-button',
	CalendarQuickactionPopupDelButton = 'calendar-quickaction-popup-del-button',
	CalendarQuickactionPopupToggle1 = 'calendar-quickaction-popup-toggle-1',
	CalendarQuickactionPopupToggle2 = 'calendar-quickaction-popup-toggle-2',

	CalendarCocPopup = 'calendar-coc-popup',
	CalendarCocPublishedContainer = 'calendar-coc-published-container',
	CalendarCocStrategyContainer = 'calendar-coc-strategy-container',
	CalendarCocOccupancyContainer = 'calendar-coc-occupancy-container',
	CalendarCocAdrContainer = 'calendar-coc-adr-container',
	CalendarCocShowmoreButton = 'calendar-coc-showmore-button',
	CalendarCocPricetrendButton = 'calendar-coc-pricetrend-button',
	CalendarCocPickupButton = 'calendar-coc-pickup-button',
	CalendarCocAlldatesButton = 'calendar-coc-alldates-button',
	CalendarCocLastmonthButton = 'calendar-coc-lastmonth-button',
	CalendarCocChartFiltersContainer = 'calendar-coc-chart-filters-container',
	CalendarCocModifiersBar = 'calendar-coc-modifiers-bar',
	CalendarCocFiltersContainer = 'calendar-coc-filters-container',
	CalendarCocToggle1 = 'calendar-coc-toggle-1',
	CalendarCocToggle2 = 'calendar-coc-toggle-2',
	CalendarCocResetButton = 'calendar-coc-reset-button',
	CalendarCocSaveButton = 'calendar-coc-save-button',

	CalendarMultiselectionCustomizeButton = 'calendar-multiselection-customize-button',
	CalendarMultiselectionCustomizeContainer = 'calendar-multiselection-customize-container',
	CalendarMultiselectionSelectionsContainer = 'calendar-multiselection-selections-container',
	CalendarMultiselectionFiltersContainer = 'calendar-multiselection-filters-container',
	CalendarMultiselectionRemoveFiltersContainer = 'calendar-multiselection-remove-filters-container',
	CalendarMultiselectionToggle1 = 'calendar-multiselection-toggle-1',
	CalendarMultiselectionToggle2 = 'calendar-multiselection-toggle-2',
	CalendarMultiselectionResetButton = 'calendar-multiselection-reset-button',
	CalendarMultiselectionSaveButton = 'calendar-multiselection-save-button',

	// Accommodations - Starting Prices
	AccommodationPricesButton = 'accomodation-prices-button',
	AccommodationPricesRoomsContainer = 'accomodation-prices-rooms-container',
	AccommodationPricesExpandedView = 'accomodation-prices-expanded-view',
	AccommodationPricesPeriodsButton = 'accomodation-prices-periods-button',
	AccommodationPricesBaseRoomButton = 'accomodation-prices-baseroom-button',
	AccommodationPricesFuturePricesButton = 'accomodation-prices-futureprices-button',
	AccommodationPricesPastPricesButton = 'accomodation-prices-pastprices-button',
	AccommodationPricesSelectAllCheckbox = 'accomodation-prices-selectall-checkbox',
	AccommodationPricesPeriodsList = 'accomodation-prices-periods-list',
	AccommodationPricesPeriodsCopyButton = 'accomodation-prices-periods-copy-button',
	AccommodationPricesPeriodsDeleteButton = 'accomodation-prices-periods-delete-button',
	AccommodationPricesCopyPopupContainer = 'accomodation-prices-copypopup-container',
	AccommodationPricesCopyPopupPeriods = 'accomodation-prices-copypopup-periods',
	AccommodationPricesCopyPopupPeriodsAndPrices = 'accomodation-prices-copypopup-periodsandprices',
	AccommodationPricesCopyPopupRoomsList = 'accomodation-prices-copypopup-roomslist',
	AccommodationPricesCopyPopupConfirm = 'accomodation-prices-copypopup-confirm',
	AccommodationPricesCopyPopupCancel = 'accomodation-prices-copypopup-cancel',
	AccommodationPricesOverridePopupContainer = 'accomodation-prices-overridepopup-container',
	AccommodationPricesOverridePopupCancel = 'accomodation-prices-overridepopup-cancel',
	AccommodationPricesOverridePopupOverride = 'accomodation-prices-overridepopup-override',
	AccommodationPricesDelPopupContainer = 'accomodation-prices-delpopup-container',
	AccommodationPricesDelPopupCancel = 'accomodation-prices-delpopup-cancel',
	AccommodationPricesDelPopupDelete = 'accomodation-prices-delpopup-delete',
	AccommodationPricesEditPopupContainer = 'accomodation-prices-editopopup-container',
	AccommodationPricesEditPopupDatepicker = 'accomodation-prices-editpopup-datepicker',
	AccommodationPricesEditPopupChartContainer = 'accomodation-prices-editpopup-chart-container',
	AccommodationPricesEditPopupStartingPriceInput = 'accomodation-prices-editpopup-startingprice-input',
	AccommodationPricesEditPopupCancelButton = 'accomodation-prices-editpopup-cancel-button',
	AccommodationPricesEditPopupConfirmButton = 'accomodation-prices-editpopup-confirm-button',
	AccommodationPricesErrorBanner = 'accomodation-prices-error-banner',
	AccommodationPricesSetPopupContainer = 'accomodation-prices-setpopup-container',
	AccommodationPricesSetPopupPreviousButton = 'accomodation-prices-setpopup-previous-button',
	AccommodationPricesSetPopupNextButton = 'accomodation-prices-setpopup-next-button',
	AccommodationPricesSetPopupNewButton = 'accomodation-prices-setpopup-new-button',
	AccommodationPricesSetPopupCancelButton = 'accomodation-prices-setpopup-cancel-button',
	AccommodationPricesSetPopupConfirmButton = 'accomodation-prices-setpopup-confirm-button',
	AccommodationPricesBaseRoomSelect = 'accomodation-prices-baseroom-select',
	AccommodationPricesBaseRoomSelectDropdown = 'accomodation-prices-baseroom-select-dropdown',
	AccommodationPricesBaseRoomRuleInput = 'accomodation-prices-baseroom-rule-input',

	// Accommodations - Orphan Nights
	AccommodationDropdownButton = 'accomodation-dropdown-button',
	AccommodationDropdownContainer = 'accomodation-dropdown-container',
	AccommodationGapButton = 'accomodation-gap-button',
	AccommodationGapEnableToggle = 'accomodation-gap-enable-toggle',
	AccommodationGapMinNightsInput = 'accomodation-gap-minnights-input',
	AccommodationGapRoomsContainer = 'accomodation-gap-rooms-container',
	AccommodationGapShowMore = 'accomodation-gap-showmore',
	AccommodationSaveButton = 'accomodation-save-button',
	AccommodationGapSavedPopup = 'accomodation-gap-saved-popup',
}
