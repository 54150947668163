import { Localization } from './Localization';
import { Range } from './Range';

export const enum EventType {
  Event = 'event',
  Holiday = 'holiday',
}

export const enum EventPreviewType {
  Current = 'current',
  Preview = 'preview',
  PreviewWithModifiers = 'preview_with_modifiers',
}

export type EventPreviewPrice = {
  date: Date;
  price: number;
};

export type DateImpact = {
  date: Date;
  impact: number;
  isTail?: boolean;
};

export enum RepetitionType {
  NoRepeat = 'no_repeat',
  RegularRepeat = 'repeat',
  DifferentDatesRepeat = 'different_dates_repeat',
  None = 'none',
  Custom = 'custom',
}

export type EventDetails = {
  eventDates: Range<Date>;
  impacts: DateImpact[];
  repetitionType: RepetitionType;
};

export type Event = {
  id?: string; // startDate + label (in snake case) for holiday
  label: Localization<string>;
  eventPeriod: Range<Date>;
  eventType: EventType;
  isLoading?: boolean;
  isUserGenerated?: boolean;
  isUserModified?: boolean;
  isUserDisabled?: boolean;
  isDrivedEvent?: boolean;
  derivedEventAccommodationId?: number;
  eventDetails?: EventDetails;
  accommodationId: string;
};
