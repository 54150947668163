import { Accommodation } from './Accommodation';

export enum PaymentMethodType {
  Direct = 'direct_debit',
  CreditCard = 'card',
  WireTransfer = 'wire_transfer',
  Unknown = 'unknown',
}

export type PaymentMethod = {
  type: PaymentMethodType;
  lastDigits?: string;
  expiration?: {
    month: number;
    year: number;
  };
  paymentNetwork?: string;
  isPrimary?: boolean;
};

export enum SubscriptionStatusType {
  Future = 'future',
  InTrial = 'in_trial',
  Active = 'active',
  NonRenewing = 'non_renewing',
  Paused = 'paused',
  Cancelled = 'cancelled',
  Transferred = 'transferred',
  Unknown = 'unknown',
}

export enum ScheduledChangeType {
  OnNextBillingCycle = 'next-billing-cycle',
  SpecificDate = 'specific-date',
}

export type ScheduledChange = {
  type: ScheduledChangeType;
  changeOnDate: Date;
  newBillingAmount: number;
};

export type Subscription = {
  id: string;
  plan: string;
  status: SubscriptionStatusType;
  accommodations: Pick<Accommodation, 'name'>[];
  currencyCode: string;

  hasScheduledChanges: boolean;
  scheduledChange?: ScheduledChange;
  nextBillingDate: Date;
  nextBillingAmount: number;
  cancelledAtDate?: Date;
  pausedOnDate?: Date;
  hasUnpaidInvoices: boolean;
  totalUnpaidInvoices?: number;
  totalUnpaidAmount?: number;
  unpaidSinceDate?: Date;
};

export type BillingAddress = {
  street: string | null;
  city: string | null;
  countryCode: string | null;
  zipCode: string | null;
  formattedAddress: string | null;
};

export type BillingInfo = {
  firstName: string;
  lastName: string;
  email: string;
  vatId: string;
  fiscalCode: string;
  recipientCode: string;
  address: BillingAddress;
  companyName: string;
};

export type BillingProfile = {
  id: string;
  info: BillingInfo;
  paymentMethods: PaymentMethod[];
  subscriptions: Subscription[];
};

export enum InvoiceStatusType {
  Paid = 'paid',
  Posted = 'posted',
  PaymentDue = 'payment_due',
  NotPaid = 'not_paid',
  Voided = 'voided',
  Pending = 'pending',
  Unknown = 'unknown',
}

export type Invoice = {
  id: string;
  plan: string;
  downloadUrl: string;
  accommodations: Pick<Accommodation, 'name' | 'id'>[];
  status: InvoiceStatusType;
  amount: number;
  subscriptionId: string;
  date: Date;
  currencyCode: string;
};
