import { WSMessageEvent } from '../../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../../common/WebSocketNetworkObject';
import { convertStrategyCommonToPayload } from '../../../../utils/strategy';
import {
  GetAccommodationPricesPredictionPreviewRequest,
  GetAccommodationPricesPredictionPreviewRequestPayload,
} from './GetAccommodationPricesPredictionPreviewRequest';

export class GetAccommodationPricesPredictionPreviewNetworkObject extends WebSocketNetworkObject<
  GetAccommodationPricesPredictionPreviewRequest,
  GetAccommodationPricesPredictionPreviewRequestPayload
> {
  constructor(params: GetAccommodationPricesPredictionPreviewRequest) {
    super(params, WSMessageEvent.GetAccommodationPricesPredictionPreview);
  }

  override getParams(): GetAccommodationPricesPredictionPreviewRequestPayload {
    const { strategy } = this.params;

    return {
      accommodationId: strategy.accommodationId,
      strategy: convertStrategyCommonToPayload(strategy),
    };
  }
}
