import { WSMessageEvent } from '../../../../common/WebSocket';
import { WebSocketNetworkObject } from '../../../../common/WebSocketNetworkObject';
import { convertStrategyCommonToPayload } from '../../../../utils/strategy';
import {
  UpdateAccommodationStrategyRequest,
  UpdateAccommodationStrategyRequestPayload,
} from './UpdateAccommodationStrategyRequest';

export class UpdateAccommodationStrategyNetworkObject extends WebSocketNetworkObject<
  UpdateAccommodationStrategyRequest,
  UpdateAccommodationStrategyRequestPayload
> {
  constructor(params: UpdateAccommodationStrategyRequest) {
    super(params, WSMessageEvent.UpdateAccommodationStrategy);
  }

  override getParams(): UpdateAccommodationStrategyRequestPayload {
    const { removeModifiers, strategy } = this.params;

    return {
      accommodationId: strategy.accommodationId,
      removeModifiers,
      strategy: convertStrategyCommonToPayload(strategy),
    };
  }
}
