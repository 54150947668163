import { SpSvg } from '~/autogen/SpSvg'
import { currencySymbolToIcon } from '../config/CurrencySymbolToIcon'
import { Currency } from '../submodules/sharedTypes/common/Currency'
import { utilNumber } from './UtilNumber'

class UtilCurrency {
	public currentCurrency = (currency: Currency) => {
		return utilNumber.toCurrency(0, currency).split('0').join('').trim()
	}

	public currencyIcon(currency: Currency): SpSvg {
		const currencyString = this.currentCurrency(currency)
		return currencySymbolToIcon(currencyString)
	}

	public currencySymbol(currency: Currency) {
		return new Intl.NumberFormat(currency.locale, {
			style: 'currency',
			currency: currency.code,
			maximumFractionDigits: 0,
		})
			.format(0)
			.replace(/\d/g, '')
			.trim()
	}

	/**
	 * Given a number, returns the price enriched with the configured currency
	 *
	 * @param number the price
	 * @returns formatted price
	 */
	public formatPrice(price: number | undefined, currency: Currency) {
		if (price == undefined) {
			return this.emptyCurrency(currency)
		}

		return price.toLocaleString(currency.locale, {
			maximumFractionDigits: 0,
			style: 'currency',
			currency: currency.code,
		})
	}

	private emptyCurrency(currency: Currency) {
		const temp = 0
		return temp
			.toLocaleString(currency.locale, {
				maximumFractionDigits: 0,
				style: 'currency',
				currency: currency.code,
			})
			.replace('0', '-')
	}
}

export const utilCurrency = new UtilCurrency()
